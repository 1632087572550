import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const BORDER_RADIUS = 10;

const CustomModal = ({ classes, backdropClick, children, escapeKeyDown, open, handleClose }) => (
  <Modal
    aria-labelledby="custom-modal"
    aria-describedby="custom-modal"
    open={open}
    onClose={handleClose}
    // disable by default
    disableBackdropClick={!backdropClick}
    disableEscapeKeyDown={!escapeKeyDown}
    classes={{ root: classes.modalRoot }}
  >
    <div className={classes.modalContent}>{children}</div>
  </Modal>
);

const styles = theme => ({
  modalRoot: {
    borderRadius: BORDER_RADIUS,
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: BORDER_RADIUS,
    boxShadow: theme.shadows[5],
    left: `50%`,
    maxHeight: '90%',
    outline: 'none',
    overflow: 'auto',
    padding: theme.spacing.unit * 2,
    position: 'absolute',
    top: `50%`,
    transform: `translate(-50%, -50%)`,
  },
});

CustomModal.propTypes = {
  classes: PropTypes.object.isRequired,

  escapeKeyDown: PropTypes.bool,
  backdropClick: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  open: PropTypes.bool.isRequired,

  handleClose: PropTypes.func.isRequired,
};

CustomModal.defaultProps = {
  backdropClick: false,
  escapeKeyDown: false,
};

export default withStyles(styles)(CustomModal);
