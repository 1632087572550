import { isEmpty } from 'lodash';
import moment from 'moment';

// validation functions for text input fields
// return error text if validator function fails
// return false if no error

export const required = (value, fieldName) =>
  !!value && !isEmpty(value.trim()) ? false : `${fieldName ? `${fieldName} is ` : ''}required`;
export const DATE_INPUT_DATE_FORMAT = 'MM/DD/YYYY';

export const nonZeroInteger = (value) => {
  let n = Math.floor(Number(value));
  return n !== Infinity && String(n) === value && n > 0 ? false : 'Must be a whole number greater than zero';
};

export const charLimit = (charLimit) => (value) => {
  return value && value.length > charLimit ? `Must be less than ${charLimit + 1} characters` : false;
};

export const validCharacters = (value) => {
  const regex = new RegExp(/^[a-zA-Z0-9!"#$%&'()*+,\-/:;<=> ?@[\\\]^_`{|}~]*$/);
  return value && !regex.test(value) ? 'Invalid characters' : false;
};

export const validZip = (value) => {
  const regex = new RegExp(/\d{5}$/);
  return value && !regex.test(value) ? 'Invalid zip code' : false;
};

// note: regex applied is for format (999) 999-999
export const validPhoneNumber = (value) => {
  const regex = new RegExp(/^((\+0?1\s)?)\(?\d{3}\)?[\s.\s]\d{3}[\s.-]\d{4}$/);
  return value && !regex.test(value) ? 'Invalid phone number' : false;
};

export const validEmail = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,32}$/i.test(value) ? 'Invalid email address' : false);

export const greaterThan18YearsAgo = (dateFormat) => (date) => {
  const THRESHOLD_YEARS = 18;

  try {
    const now = moment();
    const d = moment(date, dateFormat);
    const diff = now.diff(d, 'years');
    return diff < THRESHOLD_YEARS ? 'Patients under 18 must be added under guardian\'s existing account to see a provider' : false;
  } catch (e) {
    return false;
  }
};

export const lessThanEqualTo18YearsAgo = (dateFormat) => (date) => {
  const THRESHOLD_YEARS = 18;

  try {
    const now = moment();
    const d = moment(date, dateFormat);
    const diff = now.diff(d, 'years');

    return diff >= THRESHOLD_YEARS ? 'Patients over 18 must create a new account to see a provider' : false;
  } catch (e) {
    return false;
  }
};

export const lessThan12YearsAgo = (dateFormat) => (date) => {
  const THRESHOLD_YEARS = 12;

  try {
    const now = moment();
    const d = moment(date, dateFormat);
    const diff = now.diff(d, 'years');
    return diff < THRESHOLD_YEARS;
  } catch (e) {
    return false;
  }
};

export const dateIsInThePast = (dateFormat) => (date) => {
  try {
    const now = moment();
    const d = moment(date, dateFormat);
    return now < d ? 'Date must be in the past' : false;
  } catch (e) {
    return false;
  }
};

export const validWeight = (value) => {
  if (isNaN(value)) return 'Weight must be a number';

  if (!Number.isInteger(Number(value))) return 'Weight must be a whole number';

  if (value > 999) return 'Weight must be less than 999';

  return false;
};

// password requirements:
// Special characters (!@#$%^&*)
// Lower case (a-z), upper case (A-Z) and numbers (0-9)
// 8 char minimum
export const validPassword = (value) => {
  const specialCharRegex = /[!@#$%^&*]/;
  const lowerCaseRegex = /[a-z]/;
  const upperCaseRegex = /[A-Z]/;
  const numberRegex = /[\d]/;

  if (!specialCharRegex.test(value)) {
    return 'Must contain a special character';
  }

  if (!lowerCaseRegex.test(value)) {
    return 'Must contain a lower case character';
  }

  if (!upperCaseRegex.test(value)) {
    return 'Must contain an upper case character';
  }

  if (!numberRegex.test(value)) {
    return 'Must contain a number';
  }

  if (value.length < 8) {
    return 'Must be at least 8 characters long';
  }

  return false;
};
