import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import TextField from '@material-ui/core/TextField';

import { can } from '../login/can';
import { findIndex } from 'lodash';
import { noteExportTypes } from '../../types/noteExportTypes';
import { providerGroupPermissions } from '../../types/permissionTypes';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';

const styles = (theme) => ({
  formControl: {
    width: '100%',
  },
  gridItem: {
    marginBottom: '1rem',
  },
});

class NoteTypeForm extends Component {
  constructor(props) {
    super(props);

    const noteType = props.noteType;

    this.state = {
      providerGroupId: noteType ? noteType.providerGroupId : '',
      name: noteType ? noteType.name : '',
      visibleToPatientByDefault: noteType ? noteType.visibleToPatientByDefault : false,
      labelWidth: 0,
      noteExportType: noteType ? noteType.noteExportType : 'Not Exported',
    };
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.inputLabelRef).offsetWidth,
    });
  }

  getProviderGroupOptions = () => {
    return this.props.providerGroups
      .filter((providerGroup) => (this.props.noteType ? true : can(providerGroupPermissions.createNoteType, providerGroup.id)))
      .map((providerGroup, i) => {
        return (
          <MenuItem value={providerGroup.id} key={i}>
            {providerGroup.name}
          </MenuItem>
        );
      });
  };

  getExportTypeOptions = () => {
    return noteExportTypes.map((t, i) => (<MenuItem value={t} key={i}>
      {t}
    </MenuItem>));
  }

  handleChange = (name) => (event) => {
    let updateObj = { [name]: event.target.value };
    this.setState(updateObj);
  };

  handleToggleVisibleToPatient = () => {
    this.setState({
      visibleToPatientByDefault: !this.state.visibleToPatientByDefault,
    });
  };

  formHasErrors = () => {
    return this.state.providerGroupId === '' || this.state.name === '';
  };

  handleSaveNoteType = () => {
    let noteType = {
      providerGroupId: this.state.providerGroupId,
      name: this.state.name,
      visibleToPatientByDefault: this.state.visibleToPatientByDefault,
      noteExportType: this.state.noteExportType,
    };

    this.props.handleSaveNoteType(this.props.noteType ? this.props.noteType.id : null, noteType);
  };

  handleDeleteNoteType = () => {
    this.props.handleDeleteNoteType(this.props.noteType.id);
  };

  deleteDisabled = () => {
    const noteTypeIndex = findIndex(this.props.noteTemplates, { noteTypeId: this.props.noteType.id });
    return noteTypeIndex !== -1;
  };

  render() {
    let { classes, disabled } = this.props;
    const { providerGroupId, name, visibleToPatientByDefault, noteExportType, labelWidth } = this.state;

    return (
      <Grid container>
        <Grid item xs={12} sm={9}>
          <Grid item xs={12} className={classes.gridItem}>
            <FormControl required variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={(ref) => {
                  this.inputLabelRef = ref;
                }}
                htmlFor="providerGroupId-placeholder"
              >
                Provider Group
              </InputLabel>
              <Select
                value={providerGroupId}
                onChange={this.handleChange('providerGroupId')}
                input={<OutlinedInput labelWidth={labelWidth} name="providerGroupId" id="providerGroupId-placeholder" />}
                disabled={this.props.noteType !== undefined || disabled}
              >
                {this.getProviderGroupOptions()}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <TextField
                id="name"
                className={classes.textField}
                value={name}
                onChange={this.handleChange('name')}
                label={'Note Type Name'}
                margin="normal"
                variant="outlined"
                required
                disabled={disabled}
              />
            </FormControl>
          </Grid>

          <FormControl variant="outlined" className={classes.formControl}
            style={{ marginBottom: '1rem' }}
          >
            <InputLabel
              ref={(ref) => {
                this.inputLabelRef = ref;
              }}
              htmlFor="noteExportType"
            >
              Note Export Type
            </InputLabel>
            <Select
              value={noteExportType}
              onChange={this.handleChange('noteExportType')}
              input={<OutlinedInput labelWidth={labelWidth} name="providerGroupId" id="noteExportType" />}
            >
              {this.getExportTypeOptions()}
            </Select>
          </FormControl>

          <Grid item xs={12} className={classes.gridItem}>
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  checked={visibleToPatientByDefault}
                  onChange={this.handleToggleVisibleToPatient}
                  value="visibleToPatientByDefault"
                  color="primary"
                  style={{ paddingTop: 0, paddingBottom: 0, paddingRight: '1rem' }}
                  disabled={disabled}
                />
              }
              label="Default Visible to Patient"
            />
          </Grid>

          {/* ACTIONS */}
          <Grid container item xs={12}
            justify={'flex-end'}
          >
            {this.props.noteType && can(providerGroupPermissions.deleteNoteType, this.props.noteType.providerGroupId) && (
              <Button variant={'outlined'} onClick={this.handleDeleteNoteType} disabled={this.deleteDisabled()}>
                {'Delete'}
              </Button>
            )}
            {!disabled && (
              <Fragment>
                <Button
                  disabled={this.formHasErrors()}
                  variant={'contained'}
                  color={'primary'}
                  style={{ marginLeft: 'auto', marginRight: '0.5rem' }}
                  onClick={this.handleSaveNoteType}
                >
                  {'Save'}
                </Button>
                <Button variant={'outlined'} onClick={this.props.handleCancel}>
                  {'Cancel'}
                </Button>
              </Fragment>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

NoteTypeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  noteType: PropTypes.object,
  noteTemplates: PropTypes.array.isRequired,
  providerGroups: PropTypes.array.isRequired,
  handleSaveNoteType: PropTypes.func.isRequired,
  handleDeleteNoteType: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
};

export default withStyles(styles)(NoteTypeForm);
