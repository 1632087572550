import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import logo from '../../images/login/logo-grey.png';
import auth from '../../auth/auth';
import LoginForm from './loginForm.component';

import { handleToastMessage, setPageTitle, showToast } from '../layout/layout.actions';

const MAX_WIDTH = '10rem';

const styles = theme => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    paddingBottom: '6rem',
    height: '100vh',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  logo: {
    maxWidth: MAX_WIDTH,
    margin: theme.spacing.unit,
    marginBottom: '1rem',
  },
  textFieldWrapper: {
    width: '100%',
    maxWidth: MAX_WIDTH,
    marginBottom: '2rem',
  },
  buttonsWrapper: {
    marginTop: '1.5rem',
    width: '100%',
    maxWidth: MAX_WIDTH,
  },
});

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentWillMount() {
    this.props.setPageTitle('Login');
  }

  handleLogin() {
    auth.login();
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div
        className={classes.formWrapper}
        style={{ backgroundColor: theme.palette.primary.background }}
      >
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '50vw', alignSelf: 'center' }}
        >
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '50vw',
              height: '80vh',
              flex: 1,
            }}
          >
            <CardContent className={classNames(classes.formContent)}>
              <LoginForm></LoginForm>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

LoginContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {};
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      setPageTitle,
      handleToastMessage,
      showToast,
    })(LoginContainer)
  )
);
