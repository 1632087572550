import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NoteTypesDataTable from './noteTypesDataTable.component';
import NoteTemplatesDataTable from './noteTemplatesDataTable.component';
import classnames from 'classnames';
import { getNoteTypes } from './noteTypes.actions';
import { getNoteTemplates } from './noteTemplates.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Switch, Route, Link } from 'react-router-dom';

const styles = theme => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 5rem)'
  }
});

class NotesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      currentRow: null
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentWillMount() {
    this.props.setPageTitle('Notes');
    this.props.getNoteTypes();
    this.props.getNoteTemplates();
  }

  handleTabChange(event, newValue) {
    this.setState({ currentTab: newValue });
  }

  render() {
    const { classes, isLoading, noteTypes, noteTemplates, providerGroups, location } = this.props;

    if (isLoading === true) {
      return <LoadingOverlay />;
    }

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        <AppBar position="static" color="default">
          <Tabs value={location.pathname}>
            <Tab label="Types" value="/notes/types" component={Link} to="/notes/types" />
            <Tab label="Templates" value="/notes/templates" component={Link} to="/notes/templates" />
          </Tabs>
        </AppBar>
        <Switch>
          <Route
            path="/notes/types"
            render={() => <NoteTypesDataTable data={noteTypes} providerGroups={providerGroups} history={this.props.history} />}
          />
          <Route
            path="/notes/templates"
            render={() => (
              <NoteTemplatesDataTable
                data={noteTemplates}
                noteTypes={noteTypes}
                providerGroups={providerGroups}
                history={this.props.history}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    noteTypes: state.noteTypes.noteTypes,
    noteTemplates: state.noteTemplates.noteTemplates,
    isLoading: state.noteTypes.isLoading || state.noteTemplates.isLoading,
    providerGroups: state.layout.providerGroups
  };
};

NotesContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        getNoteTypes,
        getNoteTemplates
      }
    )(NotesContainer)
  )
);
