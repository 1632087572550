import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
  Typography,
} from '@material-ui/core';
import ReactDOM from 'react-dom';

import { genderOptions } from '../../utilities/genderUtils';
import { getRelationshipOptions } from '../../utilities/insuranceUtils';
import { RELATIONSHIP_TYPES } from '../../types/relationshipTypes';
import PatientInsurancePhotos from './patientInsurancePhotos.component';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';

class PatientInsuranceInfoForm extends Component {
  state = {
    genderLabelWidth: 0,
    relationshipLabelWidth: 0,
  };

  componentDidMount() {
    this.setState({
      genderLabelWidth: this.genderLabelRef
        ? ReactDOM.findDOMNode(this.genderLabelRef).offsetWidth
        : 0,
      relationshipLabelWidth: this.relationshipLabelRef
        ? ReactDOM.findDOMNode(this.relationshipLabelRef).offsetWidth
        : 0,
    });
  }

  componentDidUpdate() {
    if (this.genderLabelRef === 0 && this.genderLabelRef) {
      this.setState({
        genderLabelWidth: ReactDOM.findDOMNode(this.genderLabelRef).offsetWidth,
      });
    }
  }

  render() {
    const { entranceId, patient, isLoading, onChange, onRemovePhoto } = this.props;

    const hasReadPermission = can(entrancePermissions.readPatientInsurance, entranceId);
    const hasEditPermission = can(entrancePermissions.updatePatientInsurance, entranceId);

    return hasReadPermission ? (
      <>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={isLoading || !hasEditPermission}
              fullWidth
              label="Carrier Name"
              margin="normal"
              name="insurance.carrierName"
              value={patient.insurance?.carrierName || ''}
              variant="outlined"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={isLoading || !hasEditPermission}
              fullWidth
              label="Member/Subscriber ID"
              margin="normal"
              name="insurance.subscriberId"
              value={patient.insurance?.subscriberId || ''}
              variant="outlined"
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={isLoading || !hasEditPermission}
              fullWidth
              label="Policy/Group Number"
              margin="normal"
              name="insurance.policyNumber"
              value={patient.insurance?.policyNumber || ''}
              variant="outlined"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel
                ref={ref => {
                  this.relationshipLabelRef = ref;
                }}
                htmlFor="relationship-placeholder"
              >
                Relationship to Insured
              </InputLabel>
              <Select
                input={
                  <OutlinedInput
                    disabled={isLoading || !hasEditPermission}
                    labelWidth={this.state.relationshipLabelWidth}
                    name="insurance.relationshipToInsured"
                    id="relationship-placeholder"
                  />
                }
                value={patient.insurance?.relationshipToInsured || ''}
                onChange={onChange}
              >
                {getRelationshipOptions().map((gender, i) => {
                  return (
                    <MenuItem value={gender.value} key={gender.value}>
                      {gender.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {patient.insurance?.relationshipToInsured !== RELATIONSHIP_TYPES.RELATIONSHIP_SELF.value && (
          <>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={isLoading || !hasEditPermission}
                  fullWidth
                  label="Subscriber Name"
                  margin="normal"
                  name="insurance.subscriberName"
                  value={patient.insurance?.subscriberName || ''}
                  variant="outlined"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={isLoading || !hasEditPermission}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Subscriber Date of Birth"
                  margin="normal"
                  name="insurance.subscriberDob"
                  type="date"
                  value={patient.insurance?.subscriberDob || ''}
                  variant="outlined"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel
                    ref={ref => {
                      this.genderLabelRef = ref;
                    }}
                    htmlFor="gender-placeholder"
                  >
                    Subscriber Gender
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        disabled={isLoading || !hasEditPermission}
                        labelWidth={this.state.genderLabelWidth}
                        name="insurance.subscriberGender"
                        id="gender-placeholder"
                      />
                    }
                    value={patient.insurance?.subscriberGender || ''}
                    onChange={onChange}
                  >
                    {genderOptions.map(gender => {
                      return (
                        <MenuItem value={gender.value} key={gender.value}>
                          {gender.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={isLoading || !hasEditPermission}
                  fullWidth
                  label="Subscriber Address Line 1"
                  margin="normal"
                  name="insurance.subscriberAddressLine1"
                  value={patient.insurance?.subscriberAddressLine1 || ''}
                  variant="outlined"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={isLoading || !hasEditPermission}
                  fullWidth
                  label="Subscriber Address Line 2"
                  margin="normal"
                  name="insurance.subscriberAddressLine2"
                  value={patient.insurance?.subscriberAddressLine2 || ''}
                  variant="outlined"
                  onChange={onChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={isLoading || !hasEditPermission}
                  fullWidth
                  label="Subscriber City"
                  margin="normal"
                  name="insurance.subscriberAddressCity"
                  value={patient.insurance?.subscriberAddressCity || ''}
                  variant="outlined"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={isLoading || !hasEditPermission}
                  fullWidth
                  label="Subscriber State"
                  margin="normal"
                  name="insurance.subscriberAddressState"
                  value={patient.insurance?.subscriberAddressState || ''}
                  variant="outlined"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={isLoading || !hasEditPermission}
                  fullWidth
                  label="Subscriber Zip Code"
                  margin="normal"
                  name="insurance.subscriberAddressZip"
                  value={patient.insurance?.subscriberAddressZip || ''}
                  variant="outlined"
                  onChange={onChange}
                />
              </Grid>
            </Grid>
          </>
        )}
        <PatientInsurancePhotos
          containerStyle={{ marginTop: '1rem' }}
          disabled={!hasEditPermission}
          photos={patient.insurance ? [{
            id: patient.insurance.cardImageUploadId,
            imageUrl: patient.insurance.cardImageUrl,
          }, {
            id: patient.insurance.cardImageUpload2Id,
            imageUrl: patient.insurance.cardImage2Url,
          },{
            id: patient.insurance.cardImageUpload3Id,
            imageUrl: patient.insurance.cardImage3Url,
          },{
            id: patient.insurance.cardImageUpload4Id,
            imageUrl: patient.insurance.cardImage4Url,
          }] : []}
          onRemovePhoto={onRemovePhoto}
        />
      </>
    ) : (
      <Typography style={{ marginBottom: '1rem', marginTop: '1rem' }} variant="body1">
        You do not have permission to view a patient&apos;s insurance.
      </Typography>
    );
  }
}

PatientInsuranceInfoForm.propTypes = {
  patient: PropTypes.object,
  entranceId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemovePhoto: PropTypes.func.isRequired,
};

PatientInsuranceInfoForm.defaultProps = {
  patient: null,
};

export default PatientInsuranceInfoForm;
