export const visitStatusTypes = {
  NEW: 'new',
  SELECTED: 'selected',
  READY: 'ready',
  CANCELLED: 'cancelled',
  STARTED: 'started',
  COMPLETED: 'completed'
};

export default { visitStatusTypes };
