import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DataTable from '../../common/dataTable/dataTable.component';
import classnames from 'classnames';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography, TableRow, TableCell } from '@material-ui/core';
import { find } from 'lodash';
import NumberFormat from 'react-number-format';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';

import CustomModal from '../../common/modal/customModal.component';
import AltChargeForm from './altChargeForm.component';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { SAVE_TRANSACTION_SUCCESS } from './altCharges.actions';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = theme => ({
  tableRowRoot: {
    cursor: 'pointer !important',
  },
  rowHighlight: {
    backgroundColor: theme.palette.primary.lightgray,
  },
});

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'patientName', numeric: false, disablePadding: false, label: 'Name', sortable: true, filtering: true },
  { id: 'authorizedPrice', numeric: false, disablePadding: false, label: 'Authorized Price', sortable: true, filtering: true },
  { id: 'suggestedPrice', numeric: false, disablePadding: false, label: 'Suggested Price', sortable: true, filtering: true },
  { id: 'finalPrice', numeric: false, disablePadding: false, label: 'Final Price', sortable: true, filtering: true },
  { id: 'entranceName', numeric: false, disablePadding: false, label: 'Entry', sortable: true, filtering: true },
  { id: 'pricingGroupId', numeric: false, disablePadding: false, label: 'Pricing Group', sortable: true, filtering: true },
  { id: 'providerName', numeric: false, disablePadding: false, label: 'Provider', sortable: false, filtering: true },
];

class AltChargesDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowMessageDialog: false,
      isShowReturnToWaitingRoomDialog: false,
      isShowCancelVisitDialog: false,
      isCancelButStillChart: false,
      isMenuOpen: {},
      isShowModal: false,
      selectedTransaction: null,
    };

    this.menuAnchorEl = {};
  }

  handleToggleMenu = (event, i) => {
    let isMenuOpen = { ...this.state.isMenuOpen };
    isMenuOpen[i] = !isMenuOpen[i];

    this.setState({ isMenuOpen });
  };

  handleCloseMenu = (event, i) => {
    if (this.menuAnchorEl[i].contains(event.target)) {
      return;
    }

    let isMenuOpen = { ...this.state.isMenuOpen };
    isMenuOpen[i] = false;

    this.setState({ isMenuOpen });
  };

  handleEditClick = async (row, i) => {
    let isMenuOpen = { ...this.state.isMenuOpen };
    isMenuOpen[i] = !isMenuOpen[i];

    logEvent('edit_charge', {
      adminId: this.props.user.email,
      visitId: row.visitId,
      providerId: row.providerName,
    });

    this.setState({
      selectedTransaction: row,
      isShowModal: true,
      isMenuOpen,
    });
  };

  handleApproveClick = async (row, i) => {
    let isMenuOpen = { ...this.state.isMenuOpen };
    isMenuOpen[i] = !isMenuOpen[i];
    this.setState({ isMenuOpen });

    this.props.handleApproveTransaction(row);
  };

  handleDeclineClick = async (row, i) => {
    let isMenuOpen = { ...this.state.isMenuOpen };
    isMenuOpen[i] = !isMenuOpen[i];
    this.setState({ isMenuOpen });

    this.props.handleDeclineTransaction(row);
  };

  handleCompleteClick = async (row, i) => {
    let isMenuOpen = { ...this.state.isMenuOpen };
    isMenuOpen[i] = !isMenuOpen[i];
    this.setState({ isMenuOpen });

    this.props.handleCompleteTransaction(row);
  };

  handleSaveTransaction = async (id, transaction) => {
    let response = await this.props.handleSaveTransaction(id, transaction);
    if (response.type === SAVE_TRANSACTION_SUCCESS) {
      this.setState({
        selectedAltCharge: null,
        isShowModal: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      selectedAltCharge: null,
      isShowModal: false,
    });
  };

  // get custom row render component
  renderRow = (row, i) => {
    const momentToday = moment(new Date());
    const isSevenDaysOld = moment(row.created).isSameOrBefore(momentToday.subtract(7, 'days'));

    return (
      <Fragment key={i}>
        <TableRow
          tabIndex={-1}
          classes={{
            root:
              i % 2 === 0
                ? classnames([this.props.classes.tableRowRoot, this.props.classes.rowHighlight])
                : this.props.classes.tableRowRoot,
          }}
        >
          <TableCell align="left">
            <Typography>{row.patientName}</Typography>
            <Typography variant="caption">{row.reason}</Typography>
          </TableCell>
          <TableCell align="left">
            <NumberFormat
              value={row.authorizedPrice}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale
            />
          </TableCell>
          <TableCell align="left">
            <NumberFormat
              value={row.suggestedPrice / 10}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale
            />
          </TableCell>
          <TableCell align="left">
            <NumberFormat
              value={row.finalPrice}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale
            />
          </TableCell>
          <TableCell align="left">{row.entranceName}</TableCell>
          <TableCell align="left">{row.pricingGroupName}</TableCell>
          <TableCell align="left">
            <Typography>{row.providerName}</Typography>
            <NumberFormat
              format="(###) ###-####"
              value={row.providerMobileNumber}
              displayType={'text'}
              renderText={value => <Typography variant="caption">{value}</Typography>}
            />
          </TableCell>
          <TableCell align="right">
            {row.status === 'approval_needed' ? (
              <Fragment>
                <Button
                  buttonRef={node => {
                    this.menuAnchorEl[i] = node;
                  }}
                  aria-owns={this.state.isMenuOpen[i] ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={e => this.handleToggleMenu(e, i)}
                  variant={'contained'}
                  color={'primary'}
                  disabled={!can(entrancePermissions.updateSuggestedPaymentChange, row.entranceId)}
                >
                  {`Actions`}
                  <ArrowDropDown style={{ marginTop: -2 }} />
                </Button>
                <Popper
                  open={this.state.isMenuOpen[i] ? this.state.isMenuOpen[i] : false}
                  anchorEl={this.menuAnchorEl[i]}
                  transition
                  disablePortal
                  style={{ zIndex: 1000 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper style={{ minWidth: 200 }}>
                        <ClickAwayListener
                          onClickAway={e => {
                            this.handleCloseMenu(e, i);
                          }}
                        >
                          {isSevenDaysOld ? (
                            <MenuList>
                              <MenuItem onClick={() => this.handleCompleteClick(row, i)}>COMPLETE</MenuItem>
                            </MenuList>
                          ) : (
                            <MenuList>
                              <MenuItem onClick={() => this.handleEditClick(row, i)}>EDIT</MenuItem>
                              <MenuItem onClick={() => this.handleApproveClick(row, i)}>APPROVE</MenuItem>
                              <MenuItem onClick={() => this.handleDeclineClick(row, i)}>DECLINE</MenuItem>
                            </MenuList>
                          )}
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Fragment>
            ) : (
              <Button variant={'outlined'} color={'primary'} disabled style={{ width: 110 }}>
                {row.status}
              </Button>
            )}
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  render() {
    const { data, isSaving, entrances, pricingGroups } = this.props;

    let formattedData = data.map(row => {
      row = { ...row };

      row.patientName = `${row.patientFirstName} ${row.patientLastName}`;

      row.authorizedPrice = row.authorizedPrice / 100;
      row.suggestedPrice = row.suggestedPrice / 10;
      row.finalPrice = row.finalPrice ? row.finalPrice / 100 : '';

      let entrance = find(this.props.entrances, { id: row.entranceId });
      row.entranceName = entrance ? entrance.name : '';

      let pricingGroup = find(this.props.pricingGroups, { id: row.pricingGroupId });
      row.pricingGroup = pricingGroup ? pricingGroup.name : '';

      return row;
    });

    return (
      <Fragment>
        <DataTable data={formattedData} title={''} columns={columns} renderRow={this.renderRow} disableSearch defaultSortOrder={'asc'} />
        <CustomModal open={this.state.isShowModal} escapeKeyDown backdropClick handleClose={this.handleCloseModal}>
          {isSaving && <LoadingOverlay />}
          <Typography variant="subtitle1">Edit Alternative Charge</Typography>
          <AltChargeForm
            transaction={this.state.selectedTransaction}
            entrances={entrances}
            pricingGroups={pricingGroups}
            handleSaveTransaction={this.handleSaveTransaction}
            handleCancel={this.handleCloseModal}
          />
        </CustomModal>
      </Fragment>
    );
  }
}

AltChargesDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  entrances: PropTypes.array.isRequired,
  pricingGroups: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  handleSaveTransaction: PropTypes.func.isRequired,
  handleApproveTransaction: PropTypes.func.isRequired,
  handleDeclineTransaction: PropTypes.func.isRequired,
  handleCompleteTransaction: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isSaving: state.altCharges.isSaving,
    user: state.login.user,
  };
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, {})(AltChargesDataTable));
