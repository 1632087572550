import { Button } from '@material-ui/core';
import { DELETE_FACILITY_MEMBER_SUCCESS, deleteFacilityMember, getFacilityMemberships, updateFacility } from '../facilities.actions';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import { showToast } from '../../layout/layout.actions';
import { withStyles } from '@material-ui/core/styles';
import DataTable from '../../../common/dataTable/dataTable.component';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classnames from 'classnames';

// TODO: implement permissions
// import { can } from '../../login/can';
// import { entrancePermissions } from '../../../types/permissionTypes';

const styles = (theme) => ({
  tableRowRoot: {
    cursor: 'pointer !important',
  },
  rowHighlight: {
    backgroundColor: theme.palette.primary.lightgray,
  },
});

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'firstName', numeric: false, disablePadding: false, label: 'First Name', sortable: true, filtering: true },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name', sortable: true, filtering: true },
  { id: 'email', numeric: false, disablePadding: false, label: 'E-Mail', sortable: true, filtering: true },
];

class FacilitatorsDataTable extends Component {
  handleDeleteClick = async (event, row) => {
    if (window.confirm(`Are you sure you want to remove ${row.email} from the list?`)) {
      let result = await this.props.deleteFacilityMember(row.id);
      if (result.type === DELETE_FACILITY_MEMBER_SUCCESS) {
        this.props.getFacilityMemberships(this.props.facilityDetail.id);
      } else {
        this.props.showToast('The facilitator could not be removed. Please check your connection and try again.');
      }
    }
  };

  // get custom row render component
  renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow
          tabIndex={-1}
          classes={{
            root:
              i % 2 === 0 ? classnames([this.props.classes.tableRowRoot, this.props.classes.rowHighlight]) : this.props.classes.tableRowRoot,
          }}
        >
          <TableCell align="left">{!isNil(row.account) ? row.account.firstName : ''}</TableCell>
          <TableCell align="left">{!isNil(row.account) ? row.account.lastName : ''}</TableCell>
          <TableCell align="left">{row.email}</TableCell>
          <TableCell align="right">
            <Button onClick={(event) => this.handleDeleteClick(event, row)} color="primary" variant="outlined">
              {'DELETE'}
            </Button>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <DataTable
        data={data}
        columns={columns}
        renderRow={this.renderRow}
        disableSearch
        defaultSortOrder={'asc'}
        defaultSortField={'lastName'}
      />
    );
  }
}

FacilitatorsDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array,
  updateFacility: PropTypes.func.isRequired,
  getFacilityMemberships: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  facilityDetail: PropTypes.object,
  deleteFacilityMember: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    facilityDetail: state.facilities.facilityDetail,
  };
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, { updateFacility, getFacilityMemberships, showToast, deleteFacilityMember })(FacilitatorsDataTable));
