import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DataTable from '../../common/dataTable/dataTable.component';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classnames from 'classnames';
import { find } from 'lodash';
import { Button } from '@material-ui/core';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = theme => ({
  tableRowRoot: {
    cursor: 'pointer !important'
  },
  rowHighlight: {
    backgroundColor: theme.palette.primary.lightgray
  }
});

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true, filtering: true },
  { id: 'noteTypeId', numeric: false, disablePadding: false, label: 'Note Type', sortable: true, filtering: true },
  { id: 'enabled', numeric: false, disablePadding: false, label: 'Status', sortable: true, filtering: true },
  { id: 'providerGroupId', numeric: false, disablePadding: false, label: 'Provider Group', sortable: true, filtering: true }
];

class NoteTemplatesDataTable extends Component {
  handleAddClick = () => {
    this.props.history.push(`/notes/templates/create`);
  };

  handleEditClick = async (event, row) => {
    logEvent('edit_notetemplate', {
      adminId: this.props.user.email,
      noteTemplateId: row.id
    });

    this.props.history.push(`/notes/templates/${row.id}`);
  };

  // get custom row render component
  renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow
          tabIndex={-1}
          classes={{
            root:
              i % 2 === 0 ? classnames([this.props.classes.tableRowRoot, this.props.classes.rowHighlight]) : this.props.classes.tableRowRoot
          }}
        >
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">{row.noteTypeId}</TableCell>
          <TableCell align="left">{row.enabled}</TableCell>
          <TableCell align="left">{row.providerGroupId}</TableCell>
          <TableCell align="right">
            <Button onClick={event => this.handleEditClick(event, row)} color="primary" variant="outlined">
              VIEW
            </Button>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  render() {
    const { data } = this.props;

    let formattedData = data.map(row => {
      row = { ...row };

      let noteType = find(this.props.noteTypes, { id: row.noteTypeId });
      row.noteTypeId = noteType ? noteType.name : '';

      row.enabled = row.enabled ? 'Enabled' : 'Disabled';

      let providerGroup = find(this.props.providerGroups, { id: noteType ? noteType.providerGroupId : '' });
      row.providerGroupId = providerGroup ? providerGroup.name : '';

      return row;
    });

    return (
      <DataTable
        data={formattedData}
        title={''}
        columns={columns}
        renderRow={this.renderRow}
        disableSearch
        defaultSortOrder={'asc'}
        onAddClick={can(providerGroupPermissions.createNoteTemplate) ? this.handleAddClick : null}
      />
    );
  }
}

NoteTemplatesDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  providerGroups: PropTypes.array.isRequired,
  noteTypes: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.login.user
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    {}
  )(NoteTemplatesDataTable)
);
