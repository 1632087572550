import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class Timer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      duration: null // ms, moment duration object
    };

    this.timer = null;
  }

  componentDidMount() {
    if (this.props.duration) {
      this.initTimer(this.getDuration(null, this.props.duration));
    } else if (this.props.startTime) {
      this.initTimer(this.getDuration(this.props.startTime));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.duration) {
      this.initTimer(this.getDuration(null, nextProps.duration));
    } else if (nextProps.startTime) {
      this.initTimer(this.getDuration(nextProps.startTime));
    }
  }

  // clean up timer
  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);
  }

  // get a moment duration from either a start time or wait time ms
  getDuration(startTime, waitTimeMs) {
    let duration;
    if (startTime) {
      const now = moment();
      duration = moment.duration(now.diff(startTime));
    } else if (waitTimeMs) {
      duration = moment.duration(waitTimeMs);
    } else {
      duration = 0;
    }
    return duration;
  }

  initTimer(duration) {
    this.setState({ duration });
    if (this.timer) clearInterval(this.timer);
    // kick off timer as soon as component is mounted
    this.timer = setInterval(
      () =>
        this.setState({
          duration: this.state.duration.add(1, 'second')
        }),
      1000
    );
  }

  // return format hh:mm:ss
  // duration in ms
  getFormattedDuration = () => {
    const { duration } = this.state;
    if (duration) {
      const months = duration.months();

      if (months > 0) return 'Over a Month';

      const seconds = duration.seconds();
      const minutes = duration.minutes();
      let hours = duration.hours();
      const days = duration.days();

      const addHours = days * 24;
      hours += addHours;

      return `${hours > 0 ? hours + ':' : ''}${minutes < 10 && hours > 0 ? '0' + minutes : minutes}:${
        seconds < 10 ? '0' + seconds : seconds
      }`;
    }

    return '';
  };

  render() {
    return <div>{this.getFormattedDuration()}</div>;
  }
}

Timer.propTypes = {
  startTime: PropTypes.string,
  duration: PropTypes.number
};

export default Timer;
