import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Typography } from '@material-ui/core';

import PhoneNumberMask from '../../common/inputs/phoneNumberMask.component';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';

const PatientPharmacyInfoForm = ({ entranceId, pharmacy }) => {
  const hasReadPermission = can(entrancePermissions.readPatientPharmacy, entranceId);

  return hasReadPermission ? (
    <>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            fullWidth
            label="Pharmacy Name"
            margin="normal"
            value={pharmacy.StoreName || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            fullWidth
            label="Address Line 1"
            margin="normal"
            value={pharmacy.Address1 || ''}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            fullWidth
            label="Address Line 2"
            margin="normal"
            value={pharmacy.Address2 || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            fullWidth
            label="City"
            margin="normal"
            value={pharmacy.City || ''}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={4}>
          <TextField
            disabled
            fullWidth
            label="State"
            margin="normal"
            value={pharmacy.State || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            disabled
            fullWidth
            label="Zip Code"
            margin="normal"
            value={pharmacy.ZipCode || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            disabled
            fullWidth
            InputProps={{
              inputComponent: PhoneNumberMask,
            }}
            label="Phone Number"
            margin="normal"
            value={pharmacy.PrimaryPhone || ''}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <Typography style={{ marginBottom: '1rem', marginTop: '1rem' }} variant="body1">
      You do not have permission to view a patient&apos;s pharmacy information.
    </Typography>
  );
};

PatientPharmacyInfoForm.propTypes = {
  entranceId: PropTypes.string.isRequired,
  pharmacy: PropTypes.object,
};

PatientPharmacyInfoForm.defaultProps = {
  pharmacy: null,
};

export default PatientPharmacyInfoForm;
