import qs from 'query-string';
import { CALL_API } from '../../middleware/api';

export const PATIENT_SEARCH_REQUEST = 'PATIENT_SEARCH_REQUEST';
export const PATIENT_SEARCH_SUCCESS = 'PATIENT_SEARCH_SUCCESS';
export const PATIENT_SEARCH_FAILURE = 'PATIENT_SEARCH_FAILURE';

export const SAVE_PATIENT_REQUEST = 'SAVE_PATIENT_REQUEST';
export const SAVE_PATIENT_SUCCESS = 'SAVE_PATIENT_SUCCESS';
export const SAVE_PATIENT_FAILURE = 'SAVE_PATIENT_FAILURE';

export const PHARMACY_SEARCH_REQUEST = 'PHARMACY_SEARCH_REQUEST';
export const PHARMACY_SEARCH_SUCCESS = 'PHARMACY_SEARCH_SUCCESS';
export const PHARMACY_SEARCH_FAILURE = 'PHARMACY_SEARCH_FAILURE';

export const CLEAR_PHARMACIES = 'CLEAR_PHARMACIES';

export const patientSearch = (entranceId, searchString) => {
  const query = qs.stringify(
    {
      q: searchString,
    },
    { encode: true }
  );

  return {
    [CALL_API]: {
      types: [PATIENT_SEARCH_REQUEST, PATIENT_SEARCH_SUCCESS, PATIENT_SEARCH_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/entrances/${entranceId}/patients?${query}`,
      method: 'GET',
    },
  };
};

export const savePatient = (patientId, patient) => {
  return {
    [CALL_API]: {
      types: [SAVE_PATIENT_REQUEST, SAVE_PATIENT_SUCCESS, SAVE_PATIENT_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/patients/${patientId}`,
      method: 'PUT',
      payload: patient,
    },
  };
};

export const pharmacySearch = criteria => {
  const query = qs.stringify(criteria, { encode: true });

  return {
    [CALL_API]: {
      types: [PHARMACY_SEARCH_REQUEST, PHARMACY_SEARCH_SUCCESS, PHARMACY_SEARCH_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/pharmacysearch?${query}`,
      method: 'GET',
    },
  };
};

export const clearPharmacies = () => {
  return {
    type: CLEAR_PHARMACIES,
  };
};
