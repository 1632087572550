import React, { Component, Fragment, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import './loginForm.css';
import auth from '../../auth/auth';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState('');

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const validateEmail = () => {
    setEmailError('');

    if (!email) {
      setEmailError('An email address is required.');
    }
  };

  const validatePassword = () => {
    setPasswordError('');

    if (!password) {
      setPasswordError('A password is required.');
    }
  };

  const formIsInvalid = () => {
    setLoginError('');
    validateEmail();
    validatePassword();

    return !email || !password;
  };

  const handleLogin = event => {
    event.preventDefault();
    setIsLoading(true);

    if (formIsInvalid()) {
      setIsLoading(false);
    } else {
      auth.login(
        email,
        password,
        async success => {
          console.log('success!');
          window.location.reload(true);
        },
        async error => {
          setIsLoading(false);
          setLoginError(error.description);
        }
      );
    }
  };

  return (
    <div className="login-container">
      <div className="logo">
        <img src="https://storage.googleapis.com/bison-public-static2/brand_logos/relymd-full.svg" />
      </div>
      <div className="login-heading-container">
        <h3>Administrator Login</h3>
      </div>
      <div className="login-form-container">
        <form className="login-form" noValidate autoComplete="off" onSubmit={handleLogin}>
          <TextField
            fullWidth
            className="form-control"
            id="email"
            label="Email"
            value={email}
            onChange={handleEmailChange}
            type="email"
            variant="outlined"
            required
            error={emailError !== ''}
            helperText={emailError}
            onBlur={validateEmail}
          ></TextField>
          <TextField
            fullWidth
            className="form-control"
            id="password"
            label="Password"
            value={password}
            onChange={handlePasswordChange}
            type="password"
            variant="outlined"
            required
            error={passwordError !== ''}
            helperText={passwordError}
            onBlur={validatePassword}
          ></TextField>
          {loginError && (
            <div className="login-error-container">
              <div className="login-error">{loginError}</div>
            </div>
          )}
          <div className="login-button-container">
            <Button
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              Login
            </Button>
            {isLoading && <CircularProgress size={24} className="button-progress" />}
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
