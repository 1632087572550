import moment from 'moment';

// display months if someone is under this # years old
const MIN_YEARS_TO_SHOW_MONTHS = 2;

export const getAgeString = dob => {
  const ageDiff = moment().diff(dob, 'years');
  return `${moment().diff(dob, ageDiff < MIN_YEARS_TO_SHOW_MONTHS ? 'months' : 'years')} ${
    ageDiff < MIN_YEARS_TO_SHOW_MONTHS ? 'mo' : 'yr'
  }`;
};

export const lessThan12YearsAgo = dateFormat => date => {
  const THRESHOLD_YEARS = 12;

  try {
    const now = moment();
    const d = moment(date, dateFormat);
    const diff = now.diff(d, 'years');

    return diff < THRESHOLD_YEARS;
  } catch (e) {
    return false;
  }
};

export default { getAgeString };
