import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DataTable from '../../common/dataTable/dataTable.component';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import BoardRowExpansionPanel from './boardRowExpansionPanel.component';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import { getShortGender } from '../../utilities/genderUtils';
import { Collapse } from '@material-ui/core';
import classnames from 'classnames';
import Timer from '../../common/timer/timer.component';
import { getAgeString } from '../../utilities/dataUtils';
import NumberFormat from 'react-number-format';

const styles = theme => ({
  tableRowRoot: {
    cursor: 'pointer !important',
  },
  rowHighlight: {
    backgroundColor: theme.palette.primary.lightgray,
  },
  rowExpansionPanelRoot: {
    minHeight: '6rem',
  },
});

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  {
    id: 'patientName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    sortable: true,
    filtering: true,
  },
  {
    id: 'ageGender',
    numeric: false,
    disablePadding: false,
    label: 'Age/Gen',
    sortable: true,
    filtering: true,
  },
  { id: 'waitTime', numeric: false, disablePadding: false, label: 'Wait', sortable: true },
  {
    id: 'group',
    numeric: false,
    disablePadding: false,
    label: 'Group',
    sortable: true,
    filtering: true,
  },
  {
    id: 'entranceName',
    numeric: false,
    disablePadding: false,
    label: 'Entry',
    sortable: true,
    filtering: true,
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: 'Location',
    sortable: true,
    filtering: true,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    sortable: true,
    filtering: true,
  },
  {
    id: 'mobileNumber',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
    sortable: true,
    filtering: true,
  },
];

class BoardDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedRowId: null,
    };
  }

  handleRowClick = async (event, row) => {
    event.stopPropagation();
    event.preventDefault();

    // collapse already-open row
    if (row.id === this.state.expandedRowId) {
      this.setState({
        expandedRowId: null,
      });
    } else if (this.state.expandedRowId) {
      // finish collapsing one row before expanding another
      this.setState({
        expandedRowId: null,
      });
    } else {
      // expand an unexpanded row
      this.setState({
        expandedRowId: row.id,
      });
    }
  };

  // reset row expansion any time data changes in table
  handleTablePagingChange = () => {
    this.setState({
      expandedRowId: null,
    });
  };

  collapseComponent = props => (
    <TableRow>
      <td
        colSpan={columns.length + 1}
        style={{ backgroundColor: this.props.theme.palette.primary.white }}
      >
        <div className={props.className}>{props.children}</div>
      </td>
    </TableRow>
  );

  // get custom row render component
  renderRow = (row, i) => {
    const isRowExpanded = row.id === this.state.expandedRowId;

    return (
      <Fragment key={i}>
        <TableRow
          onClick={event => this.handleRowClick(event, row)}
          role="checkbox"
          tabIndex={-1}
          classes={{
            root:
              i % 2 === 0
                ? classnames([this.props.classes.tableRowRoot, this.props.classes.rowHighlight])
                : this.props.classes.tableRowRoot,
          }}
        >
          <TableCell>
            <div style={{ display: 'flex', marginLeft: -24, alignItems: 'center' }}>
              {isRowExpanded ? <ArrowDown /> : <ArrowRight />}
              <div>{row.patientName}</div>
            </div>
          </TableCell>
          <TableCell align="left">{row.ageGender}</TableCell>
          <TableCell align="left">
            <Timer duration={row.waitTime} />
          </TableCell>
          <TableCell align="left">{row.group}</TableCell>
          <TableCell align="left">{row.entranceName}</TableCell>
          <TableCell align="left">{row.state}</TableCell>
          <TableCell align="left">{row.email}</TableCell>
          <TableCell align="left">
            <NumberFormat format="(###) ###-####" value={(row.mobileNumber || '').replace(/^\+1/, '')} displayType={'text'} />
          </TableCell>
        </TableRow>
        <Collapse
          component={this.collapseComponent}
          in={isRowExpanded}
          timeout="auto"
          unmountOnExit
        >
          <BoardRowExpansionPanel data={row} />
        </Collapse>
      </Fragment>
    );
  };

  render() {
    const { data } = this.props;

    let formattedData = data.map(row => {
      row = { ...row };
      row.patientName = `${row.firstName} ${row.lastName}`;

      const gender = getShortGender(row.gender);
      const ageString = getAgeString(row.dob);
      row.ageGender = `${ageString}/${gender}`;

      row.state = row.location.addressState ? row.location.addressState : 'Unknown';
      return row;
    });

    return (
      <DataTable
        data={formattedData}
        title={''}
        columns={columns}
        renderRow={this.renderRow}
        disableSearch
        onPagingChanged={this.handleTablePagingChange}
        defaultSortOrder={'asc'}
        defaultSortField={'waitTime'}
      />
    );
  }
}

BoardDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {};
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, {})(BoardDataTable)
);
