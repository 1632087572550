/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/sort-comp */
import {
  Button,
  Card,
  CardContent,
  Typography,
  withStyles,
} from '@material-ui/core';
import { FACILITY_VISIT_DOC_PW_RESET_SUCCESS, FACILITY_VISIT_SEARCH_SUCCESS, facilityVisitDocPwReset, facilityVisitSearch } from '../facilities.actions';
import { connect } from 'react-redux';
import { has, isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FormControl from '@material-ui/core/FormControl';
import LoadingOverlay from '../../../common/loadingOverlay/loadingOverlay.component';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const styles = ({ palette, shadows }) => ({
  listIcon: {
    fontSize: '20px',
    width: 'unset',
    alignSelf: 'center',
    color: palette.primary.white,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1rem',
    minWidth: 400,
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '1rem',
  },
  textField: {
    width: '100%',
    margin: '1rem auto 0.5rem auto',
    color: palette.primary.darkgray,
  },
  button: {
    color: palette.primary.white,
    width: 100,
    // margin: '2rem auto 1rem auto',
    borderRadius: 10,
    backgroundColor: palette.primary.darkgray,
    '&:hover': {
      color: palette.primary.white,
      backgroundColor: palette.primary.darkgray,
    },
    '&:disabled': {
      color: palette.primary.darkgray,
      backgroundColor: palette.primary.lightgray,
    },
  },
  subText: {
    color: palette.primary.darkgray,
    fontSize: '1rem',
    fontWeight: 200,
  },
  card: {
    marginBottom: '1rem',
    cursor: 'pointer',
    boxShadow: shadows[5],
  },
  cardContentRoot: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    display: 'flex',
    '&:last-child': {
      paddingBottom: '1rem',
    },
  },
  iconRoot: {
    color: palette.primary.darkgray,
    fontSize: '2rem',
    justifySelf: 'flex-end',
    alignSelf: 'center',
  },
});

const initialState = {
  firstName: '',
  lastName: '',
  dob: '',
  visitDate: '',
  // firstName: 'SimonTestDemo',
  // lastName: '2',
  // dob: '2010-12-02',
  // visitDate: '2020-01-03',
  password: '',
  formErrors: {},
  visitResults: [],
  updatedPassword: '',
  visitSearchError: '',
};

class FacilityDocPasswordResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  // update can be event or value
  handleChange = (field) => (update) => {
    const updateState = { ...this.state };
    updateState[field] =
      update !== null && has(update.target, 'value') ? update.target.value : update;

    // clear error state on edit
    updateState.error = null;
    this.setState(updateState);
  };

  visitSearch = async () => {
    const payload = {
      facilityId: this.props.facilityDetail.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      dob: this.state.dob,
      visitDate: this.state.visitDate,
    };

    const result = await this.props.facilityVisitSearch(payload);
    if (result.type === FACILITY_VISIT_SEARCH_SUCCESS) {
      const { data } = result.response;
      if (!isEmpty(data)) {
        this.setState({
          visitResults: result.response.data,
        });
      } else {
        this.setState({
          visitSearchError: 'No Matching Visits Found',
        });
      }
    } else {
      this.setState({
        visitSearchError: 'Error Searching Visits',
      });
    }
  }

  resetPassword = async (visit) => {
    if (window.confirm('Are you sure you want to reset the document sharing password for this visit?')) {
      const result = await this.props.facilityVisitDocPwReset(visit.id);
      if (result.type === FACILITY_VISIT_DOC_PW_RESET_SUCCESS) {
        this.setState({
          updatedPassword: result.response.password,
        });
      } else {
        // do non-blocking alert
        setTimeout(() => {
          alert('Could not reset the visit document password. Please check your connection and try again.');
        }, 0);
      }
    }
  }

  formHasErrors = () => {
    const requiredFields = ['firstName', 'lastName', 'dob', 'visitDate'];
    return requiredFields.some((f) => isEmpty(this.state[f]));
  }

  render() {
    const { classes, handleCancel, theme, isSearchingVisits, isResetttingVisitPassword } = this.props;

    const {
      firstName,
      lastName,
      dob,
      visitDate,
      visitResults,
      updatedPassword,
      visitSearchError,
    } = this.state;

    if (!isEmpty(updatedPassword)) {
      return (
        <div className={classes.content}>
          <Typography variant="body1" style={{ fontSize: '1.2rem', fontWeight: 200 }}>The visit password has been reset.</Typography>
          <Typography variant="body1" style={{ fontSize: '1.2rem', color: theme.palette.primary.darkgray }}>{`New Password: ${updatedPassword}`}</Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <Button variant={'outlined'} color="primary" style={{ marginRight: '1rem' }}
              onClick={handleCancel}
            >
              {'DONE'}
            </Button>
          </div>
        </div>);
    }

    return (
      <div className={classes.content}>
        {!isEmpty(visitResults) &&
          <>
            {isResetttingVisitPassword && <LoadingOverlay />}
            <Typography className={classes.subText}>
              Select a visit to reset password
            </Typography>
            <div style={{ marginTop: '1rem' }}>
              {visitResults.map((vr, i) => {
                return (
                  <Card className={classes.card} onClick={() => this.resetPassword(vr)} key={i}>
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <Typography variant="body1" style={{ fontSize: '1.2rem', color: theme.palette.primary.darkgray, flex: 1, alignContent: 'center' }}>{moment(vr.created).format('MM/DD/YYYY h:mm a')}</Typography>
                      <ChevronRight classes={{ root: classes.iconRoot }} />
                    </CardContent>
                  </Card>);
              })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
              <Button variant={'outlined'} color="primary" onClick={() => this.setState({ visitResults: [] })}>
                {'BACK'}
              </Button>
            </div>
          </>
        }

        {isEmpty(visitResults) &&
          <>
            {isSearchingVisits && <LoadingOverlay />}
            <Typography className={classes.subText}>
              Enter patient information to search for a visit
            </Typography>
            <form className={classes.loginForm} onSubmit={this.handleDocumentLogin}>
              <TextField
                id="firstName"
                className={classes.textField}
                value={firstName}
                onChange={this.handleChange('firstName')}
                label={'First Name'}
                margin="normal"
                variant="outlined"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                id="lastName"
                className={classes.textField}
                value={lastName}
                onChange={this.handleChange('lastName')}
                label={'Last Name'}
                margin="normal"
                variant="outlined"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <FormControl className={classes.formControl}>
                <TextField
                  id="dob"
                  className={classes.textField}
                  value={dob}
                  onChange={this.handleChange('dob')}
                  label={'Date of Birth'}
                  margin="normal"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  id="visitDate"
                  className={classes.textField}
                  value={visitDate}
                  onChange={this.handleChange('visitDate')}
                  label={'Visit Date'}
                  margin="normal"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </FormControl>
            </form>
            <div style={{ margin: '0.5rem' }}>
              {!isEmpty(visitSearchError) &&
                <Typography className={classes.subText} style={{ color: theme.palette.primary.red }}>
                  {visitSearchError}
                </Typography>
              }
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
              <Button variant={'outlined'} color="primary" style={{ marginRight: '1rem' }}
                onClick={handleCancel}
              >
                {'CANCEL'}
              </Button>
              <Button variant={'contained'} color="primary" onClick={this.visitSearch}
                disabled={this.formHasErrors()}
              >
                {'SEARCH'}
              </Button>
            </div>
          </>
        }
      </div>
    );
  }
}

FacilityDocPasswordResetForm.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  facilityVisitSearch: PropTypes.func.isRequired,
  facilityVisitDocPwReset: PropTypes.func.isRequired,
  isResetttingVisitPassword: PropTypes.bool.isRequired,
  isSearchingVisits: PropTypes.bool.isRequired,
  facilityDetail: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isResetttingVisitPassword: state.facilities.isResetttingVisitPassword,
    isSearchingVisits: state.facilities.isSearchingVisits,
    facilityDetail: state.facilities.facilityDetail,
  };
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      facilityVisitSearch,
      facilityVisitDocPwReset,
    })(FacilityDocPasswordResetForm)
  )
);
