import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DataTable from '../../common/dataTable/dataTable.component';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classnames from 'classnames';
import { find } from 'lodash';
import { Button } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = theme => ({
  tableRowRoot: {
    cursor: 'pointer !important'
  },
  rowHighlight: {
    backgroundColor: theme.palette.primary.lightgray
  }
});

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true, filtering: true },
  { id: 'code', numeric: false, disablePadding: false, label: 'Code', sortable: true, filtering: true },
  { id: 'price', numeric: false, disablePadding: false, label: 'Price', sortable: true, filtering: true },
  { id: 'idName', numeric: false, disablePadding: false, label: 'ID Name', sortable: true, filtering: true },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', sortable: true, filtering: true },
  { id: 'entranceId', numeric: false, disablePadding: false, label: 'Entrance', sortable: true, filtering: true }
];

class PricingGroupsDataTable extends Component {
  handleAddClick = () => {
    this.props.history.push(`/pricing/groups/create`);
  };

  handleEditClick = async (event, row) => {
    logEvent('edit_pricinggroup', {
      adminId: this.props.user.email,
      pricingGroupId: row.id
    });
    this.props.history.push(`/pricing/groups/${row.id}`);
  };

  // get custom row render component
  renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow
          tabIndex={-1}
          classes={{
            root:
              i % 2 === 0 ? classnames([this.props.classes.tableRowRoot, this.props.classes.rowHighlight]) : this.props.classes.tableRowRoot
          }}
        >
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">{row.code}</TableCell>
          <TableCell align="left">
            <NumberFormat value={row.price} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale />
          </TableCell>
          <TableCell align="left">{row.idName}</TableCell>
          <TableCell align="left">{row.status}</TableCell>
          <TableCell align="left">{row.entranceId}</TableCell>
          <TableCell align="right">
            <Button onClick={event => this.handleEditClick(event, row)} color="primary" variant="outlined">
              {'VIEW'}
            </Button>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  render() {
    const { data } = this.props;

    let formattedData = data.map(row => {
      row = { ...row };

      row.price = row.price / 100;
      row.idName = row.idName ? row.idName : '';

      let entrance = find(this.props.entrances, { id: row.entranceId });
      row.entranceId = entrance ? entrance.name : '';

      return row;
    });

    return (
      <DataTable
        data={formattedData}
        title={''}
        columns={columns}
        renderRow={this.renderRow}
        disableSearch
        defaultSortOrder={'asc'}
        onAddClick={can(entrancePermissions.createPricingGroup) ? this.handleAddClick : null}
      />
    );
  }
}

PricingGroupsDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  entrances: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.login.user
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    {}
  )(PricingGroupsDataTable)
);
