import { combineReducers } from 'redux';

import layout from './modules/layout/layout.reducer';
import login from './modules/login/login.reducer';
import board from './modules/board/board.reducer';
import pricingBases from './modules/pricing/pricingBases.reducer';
import pricingGroups from './modules/pricing/pricingGroups.reducer';
import pricingGroupMembers from './modules/pricing/pricingGroupMembers.reducer';
import noteTypes from './modules/notes/noteTypes.reducer';
import noteTemplates from './modules/notes/noteTemplates.reducer';
import coupons from './modules/coupons/coupons.reducer';
import altCharges from './modules/altCharges/altCharges.reducer';
import marketing from './modules/marketing/marketing.reducer';
import outageBanner from './modules/outageBanner/outageBanner.reducer';
import facilities from './modules/facilities/facilities.reducer';
import patients from './modules/patients/patients.reducer';

const appReducer = combineReducers({
  layout,
  login,
  board,
  pricingBases,
  pricingGroups,
  pricingGroupMembers,
  noteTypes,
  noteTemplates,
  coupons,
  altCharges,
  marketing,
  outageBanner,
  facilities,
  patients,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
