import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import MainLayout from './modules/layout/main.layout';
import PropTypes from 'prop-types';
import React from 'react';

const Root = ({ store }) => (
  <Provider store={store}>
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Route path="/" component={MainLayout} />
    </div>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired
};
export default Root;
