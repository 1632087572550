import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const ZipNumberMask = props => {
  const { inputRef, name, onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      format="#####"
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
    />
  );
};

ZipNumberMask.propTypes = {
  inputRef: PropTypes.any,
  name: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

ZipNumberMask.defaultProps = {
  inputRef: null,
  name: '',
};

export default ZipNumberMask;
