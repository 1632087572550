import { Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { hideToast } from './layout.actions';

class Toast extends Component {
  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={this.props.isShowToast}
          onClose={this.props.hideToast}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id">{this.props.toastMessage}</span>}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={this.props.hideToast}>
              <CloseIcon onClick={this.hideToast} />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

Toast.propTypes = {
  isShowToast: PropTypes.bool.isRequired,
  toastMessage: PropTypes.string.isRequired,
  isToastFailure: PropTypes.bool,
  toastPosition: PropTypes.string,
  responsiveBreakpoint: PropTypes.object.isRequired,
  hideToast: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    isShowToast: state.layout.isShowToast,
    toastMessage: state.layout.toastMessage,
    isToastFailure: state.layout.isToastFailure,
    toastPosition: state.layout.toastPosition,
    responsiveBreakpoint: state.layout.responsiveBreakpoint
  };
};

export default connect(
  mapStateToProps,
  {
    hideToast
  }
)(Toast);
