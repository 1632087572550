import { CALL_API } from '../../middleware/api';
import qs from 'query-string';

export const GET_FACILITIES_REQUEST = 'GET_FACILITIES_REQUEST';
export const GET_FACILITIES_SUCCESS = 'GET_FACILITIES_SUCCESS';
export const GET_FACILITIES_FAILURE = 'GET_FACILITIES_FAILURE';

export const GET_FACILITY_REQUEST = 'GET_FACILITY_REQUEST';
export const GET_FACILITY_SUCCESS = 'GET_FACILITY_SUCCESS';
export const GET_FACILITY_FAILURE = 'GET_FACILITY_FAILURE';

export const CREATE_FACILITY_REQUEST = 'CREATE_FACILITY_REQUEST';
export const CREATE_FACILITY_SUCCESS = 'CREATE_FACILITY_SUCCESS';
export const CREATE_FACILITY_FAILURE = 'CREATE_FACILITY_FAILURE';

export const UPDATE_FACILITY_REQUEST = 'UPDATE_FACILITY_REQUEST';
export const UPDATE_FACILITY_SUCCESS = 'UPDATE_FACILITY_SUCCESS';
export const UPDATE_FACILITY_FAILURE = 'UPDATE_FACILITY_FAILURE';

export const DISABLE_FACILITY_REQUEST = 'DISABLE_FACILITY_REQUEST';
export const DISABLE_FACILITY_SUCCESS = 'DISABLE_FACILITY_SUCCESS';
export const DISABLE_FACILITY_FAILURE = 'DISABLE_FACILITY_FAILURE';

export const FACILITATOR_SEARCH_REQUEST = 'FACILITATOR_SEARCH_REQUEST';
export const FACILITATOR_SEARCH_SUCCESS = 'FACILITATOR_SEARCH_SUCCESS';
export const FACILITATOR_SEARCH_FAILURE = 'FACILITATOR_SEARCH_FAILURE';

export const GET_FACILITY_MEMBERSHIPS_REQUEST = 'GET_FACILITY_MEMBERSHIPS_REQUEST';
export const GET_FACILITY_MEMBERSHIPS_SUCCESS = 'GET_FACILITY_MEMBERSHIPS_SUCCESS';
export const GET_FACILITY_MEMBERSHIPS_FAILURE = 'GET_FACILITY_MEMBERSHIPS_FAILURE';

export const ADD_FACILITY_MEMBER_REQUEST = 'ADD_FACILITY_MEMBER_REQUEST';
export const ADD_FACILITY_MEMBER_SUCCESS = 'ADD_FACILITY_MEMBER_SUCCESS';
export const ADD_FACILITY_MEMBER_FAILURE = 'ADD_FACILITY_MEMBER_FAILURE';

export const DELETE_FACILITY_MEMBER_REQUEST = 'DELETE_FACILITY_MEMBER_REQUEST';
export const DELETE_FACILITY_MEMBER_SUCCESS = 'DELETE_FACILITY_MEMBER_SUCCESS';
export const DELETE_FACILITY_MEMBER_FAILURE = 'DELETE_FACILITY_MEMBER_FAILURE';

export const FACILITY_VISIT_SEARCH_REQUEST = 'FACILITY_VISIT_SEARCH_REQUEST';
export const FACILITY_VISIT_SEARCH_SUCCESS = 'FACILITY_VISIT_SEARCH_SUCCESS';
export const FACILITY_VISIT_SEARCH_FAILURE = 'FACILITY_VISIT_SEARCH_FAILURE';

export const FACILITY_VISIT_DOC_PW_RESET_REQUEST = 'FACILITY_VISIT_DOC_PW_RESET_REQUEST';
export const FACILITY_VISIT_DOC_PW_RESET_SUCCESS = 'FACILITY_VISIT_DOC_PW_RESET_SUCCESS';
export const FACILITY_VISIT_DOC_PW_RESET_FAILURE = 'FACILITY_VISIT_DOC_PW_RESET_FAILURE';

export const PROCESS_FACILITY_MEMBER_IMPORT_REQUEST = 'PROCESS_FACILITY_MEMBER_IMPORT_REQUEST';
export const PROCESS_FACILITY_MEMBER_IMPORT_SUCCESS = 'PROCESS_FACILITY_MEMBER_IMPORT_SUCCESS';
export const PROCESS_FACILITY_MEMBER_IMPORT_FAILURE = 'PROCESS_FACILITY_MEMBER_IMPORT_FAILURE';

export const CLEAR_FACILITY_DETAIL = 'CLEAR_FACILITY_DETAIL';

export const getFacilities = () => {
  return {
    [CALL_API]: {
      types: [GET_FACILITIES_REQUEST, GET_FACILITIES_SUCCESS, GET_FACILITIES_FAILURE],
      authenticated: true,
      endpoint: 'v1/admin/facilities',
      method: 'GET',
    },
  };
};

export const getFacility = id => {
  return {
    [CALL_API]: {
      types: [GET_FACILITY_REQUEST, GET_FACILITY_SUCCESS, GET_FACILITY_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/facilities/${id}`,
      method: 'GET',
    },
  };
};

export const createFacility = payload => {
  return {
    [CALL_API]: {
      types: [CREATE_FACILITY_REQUEST, CREATE_FACILITY_SUCCESS, CREATE_FACILITY_FAILURE],
      authenticated: true,
      endpoint: 'v1/admin/facilities',
      method: 'POST',
      payload,
    },
  };
};

export const updateFacility = (id, payload) => {
  // id not allowed in update payload
  delete payload.id;

  return {
    [CALL_API]: {
      types: [UPDATE_FACILITY_REQUEST, UPDATE_FACILITY_SUCCESS, UPDATE_FACILITY_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/facilities/${id}`,
      method: 'PUT',
      payload,
    },
  };
};

export const disableFacility = id => {
  return {
    [CALL_API]: {
      types: [DISABLE_FACILITY_REQUEST, DISABLE_FACILITY_SUCCESS, DISABLE_FACILITY_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/facilities/${id}`,
      method: 'DELETE',
    },
  };
};

export const clearFacilityDetail = () => {
  return {
    type: CLEAR_FACILITY_DETAIL,
  };
};

export const facilitatorSearch = (entranceId, searchString) => {
  const query = qs.stringify(
    {
      email: searchString,
    },
    { encode: true }
  );
  return {
    [CALL_API]: {
      types: [FACILITATOR_SEARCH_REQUEST, FACILITATOR_SEARCH_SUCCESS, FACILITATOR_SEARCH_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/entrances/${entranceId}/accounts?${query}`,
      method: 'GET',
    },
  };
};

export const getFacilityMemberships = facilityId => {
  return {
    [CALL_API]: {
      types: [
        GET_FACILITY_MEMBERSHIPS_REQUEST,
        GET_FACILITY_MEMBERSHIPS_SUCCESS,
        GET_FACILITY_MEMBERSHIPS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/admin/facilitymemberships?facilityId=${facilityId}`,
      method: 'GET',
    },
  };
};

export const addFacilityMember = payload => {
  return {
    [CALL_API]: {
      types: [
        ADD_FACILITY_MEMBER_REQUEST,
        ADD_FACILITY_MEMBER_SUCCESS,
        ADD_FACILITY_MEMBER_FAILURE,
      ],
      authenticated: true,
      endpoint: 'v1/admin/facilitymemberships',
      method: 'POST',
      payload,
    },
  };
};

export const deleteFacilityMember = membershipId => {
  return {
    [CALL_API]: {
      types: [
        DELETE_FACILITY_MEMBER_REQUEST,
        DELETE_FACILITY_MEMBER_SUCCESS,
        DELETE_FACILITY_MEMBER_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/admin/facilitymemberships/${membershipId}`,
      method: 'DELETE',
    },
  };
};

export const facilityVisitSearch = params => {
  const query = qs.stringify(params);
  return {
    [CALL_API]: {
      types: [
        FACILITY_VISIT_SEARCH_REQUEST,
        FACILITY_VISIT_SEARCH_SUCCESS,
        FACILITY_VISIT_SEARCH_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/admin/visitsearch?${query}`,
      method: 'GET',
    },
  };
};

export const facilityVisitDocPwReset = visitId => {
  return {
    [CALL_API]: {
      types: [
        FACILITY_VISIT_DOC_PW_RESET_REQUEST,
        FACILITY_VISIT_DOC_PW_RESET_SUCCESS,
        FACILITY_VISIT_DOC_PW_RESET_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/admin/visits/${visitId}/password`,
      method: 'PUT',
    },
  };
};

export const processFacilityMemberImport = (facilityId, uploadId) => {
  return {
    [CALL_API]: {
      types: [
        PROCESS_FACILITY_MEMBER_IMPORT_REQUEST,
        PROCESS_FACILITY_MEMBER_IMPORT_SUCCESS,
        PROCESS_FACILITY_MEMBER_IMPORT_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/admin/facilities/${facilityId}/members/uploads/${uploadId}/process`,
      method: 'POST',
    },
  };
};
