import { ADD_FACILITY_MEMBER_SUCCESS, addFacilityMember, facilitatorSearch } from '../facilities.actions';
import { connect } from 'react-redux';
import { has, isEmpty } from 'lodash';
import { showToast } from '../../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Colors from '../../../styles/colors';
import FacilitatorSearchResults from './facilitatorSearchResults.component';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  formContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    minHeight: 400,
    minWidth: 800,
  },
  formControl: {
    width: '100%',
  },
  marginVertical: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  textField: {},
  gridItem: {},
  secondaryButton: {
    color: Colors.primary.main,
  },
});

class FacilitatorSearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      searchResults: [],
    };

    this.searchTimer = null;
  }

  handleChange = (name) => (event) => {
    let updateObj = { ...this.state, [name]: event.target.value };
    this.setState(updateObj);
  };

  debounceSearch = (name) => (evt) => {
    this.handleChange(name)(evt);

    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(async () => {
      if (!isEmpty(this.state.email)) {
        const result = await this.props.facilitatorSearch(this.props.entranceId, this.state.email);
        if (has(result, 'response.data') && !isEmpty(result.response.data)) {
          this.setState({ searchResults: result.response.data });
        } else {
          this.setState({
            searchResults: [],
          });
        }
      } else {
        this.setState({
          searchResults: [],
        });
      }
    }, 250);
  };

  handleAddMember = async (member) => {
    const facilityId = this.props.facilityDetail.id;

    const result = await this.props.addFacilityMember({
      facilityId,
      email: member.email,
    });

    if (result.type === ADD_FACILITY_MEMBER_SUCCESS) {
      this.props.onAddMember();
    } else {
      this.props.showToast('Could not add member. Please check your connection and try again.');
    }
  };

  render() {
    let { classes, facilityMemberships } = this.props;
    const { email, searchResults } = this.state;

    const filteredSearchResults = searchResults.filter((sr) => {
      return facilityMemberships.findIndex((fm) => fm.email === sr.email) === -1;
    });

    return (
      <form className={classes.formContainer} onSubmit={this.handleSearch}>
        <Grid container spacing={16}>
          <Grid item xs={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <TextField
                id="email"
                className={classes.textField}
                value={email}
                onChange={this.debounceSearch('email')}
                label={'E-Mail'}
                variant="outlined"
                helperText={'Start typing to search'}
                autoComplete="off"
              />
            </FormControl>
          </Grid>
          {/* <Grid item style={{ alignSelf: 'center' }}>
            <Button variant={'outlined'} type="submit" onClick={this.handleSearch} color="primary" disabled={email.trim().length <= 0}>
              {'SEARCH'}
            </Button>
          </Grid> */}
          {/* {searchResults.length > 0 && ( */}
          <Paper style={{ margin: '1rem', width: '100%' }}>
            <Grid item xs={12} style={{ maxHeight: 300, minWidth: 500, overflow: 'auto' }}>
              <FacilitatorSearchResults handleAdd={this.handleAddMember} data={filteredSearchResults} />
            </Grid>
          </Paper>
          {/* )} */}
        </Grid>
      </form>
    );
  }
}

FacilitatorSearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
  entranceId: PropTypes.string.isRequired,
  facilitatorSearch: PropTypes.func.isRequired,
  facilityDetail: PropTypes.object,
  addFacilityMember: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  onAddMember: PropTypes.func.isRequired,
  facilityMemberships: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    facilityDetail: state.facilities.facilityDetail,
    facilityMemberships: state.facilities.facilityMemberships,
  };
};

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, {
      facilitatorSearch,
      addFacilityMember,
      showToast,
    })(FacilitatorSearchForm)));
