import React from 'react';
import Keymap from '@convertkit/slate-keymap';

// WrapperType: must be capitol to allow run time type assignment
// https://reactjs.org/docs/jsx-in-depth.html#props-in-jsx
export default function blockPlugin({ blockType, WrapperType, hotKey, command, activeQuery, defaultBlock }) {
  function renderBlock(props, editor, next) {
    const { children, node, attributes } = props;

    if (node.type === blockType) {
      return <WrapperType {...attributes}>{children}</WrapperType>;
    } else {
      return next();
    }
  }

  function toggle(editor) {
    const type = isActive(editor) ? defaultBlock : blockType;
    editor.setBlocks(type);
  }

  function isActive(editor) {
    return editor.value.blocks.some(node => node.type === blockType);
  }

  const keyMapPlugin = hotKey ? [Keymap({ [hotKey]: command })] : [];

  return [
    {
      renderBlock,
      renderNode: renderBlock, // COMPAT: renderNode is removed in slate
      commands: { [command]: toggle },
      queries: { [activeQuery]: isActive }
    },
    ...keyMapPlugin
  ];
}
