import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import {
  getPricingBases,
  //createPricingBase,
  savePricingBase,
  //deletePricingBase,
  //CREATE_PRICING_BASE_SUCCESS,
  SAVE_PRICING_BASE_SUCCESS
} from './pricingBases.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import PricingBaseFormComponent from './pricingBaseForm.component';
import { find } from 'lodash';
import { showToast } from '../layout/layout.actions';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = theme => ({
  container: {
    padding: '3rem',
    height: 'calc(100% - 2rem)'
  }
});

class PricingBaseContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleSavePricingBase = this.handleSavePricingBase.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async handleSavePricingBase(id, pricingBase) {
    const { pricingBaseId } = this.props.match.params;

    if (id) {
      logEvent('save_basepricing', {
        adminId: this.props.user.email,
        basePricingId: pricingBaseId
      });
      let response = await this.props.savePricingBase(id, pricingBase);
      if (response.type === SAVE_PRICING_BASE_SUCCESS) {
        this.props.history.goBack();
      } else {
        this.props.showToast('Could not save the Pricing Base. Please check your connection and try again.');
      }
    } /*else {
      let response = await this.props.createPricingBase(pricingBase);
      if (response.type === CREATE_PRICING_BASE_SUCCESS) {
        this.props.history.goBack();
      } else {
        this.props.showToast('Could not create the Pricing Base. Please check your connection and try again.');
      }
    }*/
  }

  handleCancel() {
    this.props.history.goBack();
  }

  componentWillMount() {
    this.props.setPageTitle('Pricing Base');
    this.props.getPricingBases();
  }

  render() {
    const { classes, isLoading, isSaving, pricingBases } = this.props;
    const { pricingBaseId } = this.props.match.params;

    if (isLoading === true) {
      return <LoadingOverlay />;
    }

    const pricingBase = find(pricingBases, { id: pricingBaseId });
    const disabled = pricingBase
      ? !can(entrancePermissions.updateBasePrice, pricingBase.entranceId)
      : !can(entrancePermissions.createBasePrice, null);

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        {isSaving && <LoadingOverlay />}
        <PricingBaseFormComponent
          pricingBase={pricingBase}
          handleSavePricingBase={this.handleSavePricingBase}
          handleCancel={this.handleCancel}
          disabled={disabled}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pricingBases: state.pricingBases.pricingBases,
    isLoading: state.pricingBases.isLoading,
    isSaving: state.pricingBases.isSaving,
    user: state.login.user
  };
};

PricingBaseContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        getPricingBases,
        //createPricingBase,
        savePricingBase,
        //deletePricingBase,
        showToast
      }
    )(PricingBaseContainer)
  )
);
