import {
  CLEAR_FACILITY_DETAIL,
  FACILITY_VISIT_DOC_PW_RESET_FAILURE,
  FACILITY_VISIT_DOC_PW_RESET_REQUEST,
  FACILITY_VISIT_DOC_PW_RESET_SUCCESS,
  FACILITY_VISIT_SEARCH_FAILURE,
  FACILITY_VISIT_SEARCH_REQUEST,
  FACILITY_VISIT_SEARCH_SUCCESS,
  GET_FACILITIES_FAILURE,
  GET_FACILITIES_REQUEST,
  GET_FACILITIES_SUCCESS,
  GET_FACILITY_FAILURE,
  GET_FACILITY_MEMBERSHIPS_FAILURE,
  GET_FACILITY_MEMBERSHIPS_REQUEST,
  GET_FACILITY_MEMBERSHIPS_SUCCESS,
  GET_FACILITY_REQUEST,
  GET_FACILITY_SUCCESS,
} from './facilities.actions';
import produce from 'immer';

const initialState = {
  facilities: [],
  isLoading: false,
  isSearchingVisits: false,
  isResetttingVisitPassword: false,
  facilityDetail: null,
  facilityMemberships: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_FACILITIES_REQUEST:
      draft.isLoading = true;
      break;

    case GET_FACILITIES_SUCCESS:
      draft.facilities = action.response.data;
      draft.isLoading = false;
      break;

    case GET_FACILITIES_FAILURE:
      draft.isLoading = false;
      break;

    case GET_FACILITY_REQUEST:
      draft.isLoading = true;
      break;

    case GET_FACILITY_SUCCESS:
      draft.facilityDetail = action.response;
      draft.isLoading = false;
      break;

    case GET_FACILITY_FAILURE:
      draft.isLoading = false;
      break;

    case GET_FACILITY_MEMBERSHIPS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_FACILITY_MEMBERSHIPS_SUCCESS:
      draft.facilityMemberships = action.response.data;
      draft.isLoading = false;
      break;

    case GET_FACILITY_MEMBERSHIPS_FAILURE:
      draft.isLoading = false;
      break;

    case CLEAR_FACILITY_DETAIL:
      draft.facilityDetail = null;
      draft.facilityMemberships = null;
      break;

    case FACILITY_VISIT_SEARCH_REQUEST:
      draft.isSearchingVisits = true;
      break;

    case FACILITY_VISIT_SEARCH_SUCCESS:
      draft.isSearchingVisits = false;
      break;

    case FACILITY_VISIT_SEARCH_FAILURE:
      draft.isSearchingVisits = false;
      break;

    case FACILITY_VISIT_DOC_PW_RESET_REQUEST:
      draft.isResetttingVisitPassword = true;
      break;

    case FACILITY_VISIT_DOC_PW_RESET_SUCCESS:
      draft.isResetttingVisitPassword = false;
      break;

    case FACILITY_VISIT_DOC_PW_RESET_FAILURE:
      draft.isResetttingVisitPassword = false;
      break;

    default:
      break;
  }
}, initialState);
