import { genderTypes } from '../types/genderTypes';

export const getShortGender = gender => {
  switch (gender.toLowerCase()) {
    case genderTypes.MALE:
      return 'M';

    case genderTypes.FEMALE:
      return 'F';

    case genderTypes.ASK:
      return 'Ask';

    default:
      return 'Unknown';
  }
};

export const getLongGender = gender => {
  switch (gender) {
    case genderTypes.MALE:
      return 'Male';

    case genderTypes.FEMALE:
      return 'Female';

    case genderTypes.ASK:
      return 'Ask';

    default:
      return 'Unknown';
  }
};

export const genderOptions = [
  {
    value: genderTypes.MALE,
    label: getLongGender(genderTypes.MALE),
  },
  {
    value: genderTypes.FEMALE,
    label: getLongGender(genderTypes.FEMALE),
  },
  {
    value: genderTypes.ASK,
    label: getLongGender(genderTypes.ASK),
  },
];
