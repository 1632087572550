import { isEmpty } from 'lodash';
import getStore from '../../store/store';

const store = getStore();

export const can = (action, id) => {
  const state = store.getState();
  const permissions = state.login.permissions;
  const roles = state.login.roles;

  //If an id has been passed check just the permissions that match that entranceId or providerGroupId
  //If null is passed its a create action and we need to check if the user has that permission across any entity

  if (roles.indexOf('rmd:super') !== -1) {
    return true;
  }

  if (id) {
    let idPermissions = [];
    permissions.forEach(permission => {
      if (permission.role.startsWith(id)) {
        idPermissions = idPermissions.concat(permission.permissions);
      }
    });
    return idPermissions.includes(action);
  } else if (!isEmpty(permissions)) {
    let allPermissions = [];
    permissions.forEach(permission => {
      allPermissions = allPermissions.concat(permission.permissions);
    });
    return allPermissions.includes(action);
  }
};
