import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { find, isNil } from 'lodash';
import { PriceTextField } from '../../common/priceTextField/priceTextField.component';
import moment from 'moment';
import { SAVE_COUPON_SUCCESS } from './coupons.actions';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';

const coupontypes = {
  PERCENT_OFF: 'percentOff',
  AMOUNT_OFF: 'amountOff',
  NEW_PRICE: 'newPrice'
};

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  gridItem: {}
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

class CouponForm extends Component {
  constructor(props) {
    super(props);

    const coupon = props.coupon;

    //We don't explicitly store the couponType so we'll derive the value from which amount is specified
    let couponType = coupontypes.PERCENT_OFF;
    if (coupon && coupon.percentOff !== null) couponType = coupontypes.PERCENT_OFF;
    if (coupon && coupon.amountOff !== null) couponType = coupontypes.AMOUNT_OFF;
    if (coupon && coupon.newPrice !== null) couponType = coupontypes.NEW_PRICE;

    this.state = {
      entranceId: coupon ? coupon.entranceId : '',
      name: coupon ? coupon.name : '',
      code: coupon ? coupon.code : '',
      couponType,
      percentOff: coupon ? coupon.percentOff : '',
      amountOff: coupon ? coupon.amountOff / 100 : '',
      newPrice: coupon ? coupon.newPrice / 100 : '',
      startDate: coupon ? moment.utc(coupon.startDate).format('YYYY-MM-DD') : '',
      endDate: coupon && !isNil(coupon.endDate) ? moment.utc(coupon.endDate).format('YYYY-MM-DD') : '',
      maxTotalUses: coupon ? (coupon.maxTotalUses ? coupon.maxTotalUses : '') : '',
      maxIndividualUses: coupon ? (coupon.maxIndividualUses ? coupon.maxIndividualUses : '') : '',
      pricingGroups: coupon ? (coupon.pricingGroups ? coupon.pricingGroups : []) : [],
      accounts: coupon ? (coupon.accounts ? coupon.accounts : []) : [],
      status: coupon ? coupon.status : 'enabled',
      labelWidthEntrance: 0,
      labelWidthCouponType: 0,
      labelWidthPricingGroups: 0,
      labelWidthAccounts: 0
    };
  }

  componentDidMount() {
    this.setState({
      labelWidthEntrance: ReactDOM.findDOMNode(this.inputLabelRefEntrance).offsetWidth,
      labelWidthCouponType: ReactDOM.findDOMNode(this.inputLabelRefCouponType).offsetWidth,
      labelWidthPricingGroups: ReactDOM.findDOMNode(this.inputLabelRefPricingGroups).offsetWidth,
      labelWidthAccounts: ReactDOM.findDOMNode(this.inputLabelRefAccounts).offsetWidth
    });
  }

  getEntranceOptions = () => {
    return this.props.entrances
      .filter(entrance => (this.props.coupon ? true : can(entrancePermissions.createCoupon, entrance.id)))
      .map((entrance, i) => {
        return (
          <MenuItem value={entrance.id} key={i}>
            {entrance.name}
          </MenuItem>
        );
      });
  };

  handleChange = name => event => {
    let updateObj = { [name]: event.target.value };

    if (name === 'entranceId') {
      if (updateObj.entranceId !== this.state.entranceId) {
        updateObj.pricingGroups = [];
        updateObj.accounts = [];
      }
    }

    this.setState(updateObj);
  };

  formHasErrors = () => {
    return (
      this.state.entranceId === '' ||
      this.state.name === '' ||
      this.state.code === '' ||
      this.state.couponType === '' ||
      (this.state.couponType === coupontypes.PERCENT_OFF && this.state.percentOff === '') ||
      (this.state.couponType === coupontypes.AMOUNT_OFF && this.state.amountOff === '') ||
      (this.state.couponType === coupontypes.NEW_PRICE && this.state.newPrice === '') ||
      this.state.startDate === ''
    );
  };

  handleSaveCoupon = () => {
    let coupon = {
      entranceId: this.state.entranceId,
      name: this.state.name,
      code: this.state.code,
      maxTotalUses: this.state.maxTotalUses !== '' ? this.state.maxTotalUses : null,
      maxIndividualUses: this.state.maxIndividualUses !== '' ? this.state.maxIndividualUses : null,
      pricingGroups: this.state.pricingGroups.length === 0 ? null : this.state.pricingGroups,
      accounts: this.state.accounts.length === 0 ? null : this.state.accounts,
      endDate: this.state.endDate ? this.state.endDate : null
    };

    if (this.state.couponType === coupontypes.PERCENT_OFF) {
      coupon['percentOff'] = this.state.percentOff;
    }
    if (this.state.couponType === coupontypes.AMOUNT_OFF) {
      coupon['amountOff'] = this.state.amountOff * 100;
    }
    if (this.state.couponType === coupontypes.NEW_PRICE) {
      coupon['newPrice'] = this.state.newPrice * 100;
    }

    if (this.state.startDate !== '') {
      coupon['startDate'] = this.state.startDate;
    }

    this.props.handleSaveCoupon(this.props.coupon ? this.props.coupon.id : null, coupon);
  };

  handleToggleCouponEnable = async () => {
    let status = this.state.status === 'enabled' ? 'disabled' : 'enabled';

    let coupon = {
      entranceId: this.props.coupon.entranceId,
      name: this.props.coupon.name,
      code: this.props.coupon.code,
      maxTotalUses: this.props.coupon.maxTotalUses,
      maxIndividualUses: this.props.coupon.maxIndividualUses,
      pricingGroups: this.props.coupon.pricingGroups,
      accounts: this.props.coupon.accounts,
      percentOff: this.props.coupon.percentOff,
      amountOff: this.props.coupon.amountOff,
      newPrice: this.props.coupon.newPrice,
      startDate: this.props.coupon.startDate,
      endDate: this.props.coupon.endDate,
      status
    };

    let response = await this.props.handleToggleCouponEnable(this.props.coupon.id, coupon);
    if (response.type === SAVE_COUPON_SUCCESS) {
      this.setState({ status });
    }
  };

  handleDeleteCoupon = () => {
    let coupon = {
      entranceId: this.props.coupon.entranceId,
      name: this.props.coupon.name,
      code: this.props.coupon.code,
      maxTotalUses: this.props.coupon.maxTotalUses,
      maxIndividualUses: this.props.coupon.maxIndividualUses,
      pricingGroups: this.props.coupon.pricingGroups,
      accounts: this.props.coupon.accounts,
      percentOff: this.props.coupon.percentOff,
      amountOff: this.props.coupon.amountOff,
      newPrice: this.props.coupon.newPrice,
      startDate: this.props.coupon.startDate,
      endDate: this.props.coupon.endDate,
      status: 'deleted'
    };

    this.props.handleDeleteCoupon(this.props.coupon.id, coupon);
  };

  render() {
    let { classes, disabled } = this.props;
    const {
      entranceId,
      name,
      code,
      couponType,
      percentOff,
      amountOff,
      newPrice,
      startDate,
      endDate,
      maxTotalUses,
      maxIndividualUses,
      pricingGroups,
      accounts,
      labelWidthEntrance,
      labelWidthCouponType,
      labelWidthPricingGroups,
      labelWidthAccounts
    } = this.state;

    //Need to filter pricingGroups and accounts based on the selected entrance
    let filteredPricingGroups = this.props.pricingGroups.filter(pricingGroup => pricingGroup.entranceId === this.state.entranceId);
    let filteredAccounts = this.props.accounts.filter(account => account.entranceId === this.state.entranceId);

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={16}>
            <Grid item xs={12} className={classes.gridItem}>
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.inputLabelRefEntrance = ref;
                  }}
                  htmlFor="entranceId-placeholder"
                >
                  Entrance
                </InputLabel>
                <Select
                  value={entranceId}
                  onChange={this.handleChange('entranceId')}
                  input={<OutlinedInput labelWidth={labelWidthEntrance} name="entranceId" id="entranceId-placeholder" />}
                  disabled={this.props.coupon !== undefined || disabled}
                >
                  {this.getEntranceOptions()}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="name"
                  className={classes.textField}
                  value={name}
                  onChange={this.handleChange('name')}
                  label={'Coupon Name'}
                  margin="normal"
                  variant="outlined"
                  required
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="code"
                  className={classes.textField}
                  value={code}
                  onChange={this.handleChange('code')}
                  label={'Coupon Code'}
                  margin="normal"
                  variant="outlined"
                  required
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.inputLabelRefCouponType = ref;
                  }}
                  htmlFor="couponType-placeholder"
                >
                  Coupon Type
                </InputLabel>
                <Select
                  value={couponType}
                  onChange={this.handleChange('couponType')}
                  input={<OutlinedInput labelWidth={labelWidthCouponType} name="couponType" id="couponType-placeholder" />}
                  disabled={disabled}
                >
                  <MenuItem value={coupontypes.PERCENT_OFF} key={coupontypes.PERCENT_OFF}>
                    {'Percent Off'}
                  </MenuItem>
                  <MenuItem value={coupontypes.AMOUNT_OFF} key={coupontypes.AMOUNT_OFF}>
                    {'Amount Off'}
                  </MenuItem>
                  <MenuItem value={coupontypes.NEW_PRICE} key={coupontypes.NEW_PRICE}>
                    {'New Price'}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {this.state.couponType === coupontypes.PERCENT_OFF && (
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <FormControl className={classes.formControl} style={{ marginTop: '-1.1rem' }}>
                  <TextField
                    id="percentOff"
                    className={classes.textField}
                    value={percentOff}
                    onChange={this.handleChange('percentOff')}
                    label={'Percent Off'}
                    margin="normal"
                    variant="outlined"
                    required
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            )}
            {this.state.couponType === coupontypes.AMOUNT_OFF && (
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <FormControl className={classes.formControl} style={{ marginTop: '-1.1rem' }}>
                  <TextField
                    id="amountOff"
                    className={classes.textField}
                    value={amountOff}
                    onChange={this.handleChange('amountOff')}
                    label={'Amount Off'}
                    margin="normal"
                    variant="outlined"
                    required
                    InputProps={{
                      inputComponent: PriceTextField
                    }}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            )}
            {this.state.couponType === coupontypes.NEW_PRICE && (
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <FormControl className={classes.formControl} style={{ marginTop: '-1.1rem' }}>
                  <TextField
                    id="newPrice"
                    className={classes.textField}
                    value={newPrice}
                    onChange={this.handleChange('newPrice')}
                    label={'New Price'}
                    margin="normal"
                    variant="outlined"
                    required
                    InputProps={{
                      inputComponent: PriceTextField
                    }}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="maxTotalUses"
                  className={classes.textField}
                  value={maxTotalUses}
                  onChange={this.handleChange('maxTotalUses')}
                  label={'Max Total Uses'}
                  margin="normal"
                  variant="outlined"
                  type="number"
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="percentOff"
                  className={classes.textField}
                  value={maxIndividualUses}
                  onChange={this.handleChange('maxIndividualUses')}
                  label={'Max Per User'}
                  margin="normal"
                  variant="outlined"
                  type="number"
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="startDate"
                  className={classes.textField}
                  value={startDate}
                  onChange={this.handleChange('startDate')}
                  label={'Start Date'}
                  margin="normal"
                  variant="outlined"
                  type="date"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="endDate"
                  className={classes.textField}
                  value={endDate}
                  onChange={this.handleChange('endDate')}
                  label={'End Date'}
                  margin="normal"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.inputLabelRefPricingGroups = ref;
                  }}
                  htmlFor="pricingGroups-placeholder"
                >
                  PricingGroups
                </InputLabel>
                <Select
                  multiple
                  value={pricingGroups}
                  onChange={this.handleChange('pricingGroups')}
                  input={<OutlinedInput labelWidth={labelWidthPricingGroups} name="pricingGroups" id="pricingGroups-placeholder" />}
                  renderValue={selected => selected.map(id => find(this.props.pricingGroups, { id }).name).join(', ')}
                  MenuProps={MenuProps}
                  disabled={this.state.entranceId === '' || disabled}
                >
                  {filteredPricingGroups.map(pricingGroup => (
                    <MenuItem key={pricingGroup.id} value={pricingGroup.id}>
                      <Checkbox checked={this.state.pricingGroups.indexOf(pricingGroup.id) > -1} />
                      <ListItemText primary={pricingGroup.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.inputLabelRefAccounts = ref;
                  }}
                  htmlFor="accounts-placeholder"
                >
                  Accounts
                </InputLabel>
                <Select
                  multiple
                  value={accounts}
                  onChange={this.handleChange('accounts')}
                  input={<OutlinedInput labelWidth={labelWidthAccounts} name="accounts" id="accounts-placeholder" />}
                  renderValue={selected => selected.map(id => find(this.props.accounts, { id }).email).join(', ')}
                  MenuProps={MenuProps}
                  disabled={this.state.entranceId === '' || disabled}
                >
                  {filteredAccounts.map(account => (
                    <MenuItem key={account.id} value={account.id}>
                      <Checkbox checked={this.state.accounts.indexOf(account.id) > -1} />
                      <ListItemText primary={account.email} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} justify={'flex-end'}>
              {this.props.coupon && can(entrancePermissions.deleteCoupon, this.props.coupon.entranceId) && (
                <Button variant={'outlined'} onClick={this.handleDeleteCoupon}>
                  {'Delete'}
                </Button>
              )}
              {!disabled && (
                <Fragment>
                  {this.props.coupon && (
                    <Button
                      variant={'outlined'}
                      onClick={this.handleToggleCouponEnable}
                      style={{ marginLeft: 'auto', marginRight: '0.5rem' }}
                    >
                      {this.state.status === 'enabled' ? 'Disable' : 'Enable'}
                    </Button>
                  )}
                  <Button
                    disabled={this.formHasErrors()}
                    variant={'contained'}
                    color={'primary'}
                    style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
                    onClick={this.handleSaveCoupon}
                  >
                    {'Save'}
                  </Button>
                  <Button variant={'outlined'} onClick={this.props.handleCancel}>
                    {'Cancel'}
                  </Button>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    );
  }
}

CouponForm.propTypes = {
  classes: PropTypes.object.isRequired,
  coupon: PropTypes.object,
  entrances: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  pricingGroups: PropTypes.array.isRequired,
  handleSaveCoupon: PropTypes.func.isRequired,
  handleToggleCouponEnable: PropTypes.func.isRequired,
  handleDeleteCoupon: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  disabled: PropTypes.bool.isRequired
};

export default withStyles(styles)(CouponForm);
