import { SAVE_ENTRANCE_SUCCESS, saveEntrance } from './entrances.actions';
import { Typography } from '@material-ui/core';
import { can } from '../login/can';
import { connect } from 'react-redux';
import { entrancePermissions } from '../../types/permissionTypes';
import { getEntrances, showToast } from '../layout/layout.actions';
import { validEmail } from '../../utilities/fieldValidation';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  formControl: {
    width: '100%',
  },
  gridItem: {
    marginBottom: '1rem',
  },
});

class EntranceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      directAddressFrom: props.entrance.directAddressFrom ? props.entrance.directAddressFrom : '',
      directAddressTo: props.entrance.directAddressTo ? props.entrance.directAddressTo : '',
    };
  }

  handleChange = (name) => (event) => {
    let updateObj = { [name]: event.target.value };
    this.setState(updateObj);
  };

  handleCancel = () => {
    this.props.history.push('/entrances');
  }

  handleSave = async () => {
    const updateEntrance = { ...this.props.entrance };
    const entranceId = updateEntrance.id;

    // set updated fields
    updateEntrance.directAddressFrom = this.state.directAddressFrom;
    updateEntrance.directAddressTo = this.state.directAddressTo;

    // remove disallowed fields
    const deleteFields = ['id', 'doseSpot', 'stripe', 'created', 'updated'];
    deleteFields.forEach((f) => delete updateEntrance[f]);

    const result = await this.props.saveEntrance(entranceId, updateEntrance);
    if (result.type === SAVE_ENTRANCE_SUCCESS) {
      await this.props.getEntrances();
      this.props.history.push('/entrances');
    } else {
      this.props.showToast('Could not update entrance settings. Please check your connection and try again.');
    }
  }

  formHasErrors = () => {
    const emailFields = ['directAddressFrom', 'directAddressTo'];
    return emailFields.some((f) => validEmail(this.state[f]) !== false);
  }

  render() {
    let { classes, entrance, theme } = this.props;
    const { directAddressFrom, directAddressTo } = this.state;

    const hasUpdatePermission = can(entrancePermissions.updateEntranceSettings);

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={9}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ color: theme.palette.primary.darkgray }}>{entrance.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12} className={classes.gridItem}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="fromAddress"
                  className={classes.textField}
                  value={directAddressFrom}
                  onChange={this.handleChange('directAddressFrom')}
                  label={'Export From Address'}
                  margin="normal"
                  variant="outlined"
                  helperText={validEmail(directAddressFrom) ? validEmail(directAddressFrom) : ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="toAddress"
                  className={classes.textField}
                  value={directAddressTo}
                  onChange={this.handleChange('directAddressTo')}
                  label={'Export To Address'}
                  margin="normal"
                  variant="outlined"
                  helperText={validEmail(directAddressTo) ? validEmail(directAddressTo) : ''}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item xs={12}
            justify={'flex-end'}
          >
            {hasUpdatePermission &&
              <Button
                variant={'contained'}
                color={'primary'}
                style={{ marginRight: '0.5rem' }}
                onClick={this.handleSave}
                disabled={this.formHasErrors()}
              >
                {'Save'}
              </Button>
            }

            <Button variant={'outlined'} onClick={this.handleCancel}>
              {'Cancel'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

EntranceForm.propTypes = {
  classes: PropTypes.object.isRequired,
  entrance: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  saveEntrance: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  getEntrances: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.layout.isEntrancesLoading,
  };
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        saveEntrance,
        showToast,
        getEntrances,
      }
    )(EntranceForm)
  )
);

