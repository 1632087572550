import { Button } from '@material-ui/core';
import { can } from '../login/can';
import { connect } from 'react-redux';
import { entrancePermissions } from '../../types/permissionTypes';
import { logEvent } from '../../utilities/googleAnalytics';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import DataTable from '../../common/dataTable/dataTable.component';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classnames from 'classnames';

const styles = theme => ({
  tableRowRoot: {
    cursor: 'pointer !important',
  },
  rowHighlight: {
    backgroundColor: theme.palette.primary.lightgray,
  },
});

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true, filtering: true },
  { id: 'entry', numeric: false, disablePadding: false, label: 'Entry', sortable: true, filtering: true },
  { id: 'numMembers', numeric: false, disablePadding: false, label: 'Number of Members', sortable: true, filtering: true },
];

class FacilitiesDataTable extends Component {
  handleAddClick = () => {
    this.props.history.push('/facilities/create');
  };

  handleEditClick = async (event, row) => {
    logEvent('view_facility', {
      adminId: this.props.user.email,
      facilityId: row.id,
    });
    this.props.history.push(`/facilities/${row.id}`);
  };

  // get custom row render component
  renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow
          tabIndex={-1}
          classes={{
            root:
              i % 2 === 0
                ? classnames([this.props.classes.tableRowRoot, this.props.classes.rowHighlight])
                : this.props.classes.tableRowRoot,
          }}
        >
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">{row.entranceName}</TableCell>
          <TableCell align="left">{row.numMembers}</TableCell>
          <TableCell align="right">
            <Button onClick={event => this.handleEditClick(event, row)} color="primary" variant="outlined">
              {'VIEW'}
            </Button>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <DataTable
        data={can(entrancePermissions.readFacility) ? data : []}
        title={''}
        columns={columns}
        renderRow={this.renderRow}
        disableSearch
        defaultSortOrder={'asc'}
        onAddClick={can(entrancePermissions.createFacility) ? this.handleAddClick : null}
      />
    );
  }
}

FacilitiesDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.login.user,
  };
};

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, {})(FacilitiesDataTable)));
