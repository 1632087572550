import { CALL_API } from '../../middleware/api';

export const GET_NOTE_TEMPLATES_REQUEST = 'GET_NOTE_TEMPLATES_REQUEST';
export const GET_NOTE_TEMPLATES_SUCCESS = 'GET_NOTE_TEMPLATES_SUCCESS';
export const GET_NOTE_TEMPLATES_FAILURE = 'GET_NOTE_TEMPLATES_FAILURE';

export const CREATE_NOTE_TEMPLATE_REQUEST = 'CREATE_NOTE_TEMPLATE_REQUEST';
export const CREATE_NOTE_TEMPLATE_SUCCESS = 'CREATE_NOTE_TEMPLATE_SUCCESS';
export const CREATE_NOTE_TEMPLATE_FAILURE = 'CREATE_NOTE_TEMPLATE_FAILURE';

export const SAVE_NOTE_TEMPLATE_REQUEST = 'SAVE_NOTE_TEMPLATE_REQUEST';
export const SAVE_NOTE_TEMPLATE_SUCCESS = 'SAVE_NOTE_TEMPLATE_SUCCESS';
export const SAVE_NOTE_TEMPLATE_FAILURE = 'SAVE_NOTE_TEMPLATE_FAILURE';

export const DELETE_NOTE_TEMPLATE_REQUEST = 'DELETE_NOTE_TEMPLATE_REQUEST';
export const DELETE_NOTE_TEMPLATE_SUCCESS = 'DELETE_NOTE_TEMPLATE_SUCCESS';
export const DELETE_NOTE_TEMPLATE_FAILURE = 'DELETE_NOTE_TEMPLATE_FAILURE';

export const getNoteTemplates = () => {
  return {
    [CALL_API]: {
      types: [GET_NOTE_TEMPLATES_REQUEST, GET_NOTE_TEMPLATES_SUCCESS, GET_NOTE_TEMPLATES_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/notetemplates`,
      method: 'GET'
    }
  };
};

export const createNoteTemplate = noteTemplate => {
  return {
    [CALL_API]: {
      types: [CREATE_NOTE_TEMPLATE_REQUEST, CREATE_NOTE_TEMPLATE_SUCCESS, CREATE_NOTE_TEMPLATE_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/notetemplates`,
      method: 'POST',
      payload: noteTemplate
    }
  };
};

export const saveNoteTemplate = (id, noteTemplate) => {
  return {
    [CALL_API]: {
      types: [SAVE_NOTE_TEMPLATE_REQUEST, SAVE_NOTE_TEMPLATE_SUCCESS, SAVE_NOTE_TEMPLATE_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/notetemplates/${id}`,
      method: 'PUT',
      payload: noteTemplate
    }
  };
};

export const deleteNoteTemplate = noteTemplateId => {
  return {
    [CALL_API]: {
      types: [DELETE_NOTE_TEMPLATE_REQUEST, DELETE_NOTE_TEMPLATE_SUCCESS, DELETE_NOTE_TEMPLATE_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/notetemplates/${noteTemplateId}`,
      method: 'DELETE',
      actionMetadata: noteTemplateId
    }
  };
};
