import { PriceTextField } from '../../common/priceTextField/priceTextField.component';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

class PricingBaseForm extends Component {
  constructor(props) {
    super(props);

    const pricingBase = props.pricingBase;

    this.state = {
      name: pricingBase ? pricingBase.name : '',
      price: pricingBase ? pricingBase.price / 100 : 0,
    };
  }

  handleChange = name => event => {
    let updateObj = { [name]: event.target.value };
    this.setState(updateObj);
  };

  formHasErrors = () => {
    return this.state.providerGroupId === '' || this.state.name === '' || this.state.price === '';
  };

  handleSavePricingBase = () => {
    const { pricingBase } = this.props;

    const updatedPricingBase = pricingBase ? { ...pricingBase } : { name: this.state.name };
    updatedPricingBase.price = Math.round(this.state.price * 100, 2);

    if (pricingBase.id) {
      delete updatedPricingBase.id;
      delete updatedPricingBase.doseSpot;
      delete updatedPricingBase.stripe;
      delete updatedPricingBase.created;
      delete updatedPricingBase.updated;
    }

    this.props.handleSavePricingBase(pricingBase ? pricingBase.id : null, updatedPricingBase);
  };

  render() {
    const { classes, disabled } = this.props;
    const { name, price } = this.state;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl required className={classes.formControl}>
            <TextField
              id="name"
              className={classes.textField}
              value={name}
              label={'Entrance'}
              margin="normal"
              variant="outlined"
              disabled={true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <TextField
              id="price"
              className={classes.textField}
              value={price}
              onChange={this.handleChange('price')}
              label={'Price'}
              margin="normal"
              variant="outlined"
              required
              InputProps={{
                inputComponent: PriceTextField,
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        {!disabled && (
          <Grid container item xs={12} justify={'flex-end'}>
            <Button
              disabled={this.formHasErrors()}
              variant={'contained'}
              color={'primary'}
              style={{ marginLeft: 'auto', marginRight: '0.5rem' }}
              onClick={this.handleSavePricingBase}
            >
              {'Save'}
            </Button>
            <Button variant={'outlined'} onClick={this.props.handleCancel}>
              {'Cancel'}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }
}

const styles = theme => ({
  formControl: {
    width: '100%',
  },
  gridItem: {
    marginBottom: '1rem',
  },
});

PricingBaseForm.propTypes = {
  classes: PropTypes.object.isRequired,

  disabled: PropTypes.bool,
  pricingBase: PropTypes.object,

  handleCancel: PropTypes.func,
  handleSavePricingBase: PropTypes.func.isRequired,
};

export default withStyles(styles)(PricingBaseForm);
