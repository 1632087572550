import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { getCoupons, createCoupon, saveCoupon, CREATE_COUPON_SUCCESS, SAVE_COUPON_SUCCESS } from './coupons.actions';
import { getPricingGroups } from '../pricing/pricingGroups.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import CouponFormComponent from './couponForm.component';
import { find } from 'lodash';
import { showToast } from '../layout/layout.actions';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = theme => ({
  container: {
    padding: '3rem',
    height: 'calc(100% - 2rem)'
  }
});

class CouponContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleSaveCoupon = this.handleSaveCoupon.bind(this);
    this.handleToggleCouponEnable = this.handleToggleCouponEnable.bind(this);
    this.handleDeleteCoupon = this.handleDeleteCoupon.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async handleSaveCoupon(id, coupon) {
    if (id) {
      logEvent('save_coupon', {
        adminId: this.props.user.email,
        couponId: id
      });
      let response = await this.props.saveCoupon(id, coupon);
      if (response.type === SAVE_COUPON_SUCCESS) {
        this.props.history.goBack();
      } else {
        this.props.showToast('Could not save the Coupon. Please check your connection and try again.');
      }
    } else {
      logEvent('add_coupon', {
        adminId: this.props.user.email,
        couponId: id
      });
      let response = await this.props.createCoupon(coupon);
      if (response.type === CREATE_COUPON_SUCCESS) {
        this.props.history.goBack();
      } else {
        this.props.showToast('Could not create the Coupon. Please check your connection and try again.');
      }
    }
  }

  async handleToggleCouponEnable(id, coupon) {
    let response = await this.props.saveCoupon(id, coupon);
    if (response.type !== SAVE_COUPON_SUCCESS) {
      this.props.showToast('Could not save the Coupon. Please check your connection and try again.');
    }
    return response;
  }

  async handleDeleteCoupon(id, coupon) {
    let response = await this.props.saveCoupon(id, coupon);
    if (response.type === SAVE_COUPON_SUCCESS) {
      this.props.history.goBack();
    } else {
      this.props.showToast('Could not delete the Coupon. Please check your connection and try again.');
    }
    return response;
  }

  handleCancel() {
    this.props.history.goBack();
  }

  componentWillMount() {
    this.props.setPageTitle('Coupon');
    this.props.getCoupons();
    this.props.getPricingGroups();
  }

  render() {
    const { classes, isLoading, isSaving, coupons, entrances, accounts, pricingGroups } = this.props;
    const { couponId } = this.props.match.params;

    if (isLoading === true) {
      return <LoadingOverlay />;
    }

    const coupon = find(coupons, { id: couponId });
    const disabled =
      (coupon ? !can(entrancePermissions.updateCoupon, coupon.entranceId) : !can(entrancePermissions.createCoupon, null)) ||
      (coupon && coupon.status === 'deleted');

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        {isSaving && <LoadingOverlay />}
        <CouponFormComponent
          coupon={coupon}
          entrances={entrances}
          accounts={accounts}
          pricingGroups={pricingGroups}
          handleSaveCoupon={this.handleSaveCoupon}
          handleToggleCouponEnable={this.handleToggleCouponEnable}
          handleDeleteCoupon={this.handleDeleteCoupon}
          handleCancel={this.handleCancel}
          disabled={disabled}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    coupons: state.coupons.coupons,
    pricingGroups: state.pricingGroups.pricingGroups,
    isLoading: state.coupons.isLoading || state.pricingGroups.isLoading,
    isSaving: state.coupons.isSaving,
    entrances: state.layout.entrances,
    accounts: state.layout.accounts,
    user: state.login.user
  };
};

CouponContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        getCoupons,
        createCoupon,
        saveCoupon,
        showToast,
        getPricingGroups
      }
    )(CouponContainer)
  )
);
