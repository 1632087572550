import React from 'react';
import Keymap from '@convertkit/slate-keymap';

// WrapperType: must be capitol to allow run time type assignment
// https://reactjs.org/docs/jsx-in-depth.html#props-in-jsx
export default function markStylePlugin({ markType, WrapperType, hotKey, command, activeQuery }) {
  function renderMark(props, editor, next) {
    const { children, mark, attributes } = props;

    if (mark.type === markType) {
      return <WrapperType {...attributes}>{children}</WrapperType>;
    } else {
      return next();
    }
  }

  function toggle(editor) {
    editor.toggleMark(markType);
  }

  function isActive(editor) {
    return editor.value.activeMarks.some(mark => mark.type === markType);
  }

  const keyMapPlugin = hotKey ? [Keymap({ [hotKey]: command })] : [];

  return [{ renderMark, commands: { [command]: toggle }, queries: { [activeQuery]: isActive } }, ...keyMapPlugin];
}
