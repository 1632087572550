import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Button,
  TablePagination,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';

const PharmacySearchResults = ({ classes, pharmacies, onSelectPharmacy, isLoading }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <div style={{ flex: 1 }}>
      <Table className={classes.table}>
        <TableBody>
          {pharmacies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
            <TableRow key={row.PharmacyId} classes={{ root: classes.tableRowRoot }}>
              <TableCell component="td" scope="row">
                <div className={classes.rowContentContainer}>
                  <div style={{ flex: 1 }}>
                    <Typography className={classes.storeName}>{row.StoreName}</Typography>
                    <Typography className={classes.storeDetails}>{row.Address1}</Typography>
                    {!isEmpty(row.Address2) && (
                      <Typography className={classes.storeDetails}>{row.Address2}</Typography>
                    )}
                    <Typography className={classes.storeDetails}>
                      {`${row.City}, ${row.State}  ${row.ZipCode}`}
                    </Typography>
                    <NumberFormat
                      className={classes.storeDetails}
                      displayType="text"
                      format="(###) ###-####"
                      value={row.PrimaryPhone}
                    />
                  </div>

                  <div>
                    <Button
                      onClick={!isLoading ? () => onSelectPharmacy(row) : null}
                      variant="outlined"
                      className={classes.button}
                      disableRipple
                    >
                      Select
                    </Button>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={pharmacies.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

const styles = theme => ({
  container: {},
  tableRowRoot: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.primary.lightgray,
    },
  },
  rowContentContainer: {
    display: 'flex',
    paddingBottom: '1rem',
    paddingTop: '1rem',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
    },
  },
  storeName: {
    color: theme.palette.primary.darkgray,
    fontSize: '1.2rem',
    marginBottom: '0.5rem',
  },
  storeDetails: {
    color: theme.palette.primary.darkgray,
    fontSize: '1rem',
  },
});

PharmacySearchResults.propTypes = {
  classes: PropTypes.object.isRequired,

  isLoading: PropTypes.bool.isRequired,
  pharmacies: PropTypes.array.isRequired,

  onSelectPharmacy: PropTypes.func.isRequired,
};

export default withStyles(styles)(PharmacySearchResults);
