import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Paper, Typography, Button } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { has } from 'lodash';

import { savePatient, SAVE_PATIENT_SUCCESS, clearPharmacies } from './patients.actions';
import Colors from '../../styles/colors';
import PatientPersonalInfoForm from './patientPersonalInfoForm.component';
import PatientInsuranceInfoForm from './patientInsuranceInfoForm.component';
import { showToast } from '../layout/layout.actions';
import { formatInsuranceAttachments } from '../../utilities/insuranceUtils';
import createPatientPayload from '../../utilities/patientUtils';
import PatientPharmacyInfoForm from './patientPharmacyInfoForm.component';
import PharmacySelectionModal from './pharmacy/pharmacySelectionModal.component';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';

/* eslint-disable no-shadow */
const PatientEdit = ({
  classes,
  entranceId,
  isLoading,
  selectedPatient,
  clearPharmacies,
  onClose,
  savePatient,
  showToast,
}) => {
  const [patient, setPatient] = useState({
    ...selectedPatient,
    groupCode: has(selectedPatient.pricingData, 'groupCode')
      ? selectedPatient.pricingData.groupCode
      : '',
    groupIdentifier: has(selectedPatient.pricingData, 'groupIdentifier')
      ? selectedPatient.pricingData.groupIdentifier
      : '',
    attachments: formatInsuranceAttachments(selectedPatient),
  });
  const [isPharmSelectOpen, setIsPharmSelectOpen] = useState(false);

  useEffect(() => {
    setPatient({
      ...selectedPatient,
      groupCode: has(selectedPatient.pricingData, 'groupCode')
        ? selectedPatient.pricingData.groupCode
        : '',
      groupIdentifier: has(selectedPatient.pricingData, 'groupIdentifier')
        ? selectedPatient.pricingData.groupIdentifier
        : '',
      attachments: formatInsuranceAttachments(selectedPatient),
    });
  }, [selectedPatient]);

  const handleInputChange = useCallback(
    event => {
      const { name, value } = event.target;

      setPatient((prevState) => {
        const nestedName = name.split('.');
        let props = {
          ...prevState,
        };

        if (nestedName.length > 1) {
          const obj = nestedName[0];
          const nestedProp = nestedName[1];

          props[obj] = {
            ...props[obj],
            [nestedProp]: value,
          }
        } else {
          props[name] = value;
        }

        return props;
      });
    },
    [patient]
  );

  const handleSavePatient = useCallback(async () => {
    const payload = createPatientPayload(patient);
    const response = await savePatient(patient.id, payload);

    if (response.type === SAVE_PATIENT_SUCCESS) {
      onClose();
    } else {
      showToast(
        'Could not save the patient information. Please check your connection and try again.'
      );
    }
  }, [patient, onClose, savePatient, showToast]);

  const handleRemoveInuranceAttachment = useCallback(
    index => {
      const attachments = [].concat(patient.attachments);
      attachments.splice(index, 1);

      setPatient({ ...patient, attachments });
    },
    [patient]
  );

  const handleSelectPharmacy = useCallback(
    pharmacy => {
      setPatient({ ...patient, pharmacy, pharmacyDoseSpotId: pharmacy.PharmacyId });
      setIsPharmSelectOpen(false);

      clearPharmacies();
    },
    [clearPharmacies, patient]
  );

  const handleToggleEditPharmacy = useCallback(() => {
    if (isPharmSelectOpen) {
      clearPharmacies();
    }

    setIsPharmSelectOpen(!isPharmSelectOpen);
  }, [clearPharmacies, isPharmSelectOpen]);

  const hasReadPharmacyPermission = can(entrancePermissions.readPatientPharmacy, entranceId);
  const hasEditPharmacyPermission = can(entrancePermissions.updatePatientPharmacy, entranceId);

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.heading}>
              Personal Information
            </Typography>
            <PatientPersonalInfoForm
              entranceId={entranceId}
              isLoading={isLoading}
              patient={patient}
              onChange={handleInputChange}
            />
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.heading}>
              Insurance Information
            </Typography>
            <PatientInsuranceInfoForm
              entranceId={entranceId}
              isLoading={isLoading}
              patient={patient}
              onChange={handleInputChange}
              onRemovePhoto={handleRemoveInuranceAttachment}
            />
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <div className={classes.pharmacyHeading}>
              <Typography variant="h6" className={classes.heading}>
                Pharmacy Information
              </Typography>
              {hasEditPharmacyPermission && hasReadPharmacyPermission && (
                <Button color="primary" variant="contained" onClick={handleToggleEditPharmacy}>
                  Edit
                </Button>
              )}
            </div>
            <PatientPharmacyInfoForm entranceId={entranceId} pharmacy={patient.pharmacy || {}} />
          </Paper>
        </Grid>
        <Grid container spacing={16} item xs={12} justify="flex-end">
          <Grid item>
            <Button
              disabled={isLoading}
              color="primary"
              variant="contained"
              onClick={handleSavePatient}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <PharmacySelectionModal
        open={isPharmSelectOpen}
        onClose={handleToggleEditPharmacy}
        onSelectPharmacy={handleSelectPharmacy}
      />
    </>
  );
};

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  heading: {
    color: Colors.primary.darkgray,
  },
  pharmacyHeading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

PatientEdit.propTypes = {
  classes: PropTypes.object.isRequired,

  entranceId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedPatient: PropTypes.object,

  clearPharmacies: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  savePatient: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

PatientEdit.defaultProps = {
  selectedPatient: null,
};

const mapStateToProps = state => {
  return {
    isLoading: state.patients.isLoading,
    selectedPharmacy: state.patients.selectedPharmacy,
  };
};

export default compose(
  connect(mapStateToProps, {
    clearPharmacies,
    savePatient,
    showToast,
  }),
  withStyles(styles)
)(PatientEdit);
