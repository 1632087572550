import React, { Component } from 'react';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';

class Callback extends Component {
  render() {
    return <LoadingOverlay />;
  }
}

export default Callback;
