import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { isEmpty, find, isNil } from 'lodash';
import { compose } from 'recompose';
import ReactRouterPropTypes from 'react-router-prop-types';

import { setPageTitle } from '../layout/layout.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import Colors from '../../styles/colors';
import PatientSearchForm from './patientSearchForm.component';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';
import PatientEdit from './patientEdit.component';

/* eslint-disable no-shadow */
const PatientsContainer = ({ classes, match, entrances, isLoading, setPageTitle }) => {
  const [selectedPatient, setSelectedPatient] = useState(null);

  useEffect(() => {
    const entrance = find(entrances, { id: match.params.entranceId });

    if (!isEmpty(entrance)) {
      setPageTitle(`Patients - ${entrance.name}`);
    } else {
      setPageTitle('Patients');
    }
  }, [entrances, match, setPageTitle]);

  const handleEditPatient = patient => setSelectedPatient(patient);

  const handleClosePatient = () => setSelectedPatient(null);

  const { entranceId } = match.params;
  const hasPermission =
    can(entrancePermissions.updatePatientDemographics, entranceId) ||
    can(entrancePermissions.updatePatientInsurance, entranceId) ||
    can(entrancePermissions.updatePatientPharmacy, entranceId) ||
    can(entrancePermissions.readPatientDemographics, entranceId) ||
    can(entrancePermissions.readPatientInsurance, entranceId) ||
    can(entrancePermissions.readPatientPharmacy, entranceId);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <div className={classNames([classes.container, 'page-padding-bottom'])}>
      {!hasPermission && (
        <Typography variant="body1">
          You do not have permission to search for patients in this entrance.
        </Typography>
      )}
      {hasPermission && isNil(selectedPatient) && (
        <>
          <Typography variant="h6" className={classes.heading}>
            Search for Patients
          </Typography>
          <PatientSearchForm entranceId={entranceId} onEditPatient={handleEditPatient} />
        </>
      )}
      {hasPermission && !isNil(selectedPatient) && (
        <>
          <Typography variant="h6" className={classes.heading}>
            Edit Patient
          </Typography>
          <PatientEdit
            entranceId={entranceId}
            selectedPatient={selectedPatient}
            onClose={handleClosePatient}
          />
        </>
      )}
    </div>
  );
};

const styles = theme => ({
  container: {
    height: 'calc(100% - 5rem)',
    padding: theme.spacing.unit * 2,
  },
  heading: {
    color: Colors.primary.darkgray,
  },
});

const mapStateToProps = state => {
  return {
    entrances: state.layout.entrances,
    isLoading: state.layout.isEntrancesLoading,
  };
};

PatientsContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  isLoading: PropTypes.bool.isRequired,
  entrances: PropTypes.array.isRequired,

  setPageTitle: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
  })
)(PatientsContainer);
