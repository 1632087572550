import { Button, TableCell, TableRow } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';

import DataTable from '../../common/dataTable/dataTable.component';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
    sortable: false,
    filtering: false,
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
    sortable: false,
    filtering: false,
  },
  {
    id: 'dob',
    numeric: false,
    disablePadding: false,
    label: 'Date of Birth',
    sortable: false,
    filtering: false,
  },
];

const PatientSearchResults = ({ classes, data, entranceId, onEditAction }) => {
  const hasEditPermissions =
    can(entrancePermissions.updatePatientDemographics, entranceId) ||
    can(entrancePermissions.updatePatientInsurance, entranceId) ||
    can(entrancePermissions.updatePatientPharmacy, entranceId);

  const hasViewPermissiomns =
    can(entrancePermissions.readPatientDemographics, entranceId) ||
    can(entrancePermissions.readPatientInsurance, entranceId) ||
    can(entrancePermissions.readPatientPharmacy, entranceId);

  const handleEditClick = async (event, row) => {
    onEditAction(row);
  };

  // get custom row render component
  const renderRow = (row, i) => (
    <Fragment key={i}>
      <TableRow
        tabIndex={-1}
        classes={{
          root:
            i % 2 === 0
              ? classNames([classes.tableRowRoot, classes.rowHighlight])
              : classes.tableRowRoot,
        }}
      >
        <TableCell align="left">{row.firstName}</TableCell>
        <TableCell align="left">{row.lastName}</TableCell>
        <TableCell align="left">{row.dob}</TableCell>
        <TableCell align="right">
          {(hasEditPermissions || hasViewPermissiomns) && (
            <Button
              onClick={event => handleEditClick(event, row)}
              color="primary"
              variant="outlined"
            >
              {hasEditPermissions ? 'EDIT' : 'VIEW'}
            </Button>
          )}
        </TableCell>
      </TableRow>
    </Fragment>
  );

  return (
    <DataTable
      data={data}
      columns={columns}
      renderRow={renderRow}
      disableSearch
      defaultSortOrder="asc"
      defaultSortField="email"
      disableFilters
    />
  );
};

const styles = theme => ({
  tableRowRoot: {
    cursor: 'pointer !important',
  },
  rowHighlight: {
    backgroundColor: theme.palette.primary.lightgray,
  },
});

PatientSearchResults.propTypes = {
  classes: PropTypes.object.isRequired,

  data: PropTypes.array.isRequired,
  entranceId: PropTypes.string.isRequired,

  onEditAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.login.user,
  };
};

export default compose(withStyles(styles), connect(mapStateToProps, {}))(PatientSearchResults);
