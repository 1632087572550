import {
  GET_NOTE_TYPES_REQUEST,
  GET_NOTE_TYPES_SUCCESS,
  GET_NOTE_TYPES_FAILURE,
  CREATE_NOTE_TYPE_REQUEST,
  CREATE_NOTE_TYPE_SUCCESS,
  CREATE_NOTE_TYPE_FAILURE,
  SAVE_NOTE_TYPE_REQUEST,
  SAVE_NOTE_TYPE_SUCCESS,
  SAVE_NOTE_TYPE_FAILURE,
  DELETE_NOTE_TYPE_REQUEST,
  DELETE_NOTE_TYPE_SUCCESS,
  DELETE_NOTE_TYPE_FAILURE
} from './noteTypes.actions';
import produce from 'immer';

const initialState = {
  noteTypes: [],
  isLoading: false,
  isSaving: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_NOTE_TYPES_REQUEST:
      draft.isLoading = true;
      break;

    case GET_NOTE_TYPES_SUCCESS:
      draft.isLoading = false;
      draft.noteTypes = action.response.data;
      break;

    case GET_NOTE_TYPES_FAILURE:
      draft.isLoading = false;
      break;

    case CREATE_NOTE_TYPE_REQUEST:
      draft.isSaving = true;
      break;

    case CREATE_NOTE_TYPE_SUCCESS: {
      draft.isSaving = false;
      break;
    }

    case CREATE_NOTE_TYPE_FAILURE:
      draft.isSaving = false;
      break;

    case SAVE_NOTE_TYPE_REQUEST:
      draft.isSaving = true;
      break;

    case SAVE_NOTE_TYPE_SUCCESS: {
      draft.isSaving = false;
      break;
    }

    case SAVE_NOTE_TYPE_FAILURE:
      draft.isSaving = false;
      break;

    case DELETE_NOTE_TYPE_REQUEST:
      draft.isSaving = true;
      break;

    case DELETE_NOTE_TYPE_SUCCESS:
      draft.isSaving = false;
      break;

    case DELETE_NOTE_TYPE_FAILURE:
      draft.isSaving = false;
      break;

    default:
      break;
  }
}, initialState);
