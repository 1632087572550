import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  gridItem: {}
});

class PricingGroupMemberForm extends Component {
  constructor(props) {
    super(props);

    const pricingGroupMember = props.pricingGroupMember;

    this.state = {
      firstName: pricingGroupMember ? pricingGroupMember.firstName : '',
      lastName: pricingGroupMember ? pricingGroupMember.lastName : '',
      dob: pricingGroupMember ? pricingGroupMember.dob : '',
      identifier: pricingGroupMember ? pricingGroupMember.identifier : ''
    };
  }

  handleChange = name => event => {
    let updateObj = { [name]: event.target.value };
    this.setState(updateObj);
  };

  formHasErrors = () => {
    return this.state.firstName === '' || this.state.lastName === '' || this.state.dob === '';
  };

  handleSavePricingGroupMember = () => {
    let pricingGroupMember = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      dob: this.state.dob
    };

    if (this.state.identifier !== '') {
      pricingGroupMember.identifier = this.state.identifier;
    }

    if (this.props.pricingGroupMember) {
      pricingGroupMember.pricingGroupId = this.props.pricingGroupMember.pricingGroupId;
    }

    this.props.handleSavePricingGroupMember(this.props.pricingGroupMember ? this.props.pricingGroupMember.id : null, pricingGroupMember);
  };

  render() {
    let { classes } = this.props;
    const { firstName, lastName, dob, identifier } = this.state;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl required className={classes.formControl}>
            <TextField
              id="firstName"
              className={classes.textField}
              value={firstName}
              onChange={this.handleChange('firstName')}
              label={'First Name'}
              margin="normal"
              variant="outlined"
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <TextField
              id="lastName"
              className={classes.textField}
              value={lastName}
              onChange={this.handleChange('lastName')}
              label={'Last Name'}
              margin="normal"
              variant="outlined"
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl required className={classes.formControl}>
            <TextField
              id="dob"
              className={classes.textField}
              value={dob}
              onChange={this.handleChange('dob')}
              label={'DoB'}
              margin="normal"
              variant="outlined"
              type="date"
              required
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <TextField
              id="identifier"
              className={classes.textField}
              value={identifier}
              onChange={this.handleChange('identifier')}
              label={'ID'}
              margin="normal"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid container item xs={12} justify={'flex-end'}>
          <Button
            disabled={this.formHasErrors()}
            variant={'contained'}
            color={'primary'}
            style={{ marginLeft: 'auto', marginRight: '0.5rem' }}
            onClick={this.handleSavePricingGroupMember}
          >
            {'Save'}
          </Button>
          <Button variant={'outlined'} onClick={this.props.handleCancel}>
            {'Cancel'}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

PricingGroupMemberForm.propTypes = {
  classes: PropTypes.object.isRequired,
  pricingGroupMember: PropTypes.object,
  handleSavePricingGroupMember: PropTypes.func.isRequired,
  handleCancel: PropTypes.func
};

export default withStyles(styles)(PricingGroupMemberForm);
