import auth0 from 'auth0-js';
import { AUTH_CONFIG } from './auth0-variables';
import history from '../history';
import { isNil, isEmpty, has } from 'lodash';

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: AUTH_CONFIG.domain,
      clientID: AUTH_CONFIG.clientId,
      redirectUri: AUTH_CONFIG.callbackUrl,
      responseType: 'token id_token',
      scope: 'email openid',
      audience: AUTH_CONFIG.audience,
    });
  }

  /* login() {
    this.auth0.authorize();
  } */

  login(username, password, onSuccess, onError) {
    this.auth0.client.login(
      {
        username,
        password,
        realm: 'Username-Password-Authentication', // realm provided as param here instead of constructor
      },
      (err, result) => {
        if (!isNil(err)) {
          // error
          onError(err);
        } else if (!isNil(result)) {
          // success
          this.setSession(result);
          onSuccess(result);
        } else {
          onError(err);
        }
      }
    );
  }

  handleAuthentication(props, onSuccess) {
    this.auth0.parseHash({ hash: props.location.hash }, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.authResult = authResult;
        this.setSession(authResult);

        console.log('AUTH RESULT!', authResult);
        // send id/auth token in callback
        onSuccess(authResult.idToken, authResult.accessToken);
      } else if (err) {
        history.replace('/login');
        console.log(`ERROR: `, err);
        // alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');

    this.auth0.logout({ returnTo: AUTH_CONFIG.logoutRedirectUrl });
  }

  getAuthToken() {
    return window.localStorage.getItem('id_token');
  }
}

export default new Auth();
