import {
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAILURE,
  SAVE_TRANSACTION_REQUEST,
  SAVE_TRANSACTION_SUCCESS,
  SAVE_TRANSACTION_FAILURE,
  APPROVE_TRANSACTION_REQUEST,
  APPROVE_TRANSACTION_SUCCESS,
  APPROVE_TRANSACTION_FAILURE,
  DECLINE_TRANSACTION_REQUEST,
  DECLINE_TRANSACTION_SUCCESS,
  DECLINE_TRANSACTION_FAILURE,
  COMPLETE_TRANSACTION_REQUEST,
  COMPLETE_TRANSACTION_SUCCESS,
  COMPLETE_TRANSACTION_FAILURE,
} from './altCharges.actions';
import produce from 'immer';

const initialState = {
  transactions: [],
  isLoading: false,
  isSaving: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_TRANSACTIONS_SUCCESS:
      draft.isLoading = false;
      draft.transactions = action.response.data;
      break;

    case GET_TRANSACTIONS_FAILURE:
      draft.isLoading = false;
      break;

    case SAVE_TRANSACTION_REQUEST:
      draft.isSaving = true;
      break;

    case SAVE_TRANSACTION_SUCCESS: {
      let index = draft.transactions.findIndex(transaction => transaction.id === action.actionMetadata);
      draft.transactions[index].finalPrice = action.payload.finalPrice;
      draft.isSaving = false;
      break;
    }

    case SAVE_TRANSACTION_FAILURE:
      draft.isSaving = false;
      break;

    case APPROVE_TRANSACTION_REQUEST:
      draft.isSaving = true;
      break;

    case APPROVE_TRANSACTION_SUCCESS: {
      let index = draft.transactions.findIndex(transaction => transaction.id === action.actionMetadata);
      draft.transactions[index].status = 'complete';
      draft.isSaving = false;
      break;
    }

    case APPROVE_TRANSACTION_FAILURE:
      draft.isSaving = false;
      break;

    case DECLINE_TRANSACTION_REQUEST:
      draft.isSaving = true;
      break;

    case DECLINE_TRANSACTION_SUCCESS: {
      let index = draft.transactions.findIndex(transaction => transaction.id === action.actionMetadata);
      draft.transactions[index].status = 'complete';
      draft.isSaving = false;
      break;
    }

    case DECLINE_TRANSACTION_FAILURE:
      draft.isSaving = false;
      break;

    case COMPLETE_TRANSACTION_REQUEST:
      draft.isSaving = true;
      break;

    case COMPLETE_TRANSACTION_SUCCESS: {
      let index = draft.transactions.findIndex(transaction => transaction.id === action.actionMetadata);
      draft.transactions[index].status = 'complete';
      draft.isSaving = false;
      break;
    }

    case COMPLETE_TRANSACTION_FAILURE:
      draft.isSaving = false;
      break;

    default:
      break;
  }
}, initialState);
