/* eslint-disable react/sort-comp */
import {
  CREATE_PRICING_GROUP_SUCCESS,
  SAVE_PRICING_GROUP_SUCCESS,
  createPricingGroup,
  getPricingGroups,
  savePricingGroup,
} from './pricingGroups.actions';
import { can } from '../login/can';
import { connect } from 'react-redux';
import { entrancePermissions } from '../../types/permissionTypes';
import { find } from 'lodash';
import { getPricingBases } from './pricingBases.actions';
import { getPricingGroupMembers } from './pricingGroupMembers.actions';
import { logEvent } from '../../utilities/googleAnalytics';
import { setPageTitle } from '../layout/layout.actions';
import { showToast } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import PricingGroupFormComponent from './pricingGroupForm.component';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

const styles = (theme) => ({
  container: {
    padding: '3rem',
    height: 'calc(100% - 2rem)',
  },
});

class PricingGroupContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleSavePricingGroup = this.handleSavePricingGroup.bind(this);
    this.handleTogglePricingGroupEnable = this.handleTogglePricingGroupEnable.bind(this);
    this.handleDeletePricingGroup = this.handleDeletePricingGroup.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async handleSavePricingGroup(id, pricingGroup) {
    if (id) {
      let response = await this.props.savePricingGroup(id, pricingGroup);
      if (response.type === SAVE_PRICING_GROUP_SUCCESS) {
        this.props.history.goBack();
      } else {
        this.props.showToast('Could not save the Pricing Group. Please check your connection and try again.');
      }
    } else {
      logEvent('add_pricinggroup', {
        adminId: this.props.user.email,
      });
      let response = await this.props.createPricingGroup(pricingGroup);
      if (response.type === CREATE_PRICING_GROUP_SUCCESS) {
        this.props.history.goBack();
      } else {
        this.props.showToast('Could not create the Pricing Group. Please check your connection and try again.');
      }
    }
  }

  async handleTogglePricingGroupEnable(id, pricingGroup) {
    let response = await this.props.savePricingGroup(id, pricingGroup);
    if (response.type !== SAVE_PRICING_GROUP_SUCCESS) {
      this.props.showToast('Could not save the Pricing Group. Please check your connection and try again.');
    }
    return response;
  }

  async handleDeletePricingGroup(id, pricingGroup) {
    let response = await this.props.savePricingGroup(id, pricingGroup);
    if (response.type === SAVE_PRICING_GROUP_SUCCESS) {
      this.props.history.goBack();
    } else {
      this.props.showToast('Could not delete the Pricing Group. Please check your connection and try again.');
    }
    return response;
  }

  handleCancel() {
    this.props.history.goBack();
  }

  componentWillMount() {
    this.props.setPageTitle('Pricing Group');
    this.props.getPricingGroups();
    this.props.getPricingGroupMembers();
  }

  render() {
    const { classes, isLoading, isSaving, pricingGroups, pricingGroupMembers, entrances } = this.props;
    const { pricingGroupId } = this.props.match.params;

    if (isLoading === true) {
      return <LoadingOverlay />;
    }

    const pricingGroup = find(pricingGroups, { id: pricingGroupId });
    const members = pricingGroupMembers ? pricingGroupMembers.filter((item) => item.pricingGroupId === pricingGroupId) : [];
    const disabled =
      (pricingGroup
        ? !can(entrancePermissions.updatePricingGroup, pricingGroup.entranceId)
        : !can(entrancePermissions.createPricingGroup, null)) || (pricingGroup ? pricingGroup.status === 'deleted' : false);

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        {isSaving && <LoadingOverlay />}
        <PricingGroupFormComponent
          pricingGroup={pricingGroup}
          pricingGroupMembers={members}
          entrances={entrances}
          handleSavePricingGroup={this.handleSavePricingGroup}
          handleTogglePricingGroupEnable={this.handleTogglePricingGroupEnable}
          handleDeletePricingGroup={this.handleDeletePricingGroup}
          handleCancel={this.handleCancel}
          disabled={disabled}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pricingBases: state.pricingBases.pricingBases,
    pricingGroups: state.pricingGroups.pricingGroups,
    pricingGroupMembers: state.pricingGroupMembers.pricingGroupMembers,
    isLoading: state.pricingBases.isLoading || state.pricingGroups.isLoading || state.pricingGroupMembers.isLoading,
    isSaving: state.pricingGroups.isSaving,
    entrances: state.layout.entrances,
    user: state.login.user,
  };
};

PricingGroupContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  savePricingGroup: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired,
  getPricingBases: PropTypes.func.isRequired,
  getPricingGroups: PropTypes.func.isRequired,
  getPricingGroupMembers: PropTypes.func.isRequired,
  createPricingGroup: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        getPricingGroups,
        getPricingGroupMembers,
        createPricingGroup,
        savePricingGroup,
        showToast,
        getPricingBases,
      }
    )(PricingGroupContainer)
  )
);
