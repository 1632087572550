import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Button,
  Typography,
  Paper,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';
import { compose } from 'recompose';

import CustomModal from '../../../common/modal/customModal.component';
import PharmacySearchForm from './pharmacySearchForm.component';
import { pharmacySearch, PHARMACY_SEARCH_SUCCESS, clearPharmacies } from '../patients.actions';
import PharmacySearchResults from './pharmacySearchResults.component';
import { showToast } from '../../layout/layout.actions';

/* eslint-disable no-shadow */
const PharmacySelectionModal = ({
  classes,
  isLoading,
  open,
  pharmacies,
  clearPharmacies,
  onClose,
  onSelectPharmacy,
  pharmacySearch,
}) => {
  const [expandSearchPanel, setExpandSearchPanel] = useState(true);
  const [showPharmacies, setShowPharmacies] = useState(false);

  useEffect(() => {
    setExpandSearchPanel(true);
    setShowPharmacies(false);
  }, [open]);

  const toggleExpandPanel = useCallback(() => {
    if (showPharmacies && !isEmpty(pharmacies)) {
      setExpandSearchPanel(!expandSearchPanel);
    }
  }, [pharmacies, setExpandSearchPanel, showPharmacies, expandSearchPanel]);

  const handlePharmacySearch = useCallback(
    async criteria => {
      clearPharmacies();
      const result = await pharmacySearch(criteria);

      if (result.type === PHARMACY_SEARCH_SUCCESS) {
        setExpandSearchPanel(!(result.response.length > 0));
      } else {
        setExpandSearchPanel(true);
      }

      setShowPharmacies(true);
    },
    [setExpandSearchPanel, setShowPharmacies, pharmacySearch, clearPharmacies]
  );

  return (
    <CustomModal
      open={open}
      handleClose={onClose}
      modalContentProps={{
        style: {
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '95%',
          minWidth: '60%',
          overflow: 'auto',
        },
      }}
    >
      <div style={{ flex: 1 }}>
        <ExpansionPanel expanded={expandSearchPanel} onChange={toggleExpandPanel}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" className={classes.expansionTitle}>
              Edit Patient Pharmacy
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <PharmacySearchForm isLoading={isLoading} onPharmacySearch={handlePharmacySearch} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {showPharmacies && (
          <Paper className={classes.searchResultsPaper}>
            {(isNil(pharmacies) || isLoading) && (
              <CircularProgress className={classes.spinner} size={50} />
            )}
            {isEmpty(pharmacies) && !isLoading && (
              <div>
                <Typography className={classes.emptyText}>
                  No Search Results. Please modify your search criteria and try again.
                </Typography>
              </div>
            )}
            {!isEmpty(pharmacies) && (
              <PharmacySearchResults
                isLoading={isLoading}
                pharmacies={pharmacies}
                onSelectPharmacy={onSelectPharmacy}
              />
            )}
          </Paper>
        )}
      </div>
      <div className={classes.action}>
        <Button variant="outlined" onClick={onClose} disableRipple>
          Cancel
        </Button>
      </div>
    </CustomModal>
  );
};

const styles = theme => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
    },
  },
  buttonDisabled: {
    backgroundColor: theme.palette.primary.lightgray,
    '&:hover': {
      backgroundColor: theme.palette.secondary.lightgray,
    },
  },
  expansionTitle: {
    color: theme.palette.primary.darkgray,
    fontSize: '1rem',
  },
  searchResultsPaper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    overflow: 'auto',
    padding: '1rem',
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
  },
  emptyText: {
    color: theme.palette.primary.darkgray,
    fontSize: '1rem',
  },
  spinner: {
    marginBottom: theme.spacing.unit * 4,
    marginTop: theme.spacing.unit * 4,
  },
});

PharmacySelectionModal.propTypes = {
  classes: PropTypes.object.isRequired,

  isLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  pharmacies: PropTypes.array,

  clearPharmacies: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectPharmacy: PropTypes.func.isRequired,
  pharmacySearch: PropTypes.func.isRequired,
};

PharmacySelectionModal.defaultProps = {
  pharmacies: null,
};

const mapStateToProps = state => {
  return {
    isLoading: state.patients.isLoadingPharmacies,
    pharmacies: state.patients.pharmacies,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    clearPharmacies,
    pharmacySearch,
    showToast,
  })
)(PharmacySelectionModal);
