const BOARD = {
  route: '/',
  name: 'Board',
  icon: 'HOME',
};

const PRICING = {
  route: '/pricing',
  name: 'Pricing',
  icon: 'PRICING',
};

const COUPONS = {
  route: '/coupons',
  name: 'Coupons',
  icon: 'COUPONS',
};

const ALT_CHARGES = {
  route: '/altCharges',
  name: 'Alt Charges',
  icon: 'ALT_CHARGES',
};

const NOTES = {
  route: '/notes',
  name: 'Notes',
  icon: 'NOTES',
};

const DASHBOARDS = {
  route: '/dashboards',
  name: 'Dashboards',
  icon: 'DASHBOARDS',
};

const ENTRANCES = {
  route: '/entrances',
  name: 'Entrances',
  icon: 'ENTRANCES',
};

const MARKETING = {
  route: '/marketingContent',
  name: 'Marketing Content',
  icon: 'MARKETING',
};

const OUTAGE_BANNER = {
  route: '/outageBanner',
  name: 'Outage Banner',
  icon: 'COMMENT',
};

const PATIENTS = {
  route: '/patients',
  name: 'Patients',
  icon: 'PATIENTS'
};

const FACILITIES = {
  route: '/facilities',
  name: 'Facilities',
  icon: 'BUILDING',
};

const LOGOUT = {
  route: '/logout',
  name: 'Logout',
  icon: 'POWER',
};

export default [BOARD, PRICING, COUPONS, ALT_CHARGES, NOTES, DASHBOARDS, LOGOUT, MARKETING, OUTAGE_BANNER, PATIENTS, FACILITIES, ENTRANCES];
