import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const NODE_TYPE = 'variable';

// ***************
// Plugin
// ***************
let hasLoaded = new Map();

export default function variablePlugin({ variables, memorizeOnLoad = false }) {
  const schema = {
    inlines: {
      variable: {
        isVoid: true
      }
    }
  };

  function onChange(editor, next) {
    if (!hasLoaded.has(editor)) {
      hasLoaded.set(editor, false);
      if (memorizeOnLoad) {
        memorizeVariables(editor);
      }
    }
    return next();
  }

  function renderInline(props, editor, next) {
    const { node } = props;
    if (node.type === NODE_TYPE) {
      return (
        <Variable
          variables={variables}
          name={node.data.get('name')}
          readOnly={editor.readOnly}
          memorizedValue={node.data.get('memorizedValue')}
        />
      );
    } else {
      return next();
    }
  }

  function getVariables() {
    return Object.keys(variables).sort();
  }

  function setDataForNode(editor, { key, type, nodes }, newData) {
    editor.setNodeByKey(key, { type, nodes, data: newData });
  }

  function memorizeVariables(editor) {
    const inlineVariables = editor.value.document.filterDescendants(n => n.object === 'inline' && n.type === 'variable').toJS();
    // Don't overright already memorized values
    inlineVariables.forEach(node => setDataForNode(editor, node, { memorizedValue: variables[node.data.name], ...node.data }));
  }

  function forgetMemorizeVariables(editor) {
    const inlineVariables = editor.value.document.filterDescendants(n => n.object === 'inline' && n.type === 'variable').toJS();
    inlineVariables.forEach(node => {
      const { memorizedValue, ...newData } = node.data;
      setDataForNode(editor, node, { ...newData });
    });
  }

  function insertVariable(editor, name, { memorize = false }) {
    const data = memorize ? { name, memorizedValue: variables[name] } : { name };
    editor.insertInline({ type: NODE_TYPE, data });
  }

  return {
    schema,
    renderInline,
    renderNode: renderInline, // COMPAT: renderNode is removed in slate
    onChange,
    queries: { getVariables },
    commands: { insertVariable, memorizeVariables, forgetMemorizeVariables }
  };
}

// ***************
// React Element
// ***************
const styles = theme => ({
  editableVariabelInline: {
    backgroundColor: theme.palette.primary.lightBlue
  },
  readOnlyVariabelInline: {}
});

export const Variable = withStyles(styles)(({ variables, name, memorizedValue, readOnly, classes }) => {
  const displayValue = memorizedValue || variables[name] || `{{${name}}}`;
  const className = readOnly ? 'readOnlyVariabelInline' : 'editableVariabelInline';
  return <span className={classes[className]}>{displayValue}</span>;
});
