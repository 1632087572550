import { Typography } from '@material-ui/core';
import { can } from '../../login/can';
import { entrancePermissions } from '../../../types/permissionTypes';
import { getUSStateOptions } from '../../../utilities/provinceUtils';
import { has, isEmpty, isNil } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Colors from '../../../styles/colors';
import CustomModal from '../../../common/modal/customModal.component';
import FacilitatorsForm from './facilitatorsForm.component.js';
import FacilityDocPasswordResetForm from './facilityDocPasswordResetForm.component.js';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';

const styles = (theme) => ({
  formContainer: {
    paddingBottom: '5rem',
  },
  formControl: {
    width: '100%',
  },
  marginVertical: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  textField: {},
  gridItem: {},
  secondaryButton: {
    color: Colors.primary.main,
  },
});

const ToggleSwitch = (props) => {
  const { label, handleChange, value } = props;
  return (
    <Grid container component={'span'} alignItems={'center'}
      style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
    >
      <Grid item style={{ flex: 1 }}>
        <Typography variant={'h6'} style={{ color: Colors.primary.darkgray }}>
          {label}
        </Typography>
      </Grid>
      <Grid item component={'label'} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <Grid item>
          <Typography variant={'h6'} style={{ color: Colors.primary.darkgray }}>
            No
          </Typography>
        </Grid>
        <Grid item>
          <Switch checked={value} value={value} onChange={handleChange}
            color="primary"
            inputProps={{ 'aria-label': label }}
          />
        </Grid>
        <Grid item>
          <Typography variant={'h6'} style={{ color: Colors.primary.darkgray }}>
            Yes
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

ToggleSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

const PhoneNumberFormat = (props) => {
  const { inputRef, onChange, ...rest } = props;
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      format={'(###) ###-####'}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
};

const ZipNumberFormat = (props) => {
  const { inputRef, onChange, ...rest } = props;
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      format={'#####'}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
};

class FacilityForm extends Component {
  constructor(props) {
    super(props);

    const facility = props.facility;

    this.state = {
      entranceId: facility ? facility.entranceId : '',
      name: facility ? facility.name : '',
      addressLine1: facility && !isNil(facility.addressLine1) ? facility.addressLine1 : '',
      addressLine2: facility && !isNil(facility.addressLine2) ? facility.addressLine2 : '',
      addressCity: facility && !isNil(facility.addressCity) ? facility.addressCity : '',
      addressZip: facility && !isNil(facility.addressZip) ? facility.addressZip : '',
      phoneNumber: facility && !isNil(facility.phoneNumber) ? facility.phoneNumber : '',
      addressState: facility && !isNil(facility.addressState) ? facility.addressState : '',
      skipLegalAgreement: facility ? facility.skipLegalAgreement : false,
      skipAddress: facility ? facility.skipAddress : false,
      skipPhone: facility ? facility.skipPhone : false,
      skipPharmacy: facility ? facility.skipPharmacy : false,
      skipChiefComplaint: facility ? facility.skipChiefComplaint : false,
      skipProviderCharting: facility ? facility.skipProviderCharting : false,
      phiVisible: facility ? facility.phiVisible : false,
      shareVisitDocuments: facility ? facility.shareVisitDocuments : false,
      isShowPasswordReset: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (has(nextProps, 'facility')) {
      const { facility } = nextProps;
      this.setState({
        entranceId: facility.entranceId,
        name: facility.name,
        addressLine1: !isNil(facility.addressLine1) ? facility.addressLine1 : '',
        addressLine2: !isNil(facility.addressLine2) ? facility.addressLine2 : '',
        addressCity: !isNil(facility.addressCity) ? facility.addressCity : '',
        addressZip: !isNil(facility.addressZip) ? facility.addressZip : '',
        phoneNumber: !isNil(facility.phoneNumber) ? facility.phoneNumber : '',
        addressState: !isNil(facility.addressState) ? facility.addressState : '',
        skipLegalAgreement: facility.skipLegalAgreement,
        skipAddress: facility.skipAddress,
        skipPhone: facility.skipPhone,
        skipPharmacy: facility.skipPharmacy,
        skipChiefComplaint: facility.skipChiefComplaint,
        skipProviderCharting: facility.skipProviderCharting,
        phiVisible: facility.phiVisible,
        shareVisitDocuments: facility.shareVisitDocuments,
      });
    }
  }

  handleChange = (name) => (event) => {
    let updateObj = { ...this.state, [name]: event.target.value };
    this.setState(updateObj);
  };

  // handle inverted scenario for certain fields
  handleSwitchChange = (name, inverted = false) => (event) => {
    let value = event.target.value === 'true' ? false : true;
    if (inverted) value = !value;
    let updateObj = { ...this.state, [name]: value };
    this.setState(updateObj);
  };

  formHasErrors = () => {
    const requiredFields = ['entranceId', 'name', 'addressLine1', 'addressCity', 'addressState', 'addressZip', 'phoneNumber'];
    return requiredFields.some((f) => isEmpty(this.state[f]) || this.state[f].trim() === '');
  };

  handleSaveFacility = () => {
    // include original facility detail
    const { facility } = this.props;
    const mappedState = { ...this.state, populationId: facility.populationId, id: facility.id };
    console.log('mapped state', mappedState);

    const optionalFields = ['addressLine2'];
    optionalFields.forEach((k) => {
      if (isEmpty(mappedState[k])) {
        mappedState[k] = null;
      }
    });

    delete mappedState.isShowPasswordReset;

    this.props.handleSave({ ...mappedState });
  };

  handleCreateFacility = () => {
    const mappedState = { ...this.state };

    const optionalFields = ['addressLine2'];
    optionalFields.forEach((k) => {
      if (isEmpty(mappedState[k])) {
        delete mappedState[k];
      }
    });

    delete mappedState.isShowPasswordReset;

    this.props.handleSave(mappedState);
  };

  handleDisableFacility = () => {
    this.props.handleDisable();
  };

  handleCancel = () => {
    this.props.handleCancel();
  };

  handleShowPasswordReset = () => {
    this.setState({
      isShowPasswordReset: true,
    });
  }

  closePasswordReset = () => {
    this.setState({
      isShowPasswordReset: false,
    });
  }

  render() {
    let { classes, entrances, members } = this.props;

    const {
      name,
      entranceId,
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressZip,
      phoneNumber,
      skipLegalAgreement,
      skipAddress,
      skipPhone,
      skipPharmacy,
      skipChiefComplaint,
      skipProviderCharting,
      shareVisitDocuments,
      phiVisible,
      isShowPasswordReset,
    } = this.state;

    const needLegalAgreement = !skipLegalAgreement;
    const needAddress = !skipAddress;
    const needPhone = !skipPhone;
    const needPharmacy = !skipPharmacy;
    const needChiefComplaint = !skipChiefComplaint;
    const needProviderCharting = !skipProviderCharting;

    // check for presence of facility id to determine edit/create
    const isCreate = !has(this.props, ['facility', 'id']);

    return (
      <div className={classes.formContainer}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={16}>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControl disabled={!isCreate} required variant="outlined"
                  className={classnames([classes.marginVertical, classes.formControl])}
                >
                  <InputLabel htmlFor="entranceId">Associated Entry</InputLabel>
                  <Select
                    value={entranceId}
                    onChange={this.handleChange('entranceId')}
                    input={<OutlinedInput labelWidth={120} name="entranceId" id="entranceId" />}
                  >
                    <MenuItem value={''} />
                    {entrances.map((e, i) => {
                      return <MenuItem value={e.id} key={i}>{e.name}</MenuItem>;
                    })
                    }
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="name"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange('name')}
                    label={'Facility Name'}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="address1"
                    className={classes.textField}
                    value={addressLine1}
                    onChange={this.handleChange('addressLine1')}
                    label={'Address Line 1'}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="address2"
                    className={classes.textField}
                    value={addressLine2}
                    onChange={this.handleChange('addressLine2')}
                    label={'Address Line 2'}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="city"
                    className={classes.textField}
                    value={addressCity}
                    onChange={this.handleChange('addressCity')}
                    label={'City'}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <FormControl variant="outlined" className={classes.formControl} required>
                  <InputLabel htmlFor="state">State</InputLabel>
                  <Select
                    value={addressState}
                    onChange={this.handleChange('addressState')}
                    input={<OutlinedInput labelWidth={50} name="state" id="state" />}
                  >
                    {getUSStateOptions().map((o, i) => {
                      return (
                        <MenuItem key={i} value={o.value}>
                          {o.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="zip"
                    className={classes.textField}
                    value={addressZip}
                    onChange={this.handleChange('addressZip')}
                    label={'Zip'}
                    InputProps={{
                      inputComponent: ZipNumberFormat,
                    }}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="phone"
                    label="Facility Phone Number"
                    value={phoneNumber}
                    variant={'outlined'}
                    onChange={this.handleChange('phoneNumber')}
                    InputProps={{
                      inputComponent: PhoneNumberFormat,
                    }}
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ToggleSwitch
              label={'Visits Require Legal Agreements?'}
              handleChange={this.handleSwitchChange('skipLegalAgreement', true)}
              value={needLegalAgreement}
            />
            <ToggleSwitch value={needAddress} label={'Visits Require Patient Address?'} handleChange={this.handleSwitchChange('skipAddress', true)} />
            <ToggleSwitch value={needPhone} label={'Visits Require Patient Phone?'} handleChange={this.handleSwitchChange('skipPhone', true)} />
            <ToggleSwitch value={needPharmacy} label={'Visits Require Pharmacy Info?'} handleChange={this.handleSwitchChange('skipPharmacy', true)} />
            <ToggleSwitch value={needChiefComplaint} label={'Visits Require Chief Complaint?'} handleChange={this.handleSwitchChange('skipChiefComplaint', true)} />
            <ToggleSwitch value={needProviderCharting} label={'Visits Require Provider Charting?'} handleChange={this.handleSwitchChange('skipProviderCharting', true)} />
            <ToggleSwitch
              value={phiVisible}
              label={'Facilitator Can See Patient PHI?'}
              handleChange={this.handleSwitchChange('phiVisible')}
            />
            <ToggleSwitch
              value={shareVisitDocuments}
              label={'Share Documents Using Secret Word?'}
              handleChange={this.handleSwitchChange('shareVisitDocuments')}
            />
            {shareVisitDocuments &&
              <Button
                onClick={this.handleShowPasswordReset}
                variant="outlined"
                size="small"
                color="primary"
                style={{ marginLeft: '1rem' }}
              >Document Password Reset</Button>
            }
          </Grid>
        </Grid>

        {!isCreate &&
          <Grid container>
            <FacilitatorsForm members={members} entranceId={entranceId} />
          </Grid>
        }

        <Grid container item xs={12}
          justify={'flex-end'} style={{ paddingTop: '1rem' }}
        >
          <Button variant={'outlined'} onClick={this.handleCancel} className={classes.secondaryButton}>
            CANCEL
          </Button>

          {!isCreate && can(entrancePermissions.deleteFacility, this.props.facility.entranceId) &&
            <Button
              variant={'outlined'}
              onClick={this.handleDisableFacility}
              className={classes.secondaryButton}
              style={{ marginRight: '0.5rem', marginLeft: '3rem' }}
            >
              DISABLE
            </Button>
          }

          <Button
            disabled={this.formHasErrors()}
            variant={'contained'}
            color={'primary'}
            style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
            onClick={isCreate ? this.handleCreateFacility : this.handleSaveFacility}
          >
            SAVE
          </Button>
        </Grid>

        {/* DOCUMENT SEARCH MODAL */}
        <CustomModal escapeKeyDown open={isShowPasswordReset} handleClose={this.closePasswordReset}>
          <IconButton
            style={{ position: 'absolute', top: 8, right: 8 }}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.closePasswordReset}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{ color: Colors.primary.darkgray }}>
            Document Password Reset
          </Typography>
          <FacilityDocPasswordResetForm handleCancel={this.closePasswordReset} />
        </CustomModal>
      </div>
    );
  }
}

FacilityForm.propTypes = {
  classes: PropTypes.object.isRequired,
  facility: PropTypes.object,
  handleDisable: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  entrances: PropTypes.array.isRequired,
  handleCancel: PropTypes.func.isRequired,
  members: PropTypes.array,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(FacilityForm));
