import { CALL_API } from '../../middleware/api';
import { has } from 'lodash';

export const GET_OUTAGE_BANNER_REQUEST = 'GET_OUTAGE_BANNER_REQUEST';
export const GET_OUTAGE_BANNER_SUCCESS = 'GET_OUTAGE_BANNER_SUCCESS';
export const GET_OUTAGE_BANNER_FAILURE = 'GET_OUTAGE_BANNER_FAILURE';

export const SET_OUTAGE_BANNER_REQUEST = 'SET_OUTAGE_BANNER_REQUEST';
export const SET_OUTAGE_BANNER_SUCCESS = 'SET_OUTAGE_BANNER_SUCCESS';
export const SET_OUTAGE_BANNER_FAILURE = 'SET_OUTAGE_BANNER_FAILURE';

export const SET_OUTAGE_BANNER_LOADING = 'SET_OUTAGE_BANNER_LOADING';

const getOutageBannerSuccess = payload => {
  // if no message text is received, set a null banner object
  if (!has(payload, ['provider', 'text'])) {
    payload.provider = null;
  }

  if (!has(payload, ['patient', 'text'])) {
    payload.patient = null;
  }

  return {
    type: GET_OUTAGE_BANNER_SUCCESS,
    payload
  };
};

const setLoadingOutageBanner = payload => {
  return {
    type: SET_OUTAGE_BANNER_LOADING,
    payload
  };
};

export const getOutageBanner = () => {
  // use action creator function for async call
  return dispatch => {
    try {
      const breakCacheParam = (Math.random() * 1e16).toString(36);
      // indicate loading state
      dispatch(setLoadingOutageBanner(true));
      const url = process.env.REACT_APP_SERVER_OUTAGE_URL;
      return fetch(`${url}?cacheBreaker=${breakCacheParam}`).then(response => {
        if (response.ok && response.status === 200) {
          response.json().then(data => {
            dispatch(getOutageBannerSuccess(data));
          });
        }
      });
    } catch (e) {
      console.log('could not fetch message banner: ', e);
    } finally {
      dispatch(setLoadingOutageBanner(false));
    }
  };
};

export const setOutageBanner = payload => {
  return {
    [CALL_API]: {
      types: [SET_OUTAGE_BANNER_REQUEST, SET_OUTAGE_BANNER_SUCCESS, SET_OUTAGE_BANNER_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/status`,
      method: 'POST',
      payload
    }
  };
};
