import ReactGA from 'react-ga';

export const logEvent = (name, params = '') => {
  try {
    ReactGA.event({
      category: name,
      action: JSON.stringify(params)
    });
  } catch (e) {
    ReactGA.event({
      category: 'log_event_error',
      action: name
    });
  }
};

export default { logEvent };
