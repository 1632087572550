import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { omitBy, isEmpty } from 'lodash';

import { getUSStateOptions } from '../../../utilities/provinceUtils';
import PhoneNumberMask from '../../../common/inputs/phoneNumberMask.component';
import ZipNumberMask from '../../../common/inputs/zipCodeMask.component';

const initialState = {
  name: '',
  city: '',
  state: '',
  zip: '',
  address: '',
  phone: '',
};

const PharmacySearchForm = ({ classes, isLoading, onPharmacySearch }) => {
  const [searchCriteria, setSearchCriteria] = useState(initialState);

  const handleChange = event => {
    const { name, value } = event.target;

    setSearchCriteria({ ...searchCriteria, [name]: value });
  };

  const handlePharmacySearch = event => {
    event.preventDefault();
    const criteria = omitBy({ ...searchCriteria }, isEmpty);

    onPharmacySearch(criteria);
  };

  const handleFormReset = () => {
    setSearchCriteria(initialState);
  };

  // TODO: check for valid pharmacy search criteria
  const formHasErrors = () => {
    return Object.keys(omitBy({ ...searchCriteria }, isEmpty)).length <= 0;
  };

  const isSearchDisabled = formHasErrors() || isLoading;

  return (
    <form>
      <Grid container spacing={16}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            InputProps={{
              autoComplete: 'no', // use an invalid string to thwart browser autofill
            }}
            label="Pharmacy Name"
            name="name"
            value={searchCriteria.name}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            InputProps={{
              autoComplete: 'no', // use an invalid string to thwart browser autofill
            }}
            label="Address"
            name="address"
            value={searchCriteria.address}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            InputProps={{
              autoComplete: 'no', // use an invalid string to thwart browser autofill
            }}
            label="City"
            name="city"
            value={searchCriteria.city}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={16}>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-state-simple">State</InputLabel>
            <Select
              value={searchCriteria.state}
              onChange={handleChange}
              input={<OutlinedInput id="outlined-state-simple" labelWidth={35} name="state" />}
            >
              <MenuItem value="" />
              {getUSStateOptions().map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            InputProps={{
              inputComponent: ZipNumberMask,
              autoComplete: 'no', // use an invalid string to thwart browser autofill
            }}
            label="Zip Code"
            name="zip"
            value={searchCriteria.zip}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            InputProps={{
              inputComponent: PhoneNumberMask,
              autoComplete: 'no', // use an invalid string to thwart browser autofill
            }}
            label="Phone"
            name="phone"
            value={searchCriteria.phone}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <div className={classes.actions}>
        <>
          <Button
            disableRipple
            variant="text"
            style={{ marginRight: '1rem' }}
            onClick={handleFormReset}
          >
            Reset
          </Button>
          <Button
            className={isSearchDisabled ? classes.buttonDisabled : classes.button}
            disabled={isSearchDisabled}
            disableRipple
            type="submit"
            variant="outlined"
            onClick={handlePharmacySearch}
          >
            Search
          </Button>
        </>
      </div>
    </form>
  );
};

const styles = theme => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
    },
  },
  buttonDisabled: {
    backgroundColor: theme.palette.primary.lightgray,
    '&:hover': {
      backgroundColor: theme.palette.secondary.lightgray,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
  },
});

PharmacySearchForm.propTypes = {
  classes: PropTypes.object.isRequired,

  isLoading: PropTypes.bool.isRequired,

  onPharmacySearch: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(PharmacySearchForm);
