import { SAVE_MARKETING_CONTENT_REQUEST, SAVE_MARKETING_CONTENT_FAILURE, SAVE_MARKETING_CONTENT_SUCCESS } from './marketing.actions';
import produce from 'immer';

const initialState = {
  isLoading: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SAVE_MARKETING_CONTENT_SUCCESS:
      draft.isLoading = false;
      break;

    case SAVE_MARKETING_CONTENT_FAILURE:
      draft.isLoading = false;
      break;

    case SAVE_MARKETING_CONTENT_REQUEST:
      draft.isLoading = true;
      break;

    default:
      break;
  }
}, initialState);
