import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import { PriceTextField } from '../../common/priceTextField/priceTextField.component';
import { SAVE_PRICING_GROUP_SUCCESS } from './pricingGroups.actions';
import PricingGroupMembers from './pricingGroupMembers.component';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  gridItem: {
    marginBottom: '1rem'
  }
});

class PricingGroupForm extends Component {
  constructor(props) {
    super(props);

    const pricingGroup = props.pricingGroup;

    this.state = {
      entranceId: pricingGroup ? pricingGroup.entranceId : '',
      name: pricingGroup ? pricingGroup.name : '',
      code: pricingGroup ? pricingGroup.code : '',
      price: pricingGroup ? pricingGroup.price / 100 : 0,
      idName: pricingGroup ? (pricingGroup.idName ? pricingGroup.idName : '') : '',
      status: pricingGroup ? pricingGroup.status : 'enabled',
      labelWidthEntrance: 0
    };
  }

  componentDidMount() {
    this.setState({
      labelWidthEntrance: ReactDOM.findDOMNode(this.inputLabelRefEntrance).offsetWidth
    });
  }

  getEntranceOptions = () => {
    return this.props.entrances
      .filter(entrance => (this.props.pricingGroup ? true : can(entrancePermissions.createPricingGroup, entrance.id)))
      .map((entrance, i) => {
        return (
          <MenuItem value={entrance.id} key={i}>
            {entrance.name}
          </MenuItem>
        );
      });
  };

  handleChange = name => event => {
    let updateObj = { [name]: event.target.value };

    this.setState(updateObj);
  };

  formHasErrors = () => {
    return this.state.entranceId === '' || this.state.name === '' || this.state.code === '' || this.state.price === '';
  };

  handleSavePricingGroup = () => {
    let pricingGroup = {
      entranceId: this.state.entranceId,
      name: this.state.name,
      code: this.state.code,
      price: Math.round(this.state.price * 100, 2)
    };

    if (this.state.idName !== '') {
      pricingGroup.idName = this.state.idName;
    }

    this.props.handleSavePricingGroup(this.props.pricingGroup ? this.props.pricingGroup.id : null, pricingGroup);
  };

  handleTogglePricingGroupEnable = async () => {
    let status = this.state.status === 'enabled' ? 'disabled' : 'enabled';

    let pricingGroup = {
      entranceId: this.props.pricingGroup.entranceId,
      name: this.props.pricingGroup.name,
      code: this.props.pricingGroup.code,
      price: this.props.pricingGroup.price,
      status
    };

    let response = await this.props.handleTogglePricingGroupEnable(this.props.pricingGroup.id, pricingGroup);
    if (response.type === SAVE_PRICING_GROUP_SUCCESS) {
      this.setState({ status });
    }
  };

  handleDeletePricingGroup = () => {
    let pricingGroup = {
      entranceId: this.props.pricingGroup.entranceId,
      name: this.props.pricingGroup.name,
      code: this.props.pricingGroup.code,
      price: this.props.pricingGroup.price,
      status: 'deleted'
    };

    this.props.handleDeletePricingGroup(this.props.pricingGroup.id, pricingGroup);
  };

  render() {
    let { classes, pricingGroupMembers, disabled } = this.props;
    const { entranceId, name, code, price, idName, labelWidthEntrance } = this.state;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl required variant="outlined" className={classes.formControl}>
            <InputLabel
              ref={ref => {
                this.inputLabelRefEntrance = ref;
              }}
              htmlFor="entranceId-placeholder"
            >
              Entrance
            </InputLabel>
            <Select
              value={entranceId}
              onChange={this.handleChange('entranceId')}
              input={<OutlinedInput labelWidth={labelWidthEntrance} name="entranceId" id="entranceId-placeholder" />}
              disabled={this.props.pricingGroup !== undefined || disabled}
            >
              {this.getEntranceOptions()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl className={classes.formControl} style={{ marginTop: '-1.1rem' }}>
            <TextField
              id="name"
              className={classes.textField}
              value={name}
              onChange={this.handleChange('name')}
              label={'Name'}
              margin="normal"
              variant="outlined"
              required
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl className={classes.formControl} style={{ marginTop: '-1.1rem' }}>
            <TextField
              id="code"
              className={classes.textField}
              value={code}
              onChange={this.handleChange('code')}
              label={'Code'}
              margin="normal"
              variant="outlined"
              required
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3} className={classes.gridItem}>
          <FormControl className={classes.formControl} style={{ marginTop: '-1.1rem' }}>
            <TextField
              id="price"
              className={classes.textField}
              value={price}
              onChange={this.handleChange('price')}
              label={'Price'}
              margin="normal"
              variant="outlined"
              required
              InputProps={{
                inputComponent: PriceTextField
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3} className={classes.gridItem}>
          <FormControl className={classes.formControl} style={{ marginTop: '-1.1rem' }}>
            <TextField
              id="idName"
              className={classes.textField}
              value={idName}
              onChange={this.handleChange('idName')}
              label={'ID Name'}
              margin="normal"
              variant="outlined"
              disabled={disabled}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem} />
        <Grid item xs={12} className={classes.gridItem}>
          <PricingGroupMembers pricingGroup={this.props.pricingGroup} pricingGroupMembers={pricingGroupMembers} disabled={disabled} />
        </Grid>
        <Grid container item xs={12} justify={'flex-end'}>
          {this.props.pricingGroup && can(entrancePermissions.deletePricingGroup, this.props.pricingGroup.entranceId) && (
            <Button variant={'outlined'} onClick={this.handleDeletePricingGroup}>
              {'Delete'}
            </Button>
          )}
          {!disabled && (
            <Fragment>
              {this.props.pricingGroup && (
                <Button
                  variant={'outlined'}
                  onClick={this.handleTogglePricingGroupEnable}
                  style={{ marginLeft: 'auto', marginRight: '0.5rem' }}
                >
                  {this.state.status === 'enabled' ? 'Disable' : 'Enable'}
                </Button>
              )}
              <Button
                disabled={this.formHasErrors()}
                variant={'contained'}
                color={'primary'}
                style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
                onClick={this.handleSavePricingGroup}
              >
                {'Save'}
              </Button>
              <Button variant={'outlined'} onClick={this.props.handleCancel}>
                {'Cancel'}
              </Button>
            </Fragment>
          )}
        </Grid>
      </Grid>
    );
  }
}

PricingGroupForm.propTypes = {
  classes: PropTypes.object.isRequired,
  pricingGroup: PropTypes.object,
  pricingGroupMembers: PropTypes.array,
  entrances: PropTypes.array.isRequired,
  handleSavePricingGroup: PropTypes.func.isRequired,
  handleTogglePricingGroupEnable: PropTypes.func.isRequired,
  handleDeletePricingGroup: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  disabled: PropTypes.bool.isRequired
};

export default withStyles(styles)(PricingGroupForm);
