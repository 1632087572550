import { CALL_API } from '../../middleware/api';

export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILURE = 'GET_TRANSACTIONS_FAILURE';

export const SAVE_TRANSACTION_REQUEST = 'SAVE_TRANSACTION_REQUEST';
export const SAVE_TRANSACTION_SUCCESS = 'SAVE_TRANSACTION_SUCCESS';
export const SAVE_TRANSACTION_FAILURE = 'SAVE_TRANSACTION_FAILURE';

export const APPROVE_TRANSACTION_REQUEST = 'APPROVE_TRANSACTION_REQUEST';
export const APPROVE_TRANSACTION_SUCCESS = 'APPROVE_TRANSACTION_SUCCESS';
export const APPROVE_TRANSACTION_FAILURE = 'APPROVE_TRANSACTION_FAILURE';

export const DECLINE_TRANSACTION_REQUEST = 'DECLINE_TRANSACTION_REQUEST';
export const DECLINE_TRANSACTION_SUCCESS = 'DECLINE_TRANSACTION_SUCCESS';
export const DECLINE_TRANSACTION_FAILURE = 'DECLINE_TRANSACTION_FAILURE';

export const COMPLETE_TRANSACTION_REQUEST = 'COMPLETE_TRANSACTION_REQUEST';
export const COMPLETE_TRANSACTION_SUCCESS = 'COMPLETE_TRANSACTION_SUCCESS';
export const COMPLETE_TRANSACTION_FAILURE = 'COMPLETE_TRANSACTION_FAILURE';

export const getTransactions = status => {
  return {
    [CALL_API]: {
      types: [GET_TRANSACTIONS_REQUEST, GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_SUCCESS],
      authenticated: true,
      endpoint: status ? `v1/admin/transactions?status=${status}` : `v1/admin/transactions`,
      method: 'GET'
    }
  };
};

export const saveTransaction = (id, transaction) => {
  return {
    [CALL_API]: {
      types: [SAVE_TRANSACTION_REQUEST, SAVE_TRANSACTION_SUCCESS, SAVE_TRANSACTION_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/transactions/${id}`,
      method: 'PUT',
      payload: transaction,
      actionMetadata: id
    }
  };
};

export const approveTransaction = transactionId => {
  return {
    [CALL_API]: {
      types: [APPROVE_TRANSACTION_REQUEST, APPROVE_TRANSACTION_SUCCESS, APPROVE_TRANSACTION_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/transactions/${transactionId}/approve`,
      method: 'POST',
      actionMetadata: transactionId
    }
  };
};

export const declineTransaction = transactionId => {
  return {
    [CALL_API]: {
      types: [DECLINE_TRANSACTION_REQUEST, DECLINE_TRANSACTION_SUCCESS, DECLINE_TRANSACTION_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/transactions/${transactionId}/decline`,
      method: 'POST',
      actionMetadata: transactionId
    }
  };
};

export const completeTransaction = transactionId => {
  return {
    [CALL_API]: {
      types: [COMPLETE_TRANSACTION_REQUEST, COMPLETE_TRANSACTION_SUCCESS, COMPLETE_TRANSACTION_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/transactions/${transactionId}/complete`,
      method: 'POST',
      actionMetadata: transactionId
    }
  };
};
