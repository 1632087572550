import { CALL_API } from '../../middleware/api';

export const GET_NOTE_TYPES_REQUEST = 'GET_NOTE_TYPES_REQUEST';
export const GET_NOTE_TYPES_SUCCESS = 'GET_NOTE_TYPES_SUCCESS';
export const GET_NOTE_TYPES_FAILURE = 'GET_NOTE_TYPES_FAILURE';

export const CREATE_NOTE_TYPE_REQUEST = 'CREATE_NOTE_TYPE_REQUEST';
export const CREATE_NOTE_TYPE_SUCCESS = 'CREATE_NOTE_TYPE_SUCCESS';
export const CREATE_NOTE_TYPE_FAILURE = 'CREATE_NOTE_TYPE_FAILURE';

export const SAVE_NOTE_TYPE_REQUEST = 'SAVE_NOTE_TYPE_REQUEST';
export const SAVE_NOTE_TYPE_SUCCESS = 'SAVE_NOTE_TYPE_SUCCESS';
export const SAVE_NOTE_TYPE_FAILURE = 'SAVE_NOTE_TYPE_FAILURE';

export const DELETE_NOTE_TYPE_REQUEST = 'DELETE_NOTE_TYPE_REQUEST';
export const DELETE_NOTE_TYPE_SUCCESS = 'DELETE_NOTE_TYPE_SUCCESS';
export const DELETE_NOTE_TYPE_FAILURE = 'DELETE_NOTE_TYPE_FAILURE';

export const getNoteTypes = () => {
  return {
    [CALL_API]: {
      types: [GET_NOTE_TYPES_REQUEST, GET_NOTE_TYPES_SUCCESS, GET_NOTE_TYPES_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/notetypes`,
      method: 'GET'
    }
  };
};

export const createNoteType = noteType => {
  return {
    [CALL_API]: {
      types: [CREATE_NOTE_TYPE_REQUEST, CREATE_NOTE_TYPE_SUCCESS, CREATE_NOTE_TYPE_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/notetypes`,
      method: 'POST',
      payload: noteType
    }
  };
};

export const saveNoteType = (id, noteType) => {
  return {
    [CALL_API]: {
      types: [SAVE_NOTE_TYPE_REQUEST, SAVE_NOTE_TYPE_SUCCESS, SAVE_NOTE_TYPE_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/notetypes/${id}`,
      method: 'PUT',
      payload: noteType
    }
  };
};

export const deleteNoteType = noteTypeId => {
  return {
    [CALL_API]: {
      types: [DELETE_NOTE_TYPE_REQUEST, DELETE_NOTE_TYPE_SUCCESS, DELETE_NOTE_TYPE_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/notetypes/${noteTypeId}`,
      method: 'DELETE',
      actionMetadata: noteTypeId
    }
  };
};
