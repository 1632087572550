import jwt_decode from 'jwt-decode';

export const SET_AUTH = 'SET_AUTH';
export const CLEAR_AUTH = 'CLEAR_AUTH';


/* Define a login state to use across the app. */
export const LOGIN_STATE = {
  NOT_LOGGED_IN: 0,
  NON_ADMIN: 1,
  ADMIN: 2
};

export const setAuth = (auth, access) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_AUTH,
      auth: auth !== null ? jwt_decode(auth) : '',
      access: access !== null ? jwt_decode(access) : '',
      admin: true,
      web: true
    });
  };
};

export const clearAuth = () => {
  return {
    type: CLEAR_AUTH
  };
};
