const MapRouteTitle = location => {
  let pathname = location.pathname;

  function locate(route) {
    return pathname.indexOf(route) !== -1;
  }

  if (locate('board')) {
    return 'Board';
  } else if (locate('pricing')) {
    return 'Pricing';
  } else if (locate('coupons')) {
    return 'Coupons';
  } else if (locate('altCharges')) {
    return 'Alternative Charges';
  } else if (locate('notes')) {
    return 'Notes';
  } else if (locate('marketingContent')) {
    return 'Marketing Content';
  } else if (locate('outageBanner')) {
    return 'Outage Banner';
  } else if (locate('facilities')) {
    return 'Facilities';
  } else if (locate('dashboards')) {
    return 'Dashboards';
  } else if (locate('login')) {
    return 'Login';
  }

  return 'Board';
};

export default MapRouteTitle;
