import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import {
  getNoteTypes,
  createNoteType,
  saveNoteType,
  deleteNoteType,
  CREATE_NOTE_TYPE_SUCCESS,
  SAVE_NOTE_TYPE_SUCCESS,
  DELETE_NOTE_TYPE_SUCCESS
} from './noteTypes.actions';
import { getNoteTemplates } from './noteTemplates.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import NoteTypeFormComponent from './noteTypeForm.component';
import { find } from 'lodash';
import { showToast } from '../layout/layout.actions';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = theme => ({
  container: {
    padding: '3rem',
    height: 'calc(100% - 2rem)'
  }
});

class NoteTypeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleSaveNoteType = this.handleSaveNoteType.bind(this);
    this.handleDeleteNoteType = this.handleDeleteNoteType.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async handleSaveNoteType(id, noteType) {
    if (id) {
      let response = await this.props.saveNoteType(id, noteType);
      if (response.type === SAVE_NOTE_TYPE_SUCCESS) {
        this.props.history.goBack();
      } else {
        this.props.showToast('Could not save the Note Type. Please check your connection and try again.');
      }
    } else {
      logEvent('add_notetype', {
        adminId: this.props.user.email
      });
      let response = await this.props.createNoteType(noteType);
      if (response.type === CREATE_NOTE_TYPE_SUCCESS) {
        this.props.history.goBack();
      } else {
        this.props.showToast('Could not create the Note Type. Please check your connection and try again.');
      }
    }
  }

  async handleDeleteNoteType(id) {
    let response = await this.props.deleteNoteType(id);
    if (response.type === DELETE_NOTE_TYPE_SUCCESS) {
      this.props.history.goBack();
    } else {
      this.props.showToast('Could not delete the Note Type. Please check your connection and try again.');
    }
    return response;
  }

  handleCancel() {
    this.props.history.goBack();
  }

  componentWillMount() {
    this.props.setPageTitle('Note Type');
    this.props.getNoteTypes();
    this.props.getNoteTemplates();
  }

  render() {
    const { classes, isLoading, isSaving, noteTypes, noteTemplates, providerGroups } = this.props;
    const { noteTypeId } = this.props.match.params;

    if (isLoading === true) {
      return <LoadingOverlay />;
    }

    const noteType = find(noteTypes, { id: noteTypeId });
    const disabled = noteType
      ? !can(providerGroupPermissions.updateNoteType, noteType.providerGroupId)
      : !can(providerGroupPermissions.createNoteType, null);

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        {isSaving && <LoadingOverlay />}
        <NoteTypeFormComponent
          noteType={noteType}
          noteTemplates={noteTemplates}
          providerGroups={providerGroups}
          handleSaveNoteType={this.handleSaveNoteType}
          handleDeleteNoteType={this.handleDeleteNoteType}
          handleCancel={this.handleCancel}
          disabled={disabled}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    noteTypes: state.noteTypes.noteTypes,
    noteTemplates: state.noteTemplates.noteTemplates,
    isLoading: state.noteTypes.isLoading || state.noteTemplates.isLoading,
    isSaving: state.noteTypes.isSaving,
    providerGroups: state.layout.providerGroups,
    user: state.login.user
  };
};

NoteTypeContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        getNoteTypes,
        getNoteTemplates,
        createNoteType,
        saveNoteType,
        deleteNoteType,
        showToast
      }
    )(NoteTypeContainer)
  )
);
