import { Redirect, Route, Switch, matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';
import { LOGIN_STATE, clearAuth, setAuth } from '../login/login.actions';

import { getAccounts, getEntrances, getProviderGroups } from './layout.actions';
import { withRouteOnEnter } from '../../utilities/withRouteOnEnter.component';
import AltChargesPage from '../altCharges/altCharges.container';
import BoardPage from '../board/board.container';
import Callback from '../callback/callback.container';
import CouponPage from '../coupons/coupon.container';
import CouponsPage from '../coupons/coupons.container';
import DashboardsPage from '../dashboards/dashboards.container';
import DocumentTitleFormatter from '../../utilities/documentTitleFormatter';
import EntrancePage from '../entrances/entrance.container';
import EntrancesPage from '../entrances/entrances.container';
import FacilitiesPage from '../facilities/facilities.container';
import FacilityDetailPage from '../facilities/facilityDetail/facilityDetail.container';
import Header from './header.container';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import LoginPage from '../login/login.container';
import MapRouteTitle from '../../utilities/mapRouteTitle';
import MarketingContentCreatePage from '../marketing/marketingContentCreate.container';
import MarketingContentPage from '../marketing/marketingContent.container';
import NoWebAccessPage from './noWebAccess.container';
import NoteTemplatePage from '../notes/noteTemplate.container';
import NoteTypePage from '../notes/noteType.container';
import NotesPage from '../notes/notes.container';
import OutageBannerPage from '../outageBanner/outageBanner.container';
import PatientsPage from '../patients/patients.container';
import PricingBasePage from '../pricing/pricingBase.container';
import PricingGroupPage from '../pricing/pricingGroup.container';
import PricingPage from '../pricing/pricing.container';
import ResizeHandler from './resizeHandler.component';
import Toast from './toast.container';
import auth from '../../auth/auth';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';

const styles = theme => ({
  contentWrapper: {
    height: '100vh',
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
  },
  content: {
    overflow: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    // padding: theme.spacing.unit * 3,
    paddingTop: 64, // header height
  },
});

class MainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    ['checkAuth', 'shouldShowBackButton', 'loginSuccess'].map(
      key => (this[key] = this[key].bind(this))
    );
  }

  componentWillMount() {
    const rawIdToken = localStorage.getItem('id_token');
    const rawAccessToken = localStorage.getItem('access_token');
    this.props.setAuth(rawIdToken, rawAccessToken);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.loginState === LOGIN_STATE.ADMIN && this.props.loginState !== LOGIN_STATE.ADMIN) {
      this.props.getProviderGroups();
      this.props.getEntrances();
      this.props.getAccounts();
    } else if (
      nextProps.loginState !== LOGIN_STATE.ADMIN &&
      this.props.loginState === LOGIN_STATE.ADMIN
    ) {
      this.props.history.replace('/login');
    }
  }

  shouldShowBackButton() {
    const { pathname } = this.props.location;

    // console.log('PATHNAME!', pathname);
    const nonTerminalRoutes = [
      '/',
      '/logout',
      '/pricing/bases',
      '/pricing/groups',
      '/notes/types',
      '/notes/templates',
      '/coupons',
      '/altCharges',
      '/board',
      '/outageBanner',
      '/facilities',
    ];

    let toReturn = true;
    for (const route of nonTerminalRoutes) {
      if (
        matchPath(pathname, {
          path: route,
          exact: true,
          strict: false,
        })
      ) {
        toReturn = false;
      }
    }

    // default
    return toReturn;
    // return true;
  }

  checkAuth() {
    const { loginState, history, location } = this.props;

    if (loginState === LOGIN_STATE.ADMIN) {
      // NOTE: Right here you can read from the user's profile to determine if they have what they need to proceed
      if (location.pathname === '/login') {
        history.replace('/');
      }

      // If user attempts to go to a patients list that they can't ready demographcis, redirect to patients entrance list
      if (/^\/patients\/.*$/.test(location.pathname)) {
        const entranceId = location.pathname.split('/')[2];

        if (!can(entrancePermissions.readPatientDemographics, entranceId)) {
          history.replace('/patients');
        }
      }
    } else if (loginState === LOGIN_STATE.NON_ADMIN) {
      console.log('LOGGED IN BUT NOT AN ADMIN');
      if (location.pathname !== '/noWebAccess') {
        history.replace('/noWebAccess');
      }
    } else {
      console.log('NOT LOGGED IN!');
      if (location.pathname !== '/login') {
        history.replace('/login');
      }
    }
  }

  loginSuccess(idToken, accessToken) {
    console.log('LOGIN SUCCESSFUL');
    this.props.setAuth(idToken, accessToken);
  }

  render() {
    const { classes, authSet, loginState, isLoading } = this.props;

    if (isLoading || !authSet) {
      return <LoadingOverlay />;
    }

    return (
      <div className={classes.contentWrapper}>
        {loginState !== LOGIN_STATE.NOT_LOGGED_IN ? (
          loginState === LOGIN_STATE.ADMIN ? (
            <DocumentTitle title={DocumentTitleFormatter(MapRouteTitle(this.props.location))}>
              <>
                <ResizeHandler />
                <Header shouldShowBackButton={this.shouldShowBackButton()} />
                <main className={classes.content}>
                  <Switch>
                    <Route exact path="/" component={withRouteOnEnter(this.checkAuth)(BoardPage)} />
                    <Route
                      exact
                      path="/pricing/bases/:pricingBaseId"
                      component={withRouteOnEnter(this.checkAuth)(PricingBasePage)}
                    />
                    <Route
                      exact
                      path="/pricing/groups/:pricingGroupId"
                      component={withRouteOnEnter(this.checkAuth)(PricingGroupPage)}
                    />
                    <Redirect exact from="/pricing" to="/pricing/bases" />
                    <Route
                      path="/pricing"
                      component={withRouteOnEnter(this.checkAuth)(PricingPage)}
                    />
                    <Route
                      exact
                      path="/coupons/:couponId"
                      component={withRouteOnEnter(this.checkAuth)(CouponPage)}
                    />
                    <Route
                      exact
                      path="/coupons"
                      component={withRouteOnEnter(this.checkAuth)(CouponsPage)}
                    />
                    <Route
                      exact
                      path="/altCharges"
                      component={withRouteOnEnter(this.checkAuth)(AltChargesPage)}
                    />
                    <Route
                      exact
                      path="/notes/types/:noteTypeId"
                      component={withRouteOnEnter(this.checkAuth)(NoteTypePage)}
                    />
                    <Route
                      exact
                      path="/notes/templates/:noteTemplateId"
                      component={withRouteOnEnter(this.checkAuth)(NoteTemplatePage)}
                    />
                    <Redirect exact from="/notes" to="/notes/types" />
                    <Route path="/notes" component={withRouteOnEnter(this.checkAuth)(NotesPage)} />
                    <Route
                      exact
                      path="/entrances"
                      component={withRouteOnEnter(this.checkAuth)(EntrancesPage)}
                    />
                    {/* 
                    <Route
                      exact
                      path="/entrances/:entranceId/marketingContentCreate"
                      component={withRouteOnEnter(this.checkAuth)(MarketingContentCreatePage)}
                    /> */}
                    <Route
                      exact
                      path="/entrances/:entranceId"
                      component={withRouteOnEnter(this.checkAuth)(EntrancePage)}
                    />
                    <Route
                      exact
                      path="/marketingContent/:entranceId"
                      component={withRouteOnEnter(this.checkAuth)(MarketingContentPage)}
                    />
                    <Route
                      exact
                      path="/patients/:entranceId"
                      component={withRouteOnEnter(this.checkAuth)(PatientsPage)}
                    />
                    <Route
                      exact
                      path="/dashboards"
                      component={withRouteOnEnter(this.checkAuth)(DashboardsPage)}
                    />
                    <Route
                      exact
                      path="/marketingContent/:entranceId/create"
                      component={withRouteOnEnter(this.checkAuth)(MarketingContentCreatePage)}
                    />
                    <Route
                      exact
                      path="/marketingContent"
                      component={withRouteOnEnter(this.checkAuth)(EntrancesPage)}
                    />
                    <Route
                      exact
                      path="/patients"
                      component={withRouteOnEnter(this.checkAuth)(EntrancesPage)}
                    />
                    <Route
                      exact
                      path="/outageBanner"
                      component={withRouteOnEnter(this.checkAuth)(OutageBannerPage)}
                    />
                    <Route
                      exact
                      path="/facilities"
                      component={withRouteOnEnter(this.checkAuth)(FacilitiesPage)}
                    />
                    <Route
                      exact
                      path="/facilities/:facilityId"
                      component={withRouteOnEnter(this.checkAuth)(FacilityDetailPage)}
                    />
                    <Redirect from="*" to="/" />
                  </Switch>
                </main>
              </>
            </DocumentTitle>
          ) : (
            <Switch>
              <Route
                exact
                path="/noWebAccess"
                component={withRouteOnEnter(this.checkAuth)(NoWebAccessPage)}
              />
              <Redirect from="*" to="/noWebAccess" />
            </Switch>
          )
        ) : (
          <Switch>
            <Route exact path="/login" render={props => <LoginPage />} />
            <Route
              exact
              path="/callback"
              render={props => {
                auth.handleAuthentication(props, this.loginSuccess);
                return <Callback {...props} />;
              }}
            />
            <Redirect from="*" to="/login" />
          </Switch>
        )}
        )
        <Toast />
      </div>
    );
  }
}

MainContainer.contextTypes = {
  router: PropTypes.object,
};

MainContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  authSet: PropTypes.bool.isRequired,
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  loginState: PropTypes.number.isRequired,

  setAuth: PropTypes.func.isRequired,
  clearAuth: PropTypes.func.isRequired,
  getProviderGroups: PropTypes.func.isRequired,
  getEntrances: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
};

MainContainer.defaultProps = {
  children: null,
};

const mapStateToProps = state => {
  return {
    loginState: state.login.loginState,
    authSet: state.login.authSet,
    isLoading:
      state.layout.isProviderGroupsLoading ||
      state.layout.isEntrancesLoading ||
      state.layout.isAccountsLoading,
  };
};

const withRouterMainContainer = withRouter(
  connect(mapStateToProps, {
    setAuth,
    clearAuth,
    getProviderGroups,
    getEntrances,
    getAccounts,
  })(MainContainer)
);

export default withStyles(styles, { withTheme: true })(withRouterMainContainer);
