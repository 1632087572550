import { isNil } from 'lodash';

import { RELATIONSHIP_TYPES } from '../types/relationshipTypes';

const createPatientPayload = patient => {
  const payload = { ...patient };

  payload.insuranceCardImageUploadId = null;
  payload.insuranceCardImageUpload2Id = null;
  payload.insuranceCardImageUpload3Id = null;
  payload.insuranceCardImageUpload4Id = null;

  payload.attachments.forEach((attachment, i) => {
    if (i === 0) {
      payload.insuranceCardImageUploadId = attachment.id;
    } else {
      payload[`insuranceCardImageUpload${i + 1}Id`] = attachment.id;
    }
  });

  if (
    isNil(payload.insuranceRelationshipToInsured) ||
    payload.insuranceRelationshipToInsured === RELATIONSHIP_TYPES.RELATIONSHIP_SELF.value
  ) {
    payload.insuranceSubscriberAddressCity = null;
    payload.insuranceSubscriberAddressLine1 = null;
    payload.insuranceSubscriberAddressLine2 = null;
    payload.insuranceSubscriberAddressState = null;
    payload.insuranceSubscriberAddressZip = null;
    payload.insuranceSubscriberDob = null;
    payload.insuranceSubscriberGender = null;
    payload.insuranceSubscriberName = null;
  }

  payload.pricingData = {
    groupCode: patient.groupCode,
    groupIdentifier: patient.groupIdentifier,
  };

  delete payload.attachments;
  delete payload.groupCode;
  delete payload.groupIdentifier;

  return payload;
};

export default createPatientPayload;
