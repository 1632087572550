import { CALL_API } from '../../middleware/api';

export const GET_PRICING_BASES_REQUEST = 'GET_PRICING_BASES_REQUEST';
export const GET_PRICING_BASES_SUCCESS = 'GET_PRICING_BASES_SUCCESS';
export const GET_PRICING_BASES_FAILURE = 'GET_PRICING_BASES_FAILURE';

/*
export const CREATE_PRICING_BASE_REQUEST = 'CREATE_PRICING_BASE_REQUEST';
export const CREATE_PRICING_BASE_SUCCESS = 'CREATE_PRICING_BASE_SUCCESS';
export const CREATE_PRICING_BASE_FAILURE = 'CREATE_PRICING_BASE_FAILURE';
*/

export const SAVE_PRICING_BASE_REQUEST = 'SAVE_PRICING_BASE_REQUEST';
export const SAVE_PRICING_BASE_SUCCESS = 'SAVE_PRICING_BASE_SUCCESS';
export const SAVE_PRICING_BASE_FAILURE = 'SAVE_PRICING_BASE_FAILURE';

/*
export const DELETE_PRICING_BASE_REQUEST = 'DELETE_PRICING_BASE_REQUEST';
export const DELETE_PRICING_BASE_SUCCESS = 'DELETE_PRICING_BASE_SUCCESS';
export const DELETE_PRICING_BASE_FAILURE = 'DELETE_PRICING_BASE_FAILURE';
*/

export const getPricingBases = () => {
  return {
    [CALL_API]: {
      types: [GET_PRICING_BASES_REQUEST, GET_PRICING_BASES_SUCCESS, GET_PRICING_BASES_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/entrances`,
      method: 'GET'
    }
  };
};

/*
export const createPricingBase = pricingBase => {
  return {
    [CALL_API]: {
      types: [CREATE_PRICING_BASE_REQUEST, CREATE_PRICING_BASE_SUCCESS, CREATE_PRICING_BASE_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/pricingbases`,
      method: 'POST',
      payload: pricingBase
    }
  };
};
*/

export const savePricingBase = (id, pricingBase) => {
  return {
    [CALL_API]: {
      types: [SAVE_PRICING_BASE_REQUEST, SAVE_PRICING_BASE_SUCCESS, SAVE_PRICING_BASE_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/entrances/${id}`,
      method: 'PUT',
      payload: pricingBase
    }
  };
};

/*
export const deletePricingBase = pricingBaseId => {
  return {
    [CALL_API]: {
      types: [DELETE_PRICING_BASE_REQUEST, DELETE_PRICING_BASE_SUCCESS, DELETE_PRICING_BASE_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/pricingbases/${pricingBaseId}`,
      method: 'DELETE',
      actionMetadata: pricingBaseId
    }
  };
};
*/
