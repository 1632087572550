import { connect } from 'react-redux';
import { getFacilities } from './facilities.actions';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import FacilitiesDataTable from './facilitiesDataTable.component';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';

const styles = (/* theme */) => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 5rem)',
  },
});

class FacilitiesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    this.props.setPageTitle('Facilities');
    this.props.getFacilities();
  }

  render() {
    const { classes, isLoading, facilities } = this.props;

    return (
      <div className={classNames([classes.container, 'page-padding-bottom'])}>
        {isLoading ? <LoadingOverlay /> : <FacilitiesDataTable data={facilities} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    layout: { entrances },
    facilities: { facilities, isLoading },
  } = state;

  return {
    facilities: facilities
      .filter(facility => facility.status !== 'deleted')
      .map(facility => {
        const entrance = entrances.find(entrance => facility.entranceId === entrance.id);

        if (entrance) {
          facility.entranceName = entrance.name;
        } else {
          facility.entranceName = 'N/A';
        }

        return facility;
      }),
    isLoading,
  };
};

FacilitiesContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  facilities: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,

  getFacilities: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    getFacilities,
  })
)(FacilitiesContainer);
