import {
  GET_NOTE_TEMPLATES_REQUEST,
  GET_NOTE_TEMPLATES_SUCCESS,
  GET_NOTE_TEMPLATES_FAILURE,
  CREATE_NOTE_TEMPLATE_REQUEST,
  CREATE_NOTE_TEMPLATE_SUCCESS,
  CREATE_NOTE_TEMPLATE_FAILURE,
  SAVE_NOTE_TEMPLATE_REQUEST,
  SAVE_NOTE_TEMPLATE_SUCCESS,
  SAVE_NOTE_TEMPLATE_FAILURE,
  DELETE_NOTE_TEMPLATE_REQUEST,
  DELETE_NOTE_TEMPLATE_SUCCESS,
  DELETE_NOTE_TEMPLATE_FAILURE
} from './noteTemplates.actions';
import produce from 'immer';

const initialState = {
  noteTemplates: [],
  isLoading: false,
  isSaving: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_NOTE_TEMPLATES_REQUEST:
      draft.isLoading = true;
      break;

    case GET_NOTE_TEMPLATES_SUCCESS:
      draft.isLoading = false;
      draft.noteTemplates = action.response.data;
      break;

    case GET_NOTE_TEMPLATES_FAILURE:
      draft.isLoading = false;
      break;

    case CREATE_NOTE_TEMPLATE_REQUEST:
      draft.isSaving = true;
      break;

    case CREATE_NOTE_TEMPLATE_SUCCESS: {
      draft.isSaving = false;
      break;
    }

    case CREATE_NOTE_TEMPLATE_FAILURE:
      draft.isSaving = false;
      break;

    case SAVE_NOTE_TEMPLATE_REQUEST:
      draft.isSaving = true;
      break;

    case SAVE_NOTE_TEMPLATE_SUCCESS: {
      draft.isSaving = false;
      break;
    }

    case SAVE_NOTE_TEMPLATE_FAILURE:
      draft.isSaving = false;
      break;

    case DELETE_NOTE_TEMPLATE_REQUEST:
      draft.isSaving = true;
      break;

    case DELETE_NOTE_TEMPLATE_SUCCESS:
      draft.isSaving = false;
      break;

    case DELETE_NOTE_TEMPLATE_FAILURE:
      draft.isSaving = false;
      break;

    default:
      break;
  }
}, initialState);
