import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from '@material-ui/core';

const ToolbarButton = ({ icon, text, onMouseDown, classes, disabled }) => {
  function handleMouseDown(event) {
    event.preventDefault();
    onMouseDown(event);
  }

  return (
    <Button onMouseDown={handleMouseDown} classes={classes} disabled={disabled} disableRipple disableFocusRipple disableTouchRipple>
      {icon ? <Icon classes={{ root: classes.toolbarIconRoot }}>{icon}</Icon> : ''}
      {text ? text : ''}
    </Button>
  );
};

ToolbarButton.defaultProps = {
  onMouseDown: () => {},
  disabled: false
};

ToolbarButton.propTypes = {
  icon: PropTypes.string,
  onMouseDown: PropTypes.func,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default ToolbarButton;
