import React, { Component } from 'react';

export const withRouteOnEnter = callback => BaseComponent => {
  const routeOnEnterCallback = props => {
    if (callback && typeof callback === 'function') {
      callback(props);
    }
  };

  class routeOnEnterComponent extends Component {
    componentWillMount() {
      routeOnEnterCallback(this.props);
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.location !== this.props.location) {
        routeOnEnterCallback(nextProps);
      }
    }

    render() {
      return <BaseComponent {...this.props} />;
    }
  }

  return routeOnEnterComponent;
};
