import {
  GET_OUTAGE_BANNER_SUCCESS,
  SET_OUTAGE_BANNER_LOADING,
  SET_OUTAGE_BANNER_REQUEST,
  SET_OUTAGE_BANNER_FAILURE,
  SET_OUTAGE_BANNER_SUCCESS
} from './outageBanner.actions';
import produce from 'immer';

const initialState = {
  outageBanner: {
    patient: null,
    provider: null
  },
  isLoading: false,
  isSaving: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_OUTAGE_BANNER_SUCCESS:
      draft.outageBanner = action.payload;
      break;

    case SET_OUTAGE_BANNER_LOADING:
      draft.isLoading = action.payload;
      break;

    case SET_OUTAGE_BANNER_REQUEST:
      draft.isSaving = true;
      break;

    case SET_OUTAGE_BANNER_SUCCESS:
      draft.isSaving = false;
      break;

    case SET_OUTAGE_BANNER_FAILURE:
      draft.isSaving = false;
      break;

    default:
      break;
  }
}, initialState);
