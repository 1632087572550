import {
  GET_COUPONS_REQUEST,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAILURE,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAILURE,
  SAVE_COUPON_REQUEST,
  SAVE_COUPON_SUCCESS,
  SAVE_COUPON_FAILURE
} from './coupons.actions';
import produce from 'immer';

const initialState = {
  coupons: [],
  isLoading: false,
  isSaving: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_COUPONS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_COUPONS_SUCCESS:
      draft.isLoading = false;
      draft.coupons = action.response.data;
      break;

    case GET_COUPONS_FAILURE:
      draft.isLoading = false;
      break;

    case CREATE_COUPON_REQUEST:
      draft.isSaving = true;
      break;

    case CREATE_COUPON_SUCCESS: {
      draft.isSaving = false;
      break;
    }

    case CREATE_COUPON_FAILURE:
      draft.isSaving = false;
      break;

    case SAVE_COUPON_REQUEST:
      draft.isSaving = true;
      break;

    case SAVE_COUPON_SUCCESS: {
      draft.isSaving = false;
      break;
    }

    case SAVE_COUPON_FAILURE:
      draft.isSaving = false;
      break;

    default:
      break;
  }
}, initialState);
