import { Button, Table, TableBody, TableCell, TableHead, TableRow, Toolbar } from '@material-ui/core';
import { SET_OUTAGE_BANNER_SUCCESS, getOutageBanner, setOutageBanner } from './outageBanner.actions';
import { Typography } from '@material-ui/core';
import { can } from '../login/can';
import { connect } from 'react-redux';
import { entrancePermissions } from '../../types/permissionTypes';
import { getFriendlyName } from '../../types/outageBannerTypes';
import { isNil } from 'lodash';
import { showToast } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Colors from '../../styles/colors';
import CustomModal from '../../common/modal/customModal.component';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import OutageBannerForm from './outageBannerForm.component';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import alertTypes from '../../types/alertTypes';
import moment from 'moment';

const DATE_FORMAT = 'M/D/YYYY, h:MMa';

const styles = (theme) => ({
  button: {
    marginLeft: 'auto',
  },
});

class OutageBannerTable extends Component {
  state = {
    isShowModal: false,
  };

  onAddClick = (evt) => {
    this.setState({
      isShowModal: true,
    });
  };

  onEditClick = (evt) => {
    this.setState({
      isShowModal: true,
    });
  };

  onDeleteClick = (type) => async (evt) => {
    if (window.confirm(`Are you sure you want to delete the ${getFriendlyName(type)} outage banner?`)) {
      // clear outage banner by only passing state param as 'empty' state
      const toSave = {
        ...this.props.outageBanner,
        [type]: { state: alertTypes.NORMAL },
      };

      // set empty state as needed by server (nulls not allowed)
      Object.keys(toSave).forEach((k) => {
        if (isNil(toSave[k])) {
          toSave[k] = { state: alertTypes.NORMAL };
        }
      });

      let result = await this.props.setOutageBanner(toSave);
      if (result.type === SET_OUTAGE_BANNER_SUCCESS) {
        this.props.getOutageBanner();
      } else {
        showToast('Could not delete outage banner. Please check your connection and try again.');
      }
    }
  };

  handleCloseModal = (isSave = false) => {
    this.setState({
      isShowModal: false,
    });

    if (isSave) {
      this.props.getOutageBanner();
    }
  };

  handleSave = (type) => async (data) => {
    const toSave = {
      ...this.props.outageBanner,
      [type]: data,
    };

    // set empty state as needed by server (nulls not allowed)
    Object.keys(toSave).forEach((k) => {
      if (isNil(toSave[k])) {
        toSave[k] = { state: alertTypes.NORMAL };
      }
    });

    let result = await this.props.setOutageBanner(toSave);
    if (result.type === SET_OUTAGE_BANNER_SUCCESS) {
      this.handleCloseModal(true);
    } else {
      this.props.showToast('Could not set a new outage banner. Please check your connection and try again.');
    }
  };

  render() {
    const { outageBanner, classes, isSaving, type } = this.props;
    const { isShowModal } = this.state;

    const hasReadPermission = can(entrancePermissions.readOutageBanner);
    const hasUpdatePermission = can(entrancePermissions.updateOutageBanner);
    const hasDeletePermission = can(entrancePermissions.deleteOutageBanner);
    const hasCreatePermission = can(entrancePermissions.createOutageBanner);

    if (isSaving) return <LoadingOverlay />;

    const bannerType = getFriendlyName(type);
    const hasOutageBanner = !isNil(outageBanner[type]);

    return (
      <>
        {!hasReadPermission && (
          <Toolbar>
            <Typography variant={'h6'} style={{ color: Colors.primary.darkgray }}>
              You do not have permission to view outage banners.
            </Typography>
          </Toolbar>
        )}

        {!hasOutageBanner && hasReadPermission && (
          <Toolbar>
            <Typography variant={'h6'} style={{ color: Colors.primary.darkgray }}>
              No {bannerType} Outage Banner is Currently Set
            </Typography>
            {hasCreatePermission && (
              <Button className={classes.button} variant="contained" color="primary"
                onClick={this.onAddClick}
              >
                {'ADD'}
              </Button>
            )}
          </Toolbar>
        )}

        {hasOutageBanner && hasReadPermission && (
          <>
            <Toolbar>
              <Typography variant={'h6'} style={{ color: Colors.primary.darkgray, flex: 1 }}>
                Current {bannerType} Outage Banner
              </Typography>
              {hasUpdatePermission && (
                <Button
                  className={classes.button}
                  style={{ marginRight: '1rem' }}
                  variant="contained"
                  color="primary"
                  onClick={this.onEditClick}
                >
                  {'EDIT'}
                </Button>
              )}
              {hasDeletePermission && (
                <Button className={classes.button} variant="outlined" color="primary"
                  onClick={this.onDeleteClick(type)}
                >
                  {'DELETE'}
                </Button>
              )}
            </Toolbar>
            <Table className={classes.table} aria-label="outage banner table">
              <TableHead>
                <TableRow>
                  <TableCell>Message</TableCell>
                  <TableCell>Start</TableCell>
                  <TableCell>End</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {outageBanner[type].text}
                  </TableCell>
                  <TableCell>{moment(outageBanner[type].startTime).format(DATE_FORMAT)}</TableCell>
                  <TableCell>
                    {outageBanner[type].endTime ? moment(outageBanner[type].endTime).format(DATE_FORMAT) : 'No End Date'}
                  </TableCell>
                  <TableCell>{outageBanner[type].state}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        )}
        <CustomModal open={isShowModal} handleClose={this.handleCloseModal}>
          <Typography variant="h6" style={{ color: Colors.primary.darkgray }}>
            {bannerType} Outage Banner
          </Typography>
          <OutageBannerForm
            handleClose={this.handleCloseModal}
            outageBanner={hasOutageBanner ? outageBanner[type] : null}
            type={type}
            handleSave={this.handleSave(type)}
          />
        </CustomModal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outageBanner: state.outageBanner.outageBanner,
    isSaving: state.outageBanner.isSaving,
  };
};

OutageBannerTable.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSaving: PropTypes.bool,
  getOutageBanner: PropTypes.func.isRequired,
  setOutageBanner: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  showToast: PropTypes.func.isRequired,
  outageBanner: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        getOutageBanner,
        setOutageBanner,
        showToast,
      }
    )(OutageBannerTable)
  )
);
