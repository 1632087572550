import React, { Component, Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import NoteAdd from '@material-ui/icons/NoteAdd';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import { showToast } from '../../layout/layout.actions';
import { uploadFiles } from '../../../utilities/upload';
import {
  processFacilityMemberImport,
  getFacilityMemberships,
  PROCESS_FACILITY_MEMBER_IMPORT_SUCCESS,
} from '../facilities.actions';
import LoadingOverlay from '../../../common/loadingOverlay/loadingOverlay.component';
import { isEmpty, isNil } from 'lodash';
import { BASE_URL } from '../../../middleware/api';
import { logEvent } from '../../../utilities/googleAnalytics';

const styles = theme => ({
  formControl: {
    width: '100%',
  },
  gridItem: {
    padding: '1rem',
  },
  heading: {
    color: theme.palette.primary.darkgray,
    margin: '0 auto 0.5rem',
    textAlign: 'center',
  },
  text: {
    color: theme.palette.primary.darkgray,
    fontSize: '1rem',
    textAlign: 'center',
    marginBottom: '0.5rem',
  },
  dropzone: {
    margin: '0.5rem',
    borderColor: theme.palette.primary.darkgray,
    borderStyle: 'dashed',
    borderRadius: '0.5rem',
    borderWidth: 2,
    cursor: 'pointer',
    padding: '1rem',
    color: theme.palette.primary.darkgray,
    fontSize: '1.2rem',
  },
  dropzoneIcon: {
    fontSize: 42,
    color: theme.palette.primary.main,
    alignSelf: 'center',
  },
  countGridItem: {
    padding: '0.25rem',
    margin: 'auto',
  },
  countBox: {
    borderWidth: 1,
    borderColor: theme.palette.primary.darkgray,
    borderStyle: 'solid',
    padding: '1rem',
  },
  countBoxText: {
    color: theme.palette.primary.darkgray,
  },
});

const FacilitatorsUploadForm = ({ classes, handleClose }) => {
  const dispatch = useDispatch();
  const facilityId = useSelector(state => state.facilities.facilityDetail.id);
  const MAX_FILE_UPLOAD_SIZE = 10000000;
  const UPLOAD_FILE_URL = `${BASE_URL}v1/admin/facilities/${facilityId}/members/uploads`;

  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileDrop = (acceptedFiles, rejectedFiles) => {
    if (!isEmpty(rejectedFiles)) {
      showToast('Please select a CSV file');
    }
    setAcceptedFiles(acceptedFiles);
  };

  const handleFileUpload = async () => {
    setIsUploading(true);
    uploadFiles(
      UPLOAD_FILE_URL,
      acceptedFiles,
      response => {
        setIsUploading(false);
        setUploadedFile(response);
      },
      err => {
        showToast('There was a problem uploading the file. Please try again.');
        setIsUploading(false);
        setUploadedFile(null);
      }
    );
  };

  const processUpload = async () => {
    setIsUploading(true);

    const result = await dispatch(processFacilityMemberImport(facilityId, uploadedFile.id));

    setIsUploading(false);
    if (result.type === PROCESS_FACILITY_MEMBER_IMPORT_SUCCESS) {
      handleClose();
      dispatch(showToast('Success!'));
      await dispatch(getFacilityMemberships(facilityId));
    } else {
      dispatch(showToast('Failure!', true));
    }
  };

  return (
    <Grid container spacing={16} className={classes.container}>
      {isUploading && <LoadingOverlay />}
      <Grid item xs={12}>
        <Typography className={classes.heading} variant={'h6'}>
          Import Facilitators
        </Typography>
      </Grid>

      {/* FILE NOT YET UPLOADED */}
      {isNil(uploadedFile) && (
        <Fragment>
          <Grid item xs={12} className={classes.gridItem}>
            <Typography className={classes.text} style={{ marginBottom: 0 }}>
              Upload a CSV file of the facilitators.
            </Typography>
            <Typography className={classes.text}>
              The file should have the following columns in this order: firstName, lastName, dob,
              and email.
            </Typography>
          </Grid>

          <Grid item xs={8} style={{ display: 'flex', margin: 'auto' }}>
            <NoteAdd className={classes.dropzoneIcon} />
            <Dropzone
              accept=".csv"
              maxSize={MAX_FILE_UPLOAD_SIZE}
              onDrop={handleFileDrop}
              className={'no-select'}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} style={{ outline: 'none', width: '100%' }}>
                  <input {...getInputProps()} />
                  {isEmpty(acceptedFiles) && (
                    <Typography className={classes.dropzone}>
                      Drop CSV file here, or click to select CSV file to upload.
                    </Typography>
                  )}
                  {!isEmpty(acceptedFiles) && (
                    <Typography className={classes.dropzone}>{acceptedFiles[0].name}</Typography>
                  )}
                </div>
              )}
            </Dropzone>
          </Grid>

          <Grid container item xs={12} justify={'flex-end'}>
            <Button variant={'outlined'} onClick={() => handleClose()}>
              {'Cancel'}
            </Button>
            <Button
              disabled={isEmpty(acceptedFiles)}
              variant={'outlined'}
              color={'primary'}
              style={{ marginLeft: 'auto', marginRight: '0.5rem' }}
              onClick={() => handleFileUpload(UPLOAD_FILE_URL)}
            >
              {'Upload'}
            </Button>
          </Grid>
        </Fragment>
      )}

      {/* FILE UPLOADED BUT NOT YET APPROVED */}
      {!isNil(uploadedFile) && (
        <Fragment>
          <Grid container spacing={16} style={{ marginBottom: '1rem' }}>
            <Grid item xs={3} className={classes.countGridItem}>
              <div className={classes.countBox}>
                <Typography className={classes.countBoxText}>Total uploaded</Typography>
                <Typography className={classes.countBoxText} style={{ fontSize: '1.6rem' }}>
                  {uploadedFile.records}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3} className={classes.countGridItem}>
              <div className={classes.countBox}>
                <Typography className={classes.countBoxText}>Facilitators to create</Typography>
                <Typography className={classes.countBoxText} style={{ fontSize: '1.6rem' }}>
                  {uploadedFile.changes.create.length}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3} className={classes.countGridItem}>
              <div className={classes.countBox}>
                <Typography className={classes.countBoxText}>Facilitators to skip</Typography>
                <Typography className={classes.countBoxText} style={{ fontSize: '1.6rem' }}>
                  {uploadedFile.changes.skip.length}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3} className={classes.countGridItem}>
              <div className={classes.countBox}>
                <Typography className={classes.countBoxText}>Records with errors</Typography>
                <Typography className={classes.countBoxText} style={{ fontSize: '1.6rem' }}>
                  {uploadedFile.changes.errors.length}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Grid container item xs={12} justify={'flex-end'}>
            <Button variant={'outlined'} onClick={() => handleClose()}>
              {'Cancel'}
            </Button>
            <Button
              variant={'contained'}
              color={'primary'}
              style={{ marginLeft: 'auto', marginRight: '0.5rem' }}
              onClick={processUpload}
            >
              {'Process'}
            </Button>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(FacilitatorsUploadForm);
