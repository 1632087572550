import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { showToast } from '../layout/layout.actions';
import MarketingContentForm from './marketingContentForm.component';
import { isEmpty, isNil } from 'lodash';
import { saveMarketingContent, SAVE_MARKETING_CONTENT_SUCCESS } from './marketing.actions';

class MarketingContentCreateContainer extends Component {
  componentWillMount() {
    const { entrance } = this.props;

    if (!isEmpty(entrance)) {
      this.props.setPageTitle(`New Marketing Content - ${entrance.name}`);
    } else {
      this.props.setPageTitle('New Marketing Content');
    }
  }

  handleSaveContent = async content => {
    const { entrance } = this.props;

    if (!isEmpty(entrance)) {
      const newEntrance = { ...entrance };

      if (isNil(newEntrance.marketingContent)) {
        newEntrance.marketingContent = {};
      }

      const newContent = { ...content };
      delete newContent.contentType;

      if (!isEmpty(newEntrance.marketingContent[content.contentType])) {
        newEntrance.marketingContent[content.contentType].push(newContent);
      } else {
        newEntrance.marketingContent[content.contentType] = [newContent];
      }

      delete newEntrance.created;
      delete newEntrance.updated;
      delete newEntrance.doseSpot;
      delete newEntrance.stripe;
      delete newEntrance.id;
      delete newEntrance.tenantId;

      const result = await this.props.saveMarketingContent(entrance.id, newEntrance);

      if (result.type === SAVE_MARKETING_CONTENT_SUCCESS) {
        this.props.history.replace(`/marketingContent/${this.props.match.params.entranceId}`);
      }
    }
  };

  handleCancel = () => {
    this.props.history.replace(`/marketingContent/${this.props.match.params.entranceId}`);
  };

  render() {
    const { classes, entrance } = this.props;

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        <MarketingContentForm
          entrance={entrance}
          handleCancel={this.handleCancel}
          handleDeleteContent={this.handleDeleteContent}
          handleSaveContent={this.handleSaveContent}
        />
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 5rem)',
  },
});

MarketingContentCreateContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  entrance: PropTypes.object,

  saveMarketingContent: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

MarketingContentCreateContainer.defaultProps = {
  entrance: null,
};

const mapStateToProps = (state, props) => {
  return {
    entrance: state.layout.entrances.find(entrance => entrance.id === props.match.params.entranceId),
  };
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      setPageTitle,
      showToast,
      saveMarketingContent,
    })(MarketingContentCreateContainer)
  )
);
