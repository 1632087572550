import { CALL_API } from '../../middleware/api';

export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILURE = 'GET_COUPONS_FAILURE';

export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAILURE = 'CREATE_COUPON_FAILURE';

export const SAVE_COUPON_REQUEST = 'SAVE_COUPON_REQUEST';
export const SAVE_COUPON_SUCCESS = 'SAVE_COUPON_SUCCESS';
export const SAVE_COUPON_FAILURE = 'SAVE_COUPON_FAILURE';

export const getCoupons = () => {
  return {
    [CALL_API]: {
      types: [GET_COUPONS_REQUEST, GET_COUPONS_SUCCESS, GET_COUPONS_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/coupons`,
      method: 'GET'
    }
  };
};

export const createCoupon = coupon => {
  return {
    [CALL_API]: {
      types: [CREATE_COUPON_REQUEST, CREATE_COUPON_SUCCESS, CREATE_COUPON_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/coupons`,
      method: 'POST',
      payload: coupon
    }
  };
};

export const saveCoupon = (id, coupon) => {
  return {
    [CALL_API]: {
      types: [SAVE_COUPON_REQUEST, SAVE_COUPON_SUCCESS, SAVE_COUPON_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/coupons/${id}`,
      method: 'PUT',
      payload: coupon
    }
  };
};
