import { CALL_API } from '../../middleware/api';

export const GET_PRICING_GROUPS_REQUEST = 'GET_PRICING_GROUPS_REQUEST';
export const GET_PRICING_GROUPS_SUCCESS = 'GET_PRICING_GROUPS_SUCCESS';
export const GET_PRICING_GROUPS_FAILURE = 'GET_PRICING_GROUPS_FAILURE';

export const CREATE_PRICING_GROUP_REQUEST = 'CREATE_PRICING_GROUP_REQUEST';
export const CREATE_PRICING_GROUP_SUCCESS = 'CREATE_PRICING_GROUP_SUCCESS';
export const CREATE_PRICING_GROUP_FAILURE = 'CREATE_PRICING_GROUP_FAILURE';

export const SAVE_PRICING_GROUP_REQUEST = 'SAVE_PRICING_GROUP_REQUEST';
export const SAVE_PRICING_GROUP_SUCCESS = 'SAVE_PRICING_GROUP_SUCCESS';
export const SAVE_PRICING_GROUP_FAILURE = 'SAVE_PRICING_GROUP_FAILURE';

export const getPricingGroups = () => {
  return {
    [CALL_API]: {
      types: [GET_PRICING_GROUPS_REQUEST, GET_PRICING_GROUPS_SUCCESS, GET_PRICING_GROUPS_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/pricinggroups`,
      method: 'GET'
    }
  };
};

export const createPricingGroup = pricingGroup => {
  return {
    [CALL_API]: {
      types: [CREATE_PRICING_GROUP_REQUEST, CREATE_PRICING_GROUP_SUCCESS, CREATE_PRICING_GROUP_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/pricinggroups`,
      method: 'POST',
      payload: pricingGroup
    }
  };
};

export const savePricingGroup = (id, pricingGroup) => {
  return {
    [CALL_API]: {
      types: [SAVE_PRICING_GROUP_REQUEST, SAVE_PRICING_GROUP_SUCCESS, SAVE_PRICING_GROUP_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/pricinggroups/${id}`,
      method: 'PUT',
      payload: pricingGroup
    }
  };
};
