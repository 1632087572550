import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from 'lodash';

const styles = theme => ({
  expansionPanelContainer: {
    minHeight: '4rem',
    padding: '1rem'
  },
  text: {
    marginBottom: '1rem',
    fontSize: '1rem'
  }
});

class BoardRowExpansionPanel extends Component {
  render() {
    const { classes, data } = this.props;
    const location = data.location;

    return (
      <Grid container className={classes.expansionPanelContainer}>
        <Grid container xs={12}>
          <Grid item xs={1}>
            <Typography className={classes.text}>{`Address: `}</Typography>
          </Grid>
          {!isEmpty(location) && (
            <Grid item xs={11}>
              <Typography>{location.addressLine1}</Typography>
              <Typography>{location.addressLine2}</Typography>
              <Typography>{`${location.addressCity}, ${location.addressState} ${location.addressZip}`}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

BoardRowExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default withStyles(styles)(BoardRowExpansionPanel);
