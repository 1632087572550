import { Button, TableCell, TableRow } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';

import DataTable from '../../common/dataTable/dataTable.component';

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Entrance Name',
    sortable: true,
    filtering: true,
  },
  {
    id: 'price',
    numeric: false,
    disablePadding: false,
    label: 'Base Price',
    sortable: true,
    filtering: true,
  },
];

class EntrancesDataTable extends Component {
  handleEditClick = async (event, row) => {
    // route to entrance marketing content
    // or entrance settings depending on path

    const { pathname } = this.props.location;

    if (pathname.indexOf('marketingContent') > -1) {
      this.props.history.push(`/marketingContent/${row.id}`);
    } else if (pathname.indexOf('patients') > -1) {
      this.props.history.push(`/patients/${row.id}`);
    } else {
      this.props.history.push(`/entrances/${row.id}`);
    }
  };

  // get custom row render component
  renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow
          tabIndex={-1}
          classes={{
            root:
              i % 2 === 0
                ? classnames([this.props.classes.tableRowRoot, this.props.classes.rowHighlight])
                : this.props.classes.tableRowRoot,
          }}
        >
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">
            <NumberFormat
              value={row.price}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale
            />
          </TableCell>
          <TableCell align="right">
            <Button
              onClick={event => this.handleEditClick(event, row)}
              color="primary"
              variant="outlined"
            >
              VIEW
            </Button>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <DataTable
        data={data}
        title=""
        columns={columns}
        renderRow={this.renderRow}
        disableSearch
        defaultSortOrder="asc"
      />
    );
  }
}

const styles = theme => ({
  tableRowRoot: {
    cursor: 'pointer !important',
  },
  rowHighlight: {
    backgroundColor: theme.palette.primary.lightgray,
  },
});

EntrancesDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  data: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {};
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {})
)(EntrancesDataTable);
