import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DataTable from '../../common/dataTable/dataTable.component';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classnames from 'classnames';
import { Button } from '@material-ui/core';
import { find } from 'lodash';
import moment from 'moment';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = theme => ({
  tableRowRoot: {
    cursor: 'pointer !important'
  },
  rowHighlight: {
    backgroundColor: theme.palette.primary.lightgray
  }
});

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true, filtering: true },
  { id: 'code', numeric: false, disablePadding: false, label: 'Code', sortable: true, filtering: true },
  { id: 'startDate', numeric: false, disablePadding: false, label: 'Start Date', sortable: true, filtering: true },
  { id: 'endDate', numeric: false, disablePadding: false, label: 'End Date', sortable: true, filtering: true },
  { id: 'accounts', numeric: false, disablePadding: false, label: 'Accounts', sortable: true, filtering: true },
  { id: 'pricingGroups', numeric: false, disablePadding: false, label: 'Groups', sortable: true, filtering: true },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', sortable: true, filtering: true },
  { id: 'entranceId', numeric: false, disablePadding: false, label: 'Entry', sortable: true, filtering: true }
];

class CouponsDataTable extends Component {
  handleAddClick = () => {
    this.props.history.push(`/coupons/create`);
  };

  handleEditClick = async (event, row) => {
    logEvent('edit_coupon', {
      adminId: this.props.user.email,
      couponId: row.id
    });
    this.props.history.push(`/coupons/${row.id}`);
  };

  // get custom row render component
  renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow
          tabIndex={-1}
          classes={{
            root:
              i % 2 === 0 ? classnames([this.props.classes.tableRowRoot, this.props.classes.rowHighlight]) : this.props.classes.tableRowRoot
          }}
        >
          <TableCell align="left">{row.name}</TableCell>
          <TableCell align="left">{row.code}</TableCell>
          <TableCell align="left">{row.startDate}</TableCell>
          <TableCell align="left">{row.endDate}</TableCell>
          <TableCell align="left">{row.accounts}</TableCell>
          <TableCell align="left">{row.pricingGroups}</TableCell>
          <TableCell align="left">{row.status}</TableCell>
          <TableCell align="left">{row.entranceId}</TableCell>
          <TableCell align="right">
            <Button onClick={event => this.handleEditClick(event, row)} color="primary" variant="outlined">
              {'VIEW'}
            </Button>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  render() {
    const { data } = this.props;

    let formattedData = data.map(row => {
      row = { ...row };
      let entrance = find(this.props.entrances, { id: row.entranceId });
      row.entranceId = entrance ? entrance.name : '';

      row.startDate = `${moment.utc(row.startDate).format('L')}`;
      row.endDate = `${row.endDate ? moment.utc(row.endDate).format('L') : ''}`;

      let pricingGroups = row.pricingGroups
        ? row.pricingGroups
            .map(pricingGroupId => {
              let pg = find(this.props.pricingGroups, { id: pricingGroupId });
              return pg ? pg.name : null;
            })
            .filter(pg => pg !== null)
        : [];
      row.pricingGroups = pricingGroups.join(', ');

      let accounts = row.accounts
        ? row.accounts
            .map(accountId => {
              let a = find(this.props.accounts, { id: accountId });
              return a ? a.email : null;
            })
            .filter(a => a !== null)
        : [];
      row.accounts = accounts.join(', ');

      return row;
    });

    console.log(data);

    return (
      <DataTable
        data={formattedData}
        title={''}
        columns={columns}
        renderRow={this.renderRow}
        disableSearch
        defaultSortOrder={'asc'}
        onAddClick={can(entrancePermissions.createCoupon) ? this.handleAddClick : null}
      />
    );
  }
}

CouponsDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  entrances: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  pricingGroups: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.login.user
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    {}
  )(CouponsDataTable)
);
