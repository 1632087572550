import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormControl, TextField, InputLabel, Select, Button, OutlinedInput, MenuItem, ListItemText } from '@material-ui/core';
import alertTypes from '../../types/alertTypes';
import moment from 'moment';

const stateOptions = Object.keys(alertTypes).map(k => {
  return { value: k, label: k };
});

const DATE_PICKER_FORMAT = 'YYYY-MM-DDTHH:MM';

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  formActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem'
  }
});

class OutageBannerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: props.outageBanner ? props.outageBanner.text : '',
      startTime: props.outageBanner ? moment(props.outageBanner.startTime).format(DATE_PICKER_FORMAT) : '',
      endTime: props.outageBanner ? (props.outageBanner.endTime ? moment(props.outageBanner.endTime).format(DATE_PICKER_FORMAT) : '') : '',
      state: props.outageBanner ? props.outageBanner.state : ''
    };
  }

  handleChange = name => event => {
    let updateObj = { [name]: event.target.value };
    this.setState(updateObj);
  };

  formHasErrors = () => {
    const { text, startTime, endTime, state } = this.state;

    // check for empty required fields
    if (text === '' || startTime === '' || state === '') {
      return true;
    }

    // check that start comes before end if an endTime is provided
    if (endTime !== '') {
      if (moment(startTime).valueOf() >= moment(endTime).valueOf()) {
        return true;
      }
    }

    return false;
  };

  handleSave = () => {
    const mapState = { ...this.state };

    mapState.startTime = moment(mapState.startTime)
      .utc()
      .toISOString();

    if (mapState.endTime !== '') {
      mapState.endTime = moment(mapState.endTime)
        .utc()
        .toISOString();
    } else {
      delete mapState.endTime;
    }

    this.props.handleSave(mapState);
  };

  render() {
    const { text, startTime, endTime, state } = this.state;
    const { classes, handleClose } = this.props;

    return (
      <div>
        <FormControl className={classes.formControl}>
          <TextField
            id="name"
            className={classes.textField}
            value={text}
            onChange={this.handleChange('text')}
            label={'Message Text'}
            margin="normal"
            variant="outlined"
            required
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="startTime"
            className={classes.textField}
            value={startTime}
            onChange={this.handleChange('startTime')}
            label={'Start Time'}
            margin="normal"
            variant="outlined"
            type="datetime-local"
            required
            InputLabelProps={{
              shrink: true
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="endTime"
            className={classes.textField}
            value={endTime}
            onChange={this.handleChange('endTime')}
            label={'End Time'}
            margin="normal"
            variant="outlined"
            type="datetime-local"
            InputLabelProps={{
              shrink: true
            }}
          />
        </FormControl>
        <FormControl required variant="outlined" margin="normal" className={classes.formControl}>
          <InputLabel htmlFor="state">Type</InputLabel>
          <Select value={state} onChange={this.handleChange('state')} input={<OutlinedInput labelWidth={40} name="state" id="state" />}>
            {stateOptions.map(o => (
              <MenuItem key={o.value} value={o.value}>
                <ListItemText primary={o.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className={classes.formActions}>
          <Button
            disabled={this.formHasErrors()}
            onClick={this.handleSave}
            variant="contained"
            color="primary"
            style={{ marginRight: '1rem' }}
          >
            Save
          </Button>
          <Button variant="outlined" color="primary" onClick={() => handleClose(false)}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSaving: state.outageBanner.isSaving
  };
};

OutageBannerForm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSaving: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  outageBanner: PropTypes.object,
  type: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle
      }
    )(OutageBannerForm)
  )
);
