import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AltChargesDataTable from './altChargesDataTable.component';
import classnames from 'classnames';
import {
  getTransactions,
  saveTransaction,
  approveTransaction,
  declineTransaction,
  completeTransaction,
  SAVE_TRANSACTION_FAILURE,
  APPROVE_TRANSACTION_FAILURE,
  DECLINE_TRANSACTION_FAILURE,
  COMPLETE_TRANSACTION_FAILURE,
} from './altCharges.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { getPricingGroups } from '../pricing/pricingGroups.actions';
import { showToast } from '../layout/layout.actions';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = theme => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 5rem)',
  },
});

class AltChargesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      currentRow: null,
      isShowModal: false,
      toggleAll: false,
    };

    this.handleToggleAll = this.handleToggleAll.bind(this);
    this.handleSaveTransaction = this.handleSaveTransaction.bind(this);
    this.handleApproveTransaction = this.handleApproveTransaction.bind(this);
    this.handleDeclineTransaction = this.handleDeclineTransaction.bind(this);
  }

  handleToggleAll = () => {
    this.props.getTransactions(this.state.toggleAll ? 'approval_needed' : 'approval_needed|complete');
    this.setState({ toggleAll: !this.state.toggleAll });
  };

  handleSaveTransaction = async (id, transaction) => {
    let response = await this.props.saveTransaction(id, transaction);
    if (response.type === SAVE_TRANSACTION_FAILURE) {
      this.props.showToast('Could not save the Alternative Charge. Please check your connection and try again.');
    }
    return response;
  };

  handleApproveTransaction = async transaction => {
    logEvent('approve_charge', {
      adminId: this.props.user.email,
      visitId: transaction.visitId,
      providerId: transaction.providerName,
    });

    let response = await this.props.approveTransaction(transaction.id);
    if (response.type === APPROVE_TRANSACTION_FAILURE) {
      this.props.showToast('Could not approve the Alternative Charge. Please check your connection and try again.');
    }
  };

  handleDeclineTransaction = async transaction => {
    logEvent('decline_charge', {
      adminId: this.props.user.email,
      visitId: transaction.visitId,
      providerId: transaction.providerName,
    });

    let response = await this.props.declineTransaction(transaction.id);
    if (response.type === DECLINE_TRANSACTION_FAILURE) {
      this.props.showToast('Could not decline the Alternative Charge. Please check your connection and try again.');
    }
  };

  handleCompleteTransaction = async transaction => {
    if (
      window.confirm(
        'Are you sure you would like to complete the transaction? Pressing OK will continue, but you will also need to handle Stripe cleanup.'
      )
    ) {
      logEvent('complete_charge', {
        adminId: this.props.user.email,
        visitId: transaction.visitId,
        providerId: transaction.providerName,
      });

      let response = await this.props.completeTransaction(transaction.id);
      if (response.type === COMPLETE_TRANSACTION_FAILURE) {
        this.props.showToast('Could not complete the Alternative Charge. Please check your connection and try again.');
      }
    }
  };

  componentWillMount() {
    this.props.setPageTitle('Alternative Charges');
    this.props.getTransactions('approval_needed');
    this.props.getPricingGroups();
  }

  render() {
    const { classes, isLoading, transactions, entrances, pricingGroups } = this.props;

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormControlLabel label="Needs Review" control={<div />} />
          <FormControlLabel control={<Switch checked={this.state.toggleAll} onChange={this.handleToggleAll} />} label="All" />
        </div>

        {isLoading ? (
          <LoadingOverlay />
        ) : (
          <AltChargesDataTable
            data={transactions}
            entrances={entrances}
            pricingGroups={pricingGroups}
            history={this.props.history}
            handleSaveTransaction={this.handleSaveTransaction}
            handleApproveTransaction={this.handleApproveTransaction}
            handleDeclineTransaction={this.handleDeclineTransaction}
            handleCompleteTransaction={this.handleCompleteTransaction}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    transactions: state.altCharges.transactions,
    pricingGroups: state.pricingGroups.pricingGroups,
    isLoading: state.altCharges.isLoading || state.pricingGroups.isLoading,
    entrances: state.layout.entrances,
    user: state.login.user,
  };
};

AltChargesContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  getTransactions: PropTypes.func.isRequired,
  saveTransaction: PropTypes.func.isRequired,
  approveTransaction: PropTypes.func.isRequired,
  declineTransaction: PropTypes.func.isRequired,
  completeTransaction: PropTypes.func.isRequired,
  getPricingGroups: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      setPageTitle,
      showToast,
      getTransactions,
      saveTransaction,
      approveTransaction,
      declineTransaction,
      completeTransaction,
      getPricingGroups,
    })(AltChargesContainer)
  )
);
