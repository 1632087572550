import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { isEmpty } from 'lodash';
import api from '../middleware/api';
import rootReducer from '../index.reducer';

let myStore;

const getStore = preloadedState => {
  if (isEmpty(myStore)) {
    const store = createStore(rootReducer, preloadedState, applyMiddleware(thunk, api));
    myStore = store;
  }
  return myStore;
};

export default getStore;
