import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BoardDataTable from './boardDataTable.component';
import classnames from 'classnames';
import { getBoardItems } from './board.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { visitStatusTypes } from '../../types/visitStatusTypes';

const styles = theme => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 2rem)'
  }
});

class BoardContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentRow: null
    };
  }

  componentWillMount() {
    this.props.setPageTitle('Board');
    this.props.getBoardItems();
  }

  render() {
    const { classes, isLoading } = this.props;
    let { boardItems } = this.props;

    if (isLoading === true) {
      return <LoadingOverlay />;
    }

    // filter by new status
    boardItems = boardItems.filter(item => item.state === visitStatusTypes.NEW);

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        <BoardDataTable data={boardItems} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    boardItems: state.board.boardItems,
    user: state.login.user,
    isLoading: state.board.isLoading,
    selectedVisit: state.layout.selectedVisit
  };
};

BoardContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        getBoardItems
      }
    )(BoardContainer)
  )
);
