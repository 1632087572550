import { CALL_API } from '../../middleware/api';

export const GET_BOARD_ITEMS_REQUEST = 'GET_BOARD_ITEMS_REQUEST';
export const GET_BOARD_ITEMS_SUCCESS = 'GET_BOARD_ITEMS_SUCCESS';
export const GET_BOARD_ITEMS_FAILURE = 'GET_BOARD_ITEMS_FAILURE';

export const getBoardItems = () => {
  return {
    [CALL_API]: {
      types: [GET_BOARD_ITEMS_REQUEST, GET_BOARD_ITEMS_SUCCESS, GET_BOARD_ITEMS_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/board`,
      method: 'GET'
    }
  };
};
