import { connect } from 'react-redux';
import { find } from 'lodash';
import { getEntrances } from '../layout/layout.actions';
import { setPageTitle } from '../layout/layout.actions';
import { showToast } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import EntranceForm from './entranceForm.component';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

const styles = (theme) => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 5rem)',
  },
});

class EntranceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    this.props.setPageTitle('Entrance Settings');
  }

  getEntrance() {
    return find(this.props.entrances, (entrance) => entrance.id === this.props.match.params.entranceId);
  }

  render() {
    const { classes, isLoading } = this.props;

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        {isLoading ? <LoadingOverlay /> : <EntranceForm entrance={this.getEntrance()} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.layout.isEntrancesLoading,
    entrances: state.layout.entrances,
  };
};

EntranceContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  getEntrances: PropTypes.func.isRequired,

  isLoading: PropTypes.bool,
  entrances: PropTypes.array.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        showToast,
        getEntrances,
      }
    )(EntranceContainer)
  )
);
