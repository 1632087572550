import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import CustomRichTextEditor from '../../common/richTextEditor/customRichTextEditor.component';
import { Value } from 'slate';
import { find } from 'lodash';
import { SAVE_NOTE_TEMPLATE_SUCCESS } from './noteTemplates.actions';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  gridItem: {
    marginBottom: '1rem'
  }
});

class NoteTemplateForm extends Component {
  constructor(props) {
    super(props);

    const noteTemplate = props.noteTemplate;
    const noteType = find(props.noteTypes, { id: noteTemplate ? noteTemplate.noteTypeId : '' });

    this.state = {
      providerGroupId: noteType ? noteType.providerGroupId : '',
      noteTypeId: noteTemplate ? noteTemplate.noteTypeId : '',
      name: noteTemplate ? noteTemplate.name : '',
      body: noteTemplate
        ? this.getInitialTextEditorValue(noteTemplate.body)
        : this.getInitialTextEditorValue(
            '{"object":"value","document":{"object":"document","data":{},"nodes":[{"object":"block","type":"paragraph","data":{},"nodes":[{"object":"text","leaves":[{"object":"leaf","text":"","marks":[]}]}]}]}}'
          ),
      enabled: noteTemplate ? noteTemplate.enabled : true,
      labelWidthProviderGroup: 0,
      labelWidthNoteType: 0
    };
  }

  componentDidMount() {
    this.setState({
      labelWidthProviderGroup: ReactDOM.findDOMNode(this.inputLabelRefProviderGroup).offsetWidth,
      labelWidthNoteType: ReactDOM.findDOMNode(this.inputLabelRefNoteType).offsetWidth
    });
  }

  getProviderGroupOptions = () => {
    return this.props.providerGroups
      .filter(providerGroup => (this.props.noteTemplate ? true : can(providerGroupPermissions.createNoteTemplate, providerGroup.id)))
      .map((providerGroup, i) => {
        return (
          <MenuItem value={providerGroup.id} key={i}>
            {providerGroup.name}
          </MenuItem>
        );
      });
  };

  getNoteTypeOptions = () => {
    return this.props.noteTypes
      .filter(noteType => noteType.providerGroupId === this.state.providerGroupId)
      .map((noteType, i) => {
        return (
          <MenuItem value={noteType.id} key={i}>
            {noteType.name}
          </MenuItem>
        );
      });
  };

  handleChange = name => event => {
    let updateObj = { [name]: event.target.value };

    if (name === 'providerGroupId') {
      if (updateObj.providerGroupId !== this.state.providerGroupId) {
        updateObj.noteTypeId = '';
      }
    }

    this.setState(updateObj);
  };

  // convert a json string into a useful rich text object
  getInitialTextEditorValue = body => {
    switch (typeof body) {
      case 'string':
        return Value.fromJSON(JSON.parse(body));

      case 'object':
        return Value.fromJSON(body);

      default:
        return body;
    }
  };

  handleChangeNoteBody = value => {
    this.setState({ body: value });
  };

  formHasErrors = () => {
    return this.state.providerGroupId === '' || this.state.noteTypeId === '' || this.state.name === '';
  };

  handleSaveNoteTemplate = () => {
    let noteTemplate = {
      noteTypeId: this.state.noteTypeId,
      name: this.state.name,
      body: JSON.stringify(this.state.body.toJSON()),
      enabled: this.state.enabled
    };

    this.props.handleSaveNoteTemplate(this.props.noteTemplate ? this.props.noteTemplate.id : null, noteTemplate);
  };

  handleToggleNoteTemplateEnable = async () => {
    let enabled = !this.state.enabled;

    let noteTemplate = {
      noteTypeId: this.props.noteTemplate.noteTypeId,
      name: this.props.noteTemplate.name,
      body: this.props.noteTemplate.body,
      enabled
    };

    let response = await this.props.handleToggleNoteTemplateEnable(this.props.noteTemplate.id, noteTemplate);
    if (response.type === SAVE_NOTE_TEMPLATE_SUCCESS) {
      this.setState({ enabled });
    }
  };

  handleDeleteNoteTemplate = () => {
    this.props.handleDeleteNoteTemplate(this.props.noteTemplate.id);
  };

  render() {
    let { classes, disabled } = this.props;
    const { providerGroupId, noteTypeId, name, body, labelWidthProviderGroup, labelWidthNoteType } = this.state;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl required variant="outlined" className={classes.formControl}>
            <InputLabel
              ref={ref => {
                this.inputLabelRefProviderGroup = ref;
              }}
              htmlFor="providerGroupId-placeholder"
            >
              Provider Group
            </InputLabel>
            <Select
              value={providerGroupId}
              onChange={this.handleChange('providerGroupId')}
              input={<OutlinedInput labelWidth={labelWidthProviderGroup} name="providerGroupId" id="providerGroupId-placeholder" />}
              disabled={this.props.noteTemplate !== undefined}
            >
              {this.getProviderGroupOptions()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} className={classes.gridItem} />
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl required variant="outlined" className={classes.formControl}>
            <InputLabel
              ref={ref => {
                this.inputLabelRefNoteType = ref;
              }}
              htmlFor="noteTypeId-placeholder"
            >
              Note Type
            </InputLabel>
            <Select
              value={noteTypeId}
              onChange={this.handleChange('noteTypeId')}
              input={<OutlinedInput labelWidth={labelWidthNoteType} name="noteTypeId" id="noteTypeId-placeholder" />}
            >
              {this.getNoteTypeOptions()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <FormControl className={classes.formControl} style={{ marginTop: '-1.1rem' }}>
            <TextField
              id="name"
              className={classes.textField}
              value={name}
              onChange={this.handleChange('name')}
              label={'Note Template Name'}
              margin="normal"
              variant="outlined"
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <CustomRichTextEditor
            initialValue={body}
            onChange={this.handleChangeNoteBody}
            templateVariables={{
              date: undefined,
              visitDate: undefined,
              providerName: undefined,
              entranceBrandName: undefined,
              patientAge: undefined,
              patientName: undefined,
              patientDob: undefined,
              patientGender: undefined,
              patientPossessivePronoun: undefined
            }}
          />
        </Grid>
        <Grid container item xs={12} justify={'flex-end'}>
          {this.props.noteTemplate && can(providerGroupPermissions.deleteNoteTemplate, this.props.noteTemplate.providerGroupId) && (
            <Button variant={'outlined'} onClick={this.handleDeleteNoteTemplate}>
              {'Delete'}
            </Button>
          )}
          {!disabled && (
            <Fragment>
              {this.props.noteTemplate && (
                <Button
                  variant={'outlined'}
                  onClick={this.handleToggleNoteTemplateEnable}
                  style={{ marginLeft: 'auto', marginRight: '0.5rem' }}
                >
                  {this.state.enabled ? 'Disable' : 'Enable'}
                </Button>
              )}
              <Button
                disabled={this.formHasErrors()}
                variant={'contained'}
                color={'primary'}
                style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
                onClick={this.handleSaveNoteTemplate}
              >
                {'Save'}
              </Button>
              <Button variant={'outlined'} onClick={this.props.handleCancel}>
                {'Cancel'}
              </Button>
            </Fragment>
          )}
        </Grid>
      </Grid>
    );
  }
}

NoteTemplateForm.propTypes = {
  classes: PropTypes.object.isRequired,
  noteTemplate: PropTypes.object,
  providerGroups: PropTypes.array.isRequired,
  noteTypes: PropTypes.array.isRequired,
  handleSaveNoteTemplate: PropTypes.func.isRequired,
  handleToggleNoteTemplateEnable: PropTypes.func.isRequired,
  handleDeleteNoteTemplate: PropTypes.func.isRequired,
  handleCancel: PropTypes.func
};

export default withStyles(styles)(NoteTemplateForm);
