import { GET_BOARD_ITEMS_REQUEST, GET_BOARD_ITEMS_SUCCESS, GET_BOARD_ITEMS_FAILURE } from './board.actions';
import produce from 'immer';

const initialState = {
  boardItems: [],
  isLoading: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_BOARD_ITEMS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_BOARD_ITEMS_SUCCESS:
      draft.isLoading = false;
      draft.boardItems = action.response.data;
      break;

    case GET_BOARD_ITEMS_FAILURE:
      draft.isLoading = false;
      break;

    default:
      break;
  }
}, initialState);
