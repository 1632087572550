import { Button, Paper, Typography } from '@material-ui/core';
import {
  CREATE_PRICING_GROUP_MEMBER_SUCCESS,
  DELETE_PRICING_GROUP_MEMBER_SUCCESS,
  SAVE_PRICING_GROUP_MEMBER_SUCCESS,
  createPricingGroupMember,
  deletePricingGroupMember,
  savePricingGroupMember,
} from './pricingGroupMembers.actions';
import { connect } from 'react-redux';
import { find, isNil } from 'lodash';
import { logEvent } from '../../utilities/googleAnalytics';
import { showToast } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CustomModal from '../../common/modal/customModal.component';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import PricingGroupMemberForm from './pricingGroupMemberForm.component';
import PricingGroupMemberUploadForm from './pricingGroupMemberUploadForm.component';
import PricingGroupMembersDataTable from './pricingGroupMembersDataTable.component';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

const styles = (theme) => ({
  container: {
    padding: '1rem',
  },
});

class PricingGroupContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowDetailModal: false,
      isShowUploadModal: false,
      selectedPricingGroupMember: null,
    };

    this.handleEditPricingGroupMember = this.handleEditPricingGroupMember.bind(this);
    this.handleSavePricingGroupMember = this.handleSavePricingGroupMember.bind(this);
    this.handleDeletePricingGroupMember = this.handleDeletePricingGroupMember.bind(this);
  }

  handleAddClick = () => {
    this.setState({
      isShowDetailModal: true,
    });
  };

  handleUploadClick = () => {
    logEvent('uploadlist', {
      adminId: this.props.user.email,
      pricingGroupId: this.props.pricingGroup.id,
    });

    this.setState({
      isShowUploadModal: true,
    });
  };

  handleCloseModals = () => {
    this.setState({
      selectedPricingGroupMember: null,
      isShowUploadModal: false,
      isShowDetailModal: false,
    });
  };

  handleCloseUploadModal = (isDenyUpload) => {
    if (isDenyUpload) {
      const { pricingGroupId } = this.props.match.params;
      logEvent('deny_upload', {
        adminId: this.props.user.email,
        pricingGroupId,
      });
    }

    this.setState({
      isShowUploadModal: false,
    });
  };

  handleEditPricingGroupMember(id) {
    const selectedPricingGroupMember = find(this.props.pricingGroupMembers, { id });

    this.setState({
      selectedPricingGroupMember,
      isShowDetailModal: true,
    });
  }

  async handleSavePricingGroupMember(id, pricingGroupMember) {
    if (id) {
      let response = await this.props.savePricingGroupMember(id, pricingGroupMember);
      if (response.type === SAVE_PRICING_GROUP_MEMBER_SUCCESS) {
        this.handleCloseModals();
      } else {
        this.props.showToast('Could not save the Pricing Group Member. Please check your connection and try again.');
      }
    } else {
      logEvent('add_memberPG', {
        adminId: this.props.user.email,
        pricingGroupId: this.props.pricingGroup.id,
      });
      pricingGroupMember.pricingGroupId = this.props.pricingGroup.id;
      let response = await this.props.createPricingGroupMember(pricingGroupMember);
      if (response.type === CREATE_PRICING_GROUP_MEMBER_SUCCESS) {
        this.handleCloseModals();
      } else {
        this.props.showToast('Could not add the Pricing Group Member. Please check your connection and try again.');
      }
    }
  }

  async handleDeletePricingGroupMember(id) {
    let response = await this.props.deletePricingGroupMember(id);
    if (response.type !== DELETE_PRICING_GROUP_MEMBER_SUCCESS) {
      this.props.showToast('Could not delete the Pricing Group. Please check your connection and try again.');
    }
    return response;
  }

  render() {
    const { classes, isSaving, pricingGroupMembers, disabled } = this.props;
    const { pricingGroupId } = this.props.match.params;

    const isEditPricingGroupMember = !isNil(this.state.selectedPricingGroupMember);

    return (
      <Paper className={classes.container}>
        <div style={{ display: 'flex' }}>
          <Typography style={{ flexGrow: 1 }}>GROUP MEMBERS ( {pricingGroupMembers ? pricingGroupMembers.length : 0} )</Typography>
          <Button
            style={{ marginRight: '1rem' }}
            onClick={(event) => this.handleAddClick()}
            color="primary"
            variant="outlined"
            disabled={disabled}
          >
            Add Member
          </Button>

          {pricingGroupId !== 'create' && (
            <Button onClick={(event) => this.handleUploadClick()} color="primary" variant="outlined"
              disabled={disabled}
            >
              Upload List
            </Button>
          )}
        </div>

        <div className={classnames([classes.container, 'page-padding-bottom'])}>
          {isSaving && <LoadingOverlay />}
          <PricingGroupMembersDataTable
            data={pricingGroupMembers}
            handleEditPricingGroupMember={this.handleEditPricingGroupMember}
            handleDeletePricingGroupMember={this.handleDeletePricingGroupMember}
            disabled={disabled}
          />

          {/* ADD/EDIT MEMBER MODAL */}
          <CustomModal open={this.state.isShowDetailModal} escapeKeyDown backdropClick
            handleClose={this.handleCloseModals}
          >
            {isSaving && <LoadingOverlay />}
            <Typography variant="subtitle1">{`${isEditPricingGroupMember ? 'Edit' : 'Add a'} Member`}</Typography>
            <PricingGroupMemberForm
              pricingGroupMember={this.state.selectedPricingGroupMember}
              handleSavePricingGroupMember={this.handleSavePricingGroupMember}
              handleCancel={this.handleCloseModals}
            />
          </CustomModal>

          {/* UPLOAD MEMBERS MODAL */}
          <CustomModal open={this.state.isShowUploadModal} escapeKeyDown backdropClick
            handleClose={this.handleCloseModals}
          >
            {isSaving && <LoadingOverlay />}
            <PricingGroupMemberUploadForm handleClose={this.handleCloseUploadModal} />
          </CustomModal>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSaving: state.pricingGroupMembers.isSaving,
    user: state.login.user,
  };
};

PricingGroupContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  pricingGroupMembers: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        createPricingGroupMember,
        savePricingGroupMember,
        deletePricingGroupMember,
        showToast,
      }
    )(PricingGroupContainer)
  )
);
