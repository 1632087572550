import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Typography } from '@material-ui/core';
import { filter } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import DataTableHead from './dataTableHead.component';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    backgroundColor: 'transparent !important',
  },
  tableContainer: {
    overflow: 'auto',
    flex: '1',
  },
});

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const getSorting = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

const filterData = (array, dataFilters) => {
  let filters = { ...dataFilters };

  //Strip out empty strings from the filter list before applying
  Object.keys(filters).forEach((key) => filters[key] === '' && delete filters[key]);

  return filter(array, (obj) => {
    let matchesFilter = true;
    Object.keys(filters).forEach((key) => {
      if (
        !obj[key] ||
        !obj[key]
          .toString() //Convert numbers/dates/etc to strings
          .toLowerCase()
          .includes(filters[key].toLowerCase())
      ) {
        matchesFilter = false;
      }
    });
    return matchesFilter;
  });
};

class DataTable extends Component {
  constructor(props) {
    super(props);

    let filters = {};
    props.columns &&
      props.columns.forEach((column) => {
        filters[column.id] = '';
      });

    this.state = {
      order: props.defaultSortOrder ? props.defaultSortOrder : 'desc',
      orderBy: props.defaultSortField ? props.defaultSortField : 'date',
      data: props.data && props.data.length > 0 ? props.data : [],
      page: 0,
      rowsPerPage: 10,
      filters,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
    });
  }

  onDataChanged = (data) => {
    if (this.props.onDataChanged) this.props.onDataChanged(this.state.data);
  };

  onPagingChanged = (data) => {
    if (this.props.onPagingChanged) this.props.onPagingChanged();
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleFilterChange = (name) => (event) => {
    let filters = this.state.filters;
    filters[name] = event.target.value;
    this.setState(filters);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
    this.onPagingChanged(this.state.data);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
    this.onPagingChanged(this.state.data);
  };

  render() {
    const { classes, columns, renderRow, isLoading, disableFilters } = this.props;
    const { order, orderBy, rowsPerPage, page, filters } = this.state;
    let { data } = this.state;

    let filteredData = filterData(data, filters);

    if (isLoading === true) {
      return <LoadingOverlay />;
    }

    return (
      <div className={classes.root}>
        <div className={classes.tableContainer}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <DataTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              columns={columns}
              onAddClick={this.props.onAddClick}
            />
            <TableBody>
              {/* FILTERS ROW */}
              {!disableFilters &&
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.filtering && (
                        <TextField
                          id={column.id}
                          value={this.state.filters[column.id]}
                          onChange={this.handleFilterChange(column.id)}
                          label={''}
                          autoComplete="new-password" //Needed to turn off autoComplete
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              }

              {data && data.length === 0 && (
                <TableRow>
                  <TableCell colSpan={this.props.onAddClick ? columns.length + 1 : columns.length}>
                    <Typography>{'No results available.'} </Typography>
                  </TableCell>
                </TableRow>
              )}

              {stableSort(filteredData, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, i) => renderRow(n, i))}
            </TableBody>
          </Table>
        </div>

        {data && data.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
      </div>
    );
  }
}

DataTable.defaultProps = {
  disableFilters: false,
};

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
  columns: PropTypes.array.isRequired,
  renderRow: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func, // callback when sort/page/filter is updated
  defaultSortOrder: PropTypes.string,
  defaultSortField: PropTypes.string,
  onAddClick: PropTypes.func, //callback when the add button is clicked
  disableFilters: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default withStyles(styles)(DataTable);
