import { CALL_API } from '../../middleware/api';

export const SAVE_ENTRANCE_REQUEST = 'SAVE_ENTRANCE_REQUEST';
export const SAVE_ENTRANCE_SUCCESS = 'SAVE_ENTRANCE_SUCCESS';
export const SAVE_ENTRANCE_FAILURE = 'SAVE_ENTRANCE_FAILURE';

export const saveEntrance = (entranceId, data) => {
  return {
    [CALL_API]: {
      types: [SAVE_ENTRANCE_REQUEST, SAVE_ENTRANCE_SUCCESS, SAVE_ENTRANCE_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/entrances/${entranceId}`,
      method: 'PUT',
      payload: data,
    },
  };
};