import { Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { getFacilityMemberships } from '../facilities.actions';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Colors from '../../../styles/colors';
import CustomModal from '../../../common/modal/customModal.component';
import FacilitatorSearchForm from './facilitatorSearchForm.component';
import FacilitatorsDataTable from './facilitatorsDataTable.component.js';
import FacilitatorsUploadForm from './facilitatorsUploadForm.component.js';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = theme => ({
  paperRoot: {
    width: '100%',
    padding: '2rem',
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  paperHeading: {
    display: 'flex',
  },
});

class FacilitatorsForm extends Component {
  state = {
    isShowAddMemberModal: false,
    isShowUploadMembersModal: false,
  };

  showAddMemberModal = () => {
    this.setState({
      isShowAddMemberModal: true,
    });
  };

  showUploadMembersModal = () => {
    this.setState({
      isShowUploadMembersModal: true,
    });
  };

  handleCloseModals = () => {
    this.setState({
      isShowUploadMembersModal: false,
      isShowAddMemberModal: false,
    });
  };

  closeModal = () => {
    this.setState({
      isShowAddMemberModal: false,
    });
  };

  onAddFacilityMember = async () => {
    this.closeModal();
    this.props.getFacilityMemberships(this.props.facilityDetail.id);
  };

  render() {
    const { classes, facilityMemberships, entranceId } = this.props;
    const { isShowAddMemberModal } = this.state;

    return (
      <>
        <Paper classes={{ root: classes.paperRoot }}>
          <div className={classes.paperHeading}>
            <Typography style={{ color: Colors.primary.darkgray, flex: 1 }} variant={'h6'}>
              Facilitators
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.showAddMemberModal}
              style={{ marginRight: '1rem' }}
            >
              Add Facilitator
            </Button>
            <Button variant="outlined" color="primary" onClick={this.showUploadMembersModal}>
              Upload List
            </Button>
          </div>
          <FacilitatorsDataTable data={facilityMemberships} />
        </Paper>
        <CustomModal escapeKeyDown open={isShowAddMemberModal} handleClose={this.closeModal}>
          <IconButton
            style={{ position: 'absolute', top: 8, right: 8 }}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.closeModal}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{ color: Colors.primary.darkgray }}>
            Add A Facilitator
          </Typography>
          <FacilitatorSearchForm entranceId={entranceId} onAddMember={this.onAddFacilityMember} />
          <Button
            variant={'outlined'}
            color="primary"
            onClick={this.closeModal}
            style={{ float: 'right' }}
          >
            {'CANCEL'}
          </Button>
        </CustomModal>
        {/* UPLOAD MEMBERS MODAL */}
        <CustomModal
          open={this.state.isShowUploadMembersModal}
          escapeKeyDown
          backdropClick
          handleClose={this.handleCloseModals}
        >
          <FacilitatorsUploadForm handleClose={this.handleCloseModals} />
        </CustomModal>
      </>
    );
  }
}

FacilitatorsForm.propTypes = {
  entranceId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  facilityMemberships: PropTypes.array,
  getFacilityMemberships: PropTypes.func.isRequired,
  facilityDetail: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    facilityMemberships: state.facilities.facilityMemberships,
    facilityDetail: state.facilities.facilityDetail,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getFacilityMemberships,
  })(FacilitatorsForm)
);
