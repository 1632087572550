import { connect } from 'react-redux';
import { setResponsiveBreakpoint } from './layout.actions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import responsiveBreakpoints from './responsiveBreakpoints';

class ResizeHandler extends Component {
  constructor(props) {
    super(props);

    this.handleResize = this.handleResize.bind(this);
  }
  componentDidMount() {
    // Create the event. (This method is necessary for IE support)
    let event = document.createEvent('Event');
    // Define that the event name is 'resize'.
    event.initEvent('resize', true, true);

    let resizeTimer;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(this.handleResize, 100);
    });

    window.dispatchEvent(event);
  }

  handleResize() {
    let width = window.innerWidth;
    let breakpoint;

    if (width < responsiveBreakpoints.SMALL.maxWidth) {
      breakpoint = responsiveBreakpoints.SMALL;
    } else if (width >= responsiveBreakpoints.SMALL.maxWidth && width < responsiveBreakpoints.MEDIUM.maxWidth) {
      breakpoint = responsiveBreakpoints.MEDIUM;
    } else if (width >= responsiveBreakpoints.MEDIUM.maxWidth) {
      breakpoint = responsiveBreakpoints.LARGE;
    }
    if (breakpoint !== this.props.responsiveBreakpoint) {
      this.props.setResponsiveBreakpoint(breakpoint);
    }
  }

  render() {
    return <div className="resizeHandler" />;
  }
}

ResizeHandler.propTypes = {
  responsiveBreakpoint: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    responsiveBreakpoint: state.layout.responsiveBreakpoint
  };
};

export default connect(
  mapStateToProps,
  {
    setResponsiveBreakpoint
  }
)(ResizeHandler);
