import { has } from 'lodash';
import auth from '../auth/auth';
import request from 'superagent';

export const uploadFiles = async (url, files, onComplete, onError, onProgress, onCancel) => {
  const jwt = auth.getAuthToken();
  const req = request
    .post(url)
    .set('Authorization', `Bearer ${jwt}`)
    .set('Accept', 'application/json');

  files.forEach((file) => {
    req.attach(file.name, file);
  });

  req
    .then((response) => {
      if (response && response.ok === true) {
        onComplete(response.body);
      } else {
        onError({ statusCode: response.statusCode, statusText: response.statusText });
      }
    })
    .catch((e) => {
      // catch 401 scenario and logout
      if (has(e, 'response.statusCode') && e.response.statusCode === 401) {
        auth.logout();
      } else {
        onError(e);
      }
    });
};

export default { uploadFiles };
