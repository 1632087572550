import React from 'react';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const DEFAULT_VALUE = 'caskjfDEFAULoiuhlkjb';

const styles = theme => ({
  outlinedInputRoot: {
    padding: '0.5rem 2rem 0.5rem 0.5rem'
  }
});

export const VariableSelect = ({ editor, memorize = false, classes, theme }) => {
  const variables = editor ? editor.getVariables() : [];

  const menuItems = variables.map(v => <MenuItem key={v} value={v}>{`{{${v}}}`}</MenuItem>);

  const handleChange = ({ target: { value } }) => {
    if (value === DEFAULT_VALUE) return;
    editor.insertVariable(value, { memorize });
    editor.moveToStartOfNextText().focus();
  };

  return (
    <Select
      value={DEFAULT_VALUE}
      onChange={handleChange}
      input={<OutlinedInput classes={{ input: classes.outlinedInputRoot }} name="variables" id="variables-dropdown" labelWidth={0} />}
    >
      <MenuItem key={DEFAULT_VALUE} value={DEFAULT_VALUE}>
        <Typography style={{ color: theme.palette.primary.darkgray }}>{'Insert Variable'}</Typography>
      </MenuItem>
      {menuItems}
    </Select>
  );
};

export default withStyles(styles, { withTheme: true })(VariableSelect);
