import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Colors from '../../styles/colors';

const PhotoSet = ({ classes, containerStyle, disabled, photos, onRemovePhoto }) => (
  <div className={classes.photoContainer} style={containerStyle}>
    <Grid container spacing={16} className={classes.photoGrid}>
      {photos.filter((item) => item.imageUrl).map((photo, i) => {
        return (
          <Grid key={photo.id} item className={classes.photoItem}>
            <IconButton
              className={classes.removeButton}
              disabled={disabled}
              onClick={() => onRemovePhoto(i)}
            >
              <Close className={classes.closeIcon} />
            </IconButton>
            <img className={classes.image} src={photo.imageUrl} alt="" />
          </Grid>
        );
      })}
    </Grid>
  </div>
);

PhotoSet.propTypes = {
  classes: PropTypes.object.isRequired,

  containerStyle: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  photos: PropTypes.array.isRequired,

  onRemovePhoto: PropTypes.func.isRequired,
};

PhotoSet.defaultProps = {
  containerStyle: {},
};

const styles = (/* theme */) => ({
  image: {
    objectFit: 'contain',
    maxHeight: 175,
  },
  removeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    padding: 4,
    backgroundColor: Colors.primary.lightgray,
    '&:hover': {
      backgroundColor: Colors.primary.lightgray,
    },
  },
  closeIcon: {
    height: 20,
    width: 20,
  },
  photoContainer: {
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  photoItem: {
    marginTop: '1rem',
    position: 'relative',
  },
  photoGrid: {
    marginBottom: '1rem',
  },
});

export default withStyles(styles)(PhotoSet);
