import {
  GET_PRICING_GROUPS_REQUEST,
  GET_PRICING_GROUPS_SUCCESS,
  GET_PRICING_GROUPS_FAILURE,
  CREATE_PRICING_GROUP_REQUEST,
  CREATE_PRICING_GROUP_SUCCESS,
  CREATE_PRICING_GROUP_FAILURE,
  SAVE_PRICING_GROUP_REQUEST,
  SAVE_PRICING_GROUP_SUCCESS,
  SAVE_PRICING_GROUP_FAILURE
} from './pricingGroups.actions';
import produce from 'immer';

const initialState = {
  pricingGroups: [],
  isLoading: false,
  isSaving: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_PRICING_GROUPS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_PRICING_GROUPS_SUCCESS:
      draft.isLoading = false;
      draft.pricingGroups = action.response.data;
      break;

    case GET_PRICING_GROUPS_FAILURE:
      draft.isLoading = false;
      break;

    case CREATE_PRICING_GROUP_REQUEST:
      draft.isSaving = true;
      break;

    case CREATE_PRICING_GROUP_SUCCESS: {
      draft.isSaving = false;
      break;
    }

    case CREATE_PRICING_GROUP_FAILURE:
      draft.isSaving = false;
      break;

    case SAVE_PRICING_GROUP_REQUEST:
      draft.isSaving = true;
      break;

    case SAVE_PRICING_GROUP_SUCCESS: {
      draft.isSaving = false;
      break;
    }

    case SAVE_PRICING_GROUP_FAILURE:
      draft.isSaving = false;
      break;

    default:
      break;
  }
}, initialState);
