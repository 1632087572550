import { connect } from 'react-redux';
import { has, isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';

import PatientSearchResults from './patientSearchResults.component';
import { patientSearch } from './patients.actions';

class PatientSearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      searchResults: [],
      tabIndex: 0,
    };

    this.searchTimer = null;
  }

  handleChange = name => event => this.setState({ [name]: event.target.value });

  handleSearch = async e => {
    e.preventDefault();

    if (!isEmpty(this.state.searchText)) {
      const result = await this.props.patientSearch(this.props.entranceId, this.state.searchText);
      if (has(result, 'response.data') && !isEmpty(result.response.data)) {
        this.setState({ searchResults: result.response.data });
      } else {
        this.setState({
          searchResults: [],
        });
      }
    } else {
      this.setState({
        searchResults: [],
      });
    }
  };

  handleEditAction = patient => {
    this.setState({ selectedPatient: patient });
  };

  render() {
    const { classes, entranceId, onEditPatient } = this.props;
    const { email, searchResults } = this.state;

    return (
      <form className={classes.formContainer} onSubmit={this.handleSearch}>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <div className={classes.searchContainer}>
              <TextField
                id="search"
                className={classes.textField}
                value={email}
                onChange={this.handleChange('searchText')}
                label="Search by Name"
                variant="standard"
                autoComplete="off"
              />
              <Button
                type="submit"
                color="primary"
                size="large"
                variant="contained"
                aria-label="Search"
                style={{ marginLeft: '1rem' }}
              >
                <SearchIcon />
                Search
              </Button>
            </div>
          </Grid>
          <Paper style={{ width: '100%' }}>
            <Grid item xs={12} style={{ overflow: 'auto' }}>
              <PatientSearchResults
                data={searchResults}
                entranceId={entranceId}
                onEditAction={onEditPatient}
              />
            </Grid>
          </Paper>
        </Grid>
      </form>
    );
  }
}

const styles = theme => ({
  formContainer: {
    paddingBottom: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
  },
  searchContainer: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing.unit * 2,
  },
  textField: {
    width: '100%',
  },
});

PatientSearchForm.propTypes = {
  classes: PropTypes.object.isRequired,

  entranceId: PropTypes.string.isRequired,

  patientSearch: PropTypes.func.isRequired,
  onEditPatient: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(null, {
    patientSearch,
  })
)(PatientSearchForm);
