import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Button from '@material-ui/core/Button';

const COLUMN_HEADER_FONT_SIZE = '1rem';

class DataTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, columns } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columns.map(
            column => (
              <TableCell
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.sortable ? (
                  <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                      style={{ fontSize: COLUMN_HEADER_FONT_SIZE }}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  <div style={{ fontSize: COLUMN_HEADER_FONT_SIZE }}>{column.label}</div>
                )}
              </TableCell>
            ),
            this
          )}
          <TableCell key={'add'} align={'right'} padding={'default'}>
            {this.props.onAddClick && (
              <Button variant="contained" color="primary" onClick={this.props.onAddClick}>
                {'Add'}
              </Button>
            )}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

DataTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  onAddClick: PropTypes.func
};

export default DataTableHead;
