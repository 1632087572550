import {
  HIDE_TOAST_MESSAGE,
  SET_DIRTY_FORM,
  SET_PAGE_TITLE,
  SET_RESPONSIVE_BREAKPOINT,
  SET_TOAST_POSITION,
  SHOW_TOAST_MESSAGE,
  TOGGLE_DRAWER_MENU,
  GET_PROVIDER_GROUPS_REQUEST,
  GET_PROVIDER_GROUPS_SUCCESS,
  GET_PROVIDER_GROUPS_FAILURE,
  GET_ENTRANCES_REQUEST,
  GET_ENTRANCES_SUCCESS,
  GET_ENTRANCES_FAILURE,
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE
} from './layout.actions';
import produce from 'immer';
import responsiveBreakpoints from './responsiveBreakpoints';

const initialState = {
  pageTitle: 'Home',
  responsiveBreakpoint: responsiveBreakpoints.LARGE,

  isShowToast: false,
  toastMessage: '',
  isToastFailure: false,
  toastPosition: '0',
  isDrawerMenuOpen: false,
  hasDirtyForm: false,

  providerGroups: [],
  isProviderGroupsLoading: false,
  entrances: [],
  isEntrancesLoading: false,
  accounts: [],
  isAccountsLoading: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_TITLE:
      console.log('page title action', action);
      draft.pageTitle = action.payload;
      draft.breadcrumb = action.breadcrumb;
      draft.breadcrumbTitle = action.breadcrumbTitle;
      break;

    case SET_RESPONSIVE_BREAKPOINT:
      draft.responsiveBreakpoint = action.payload;
      break;

    case SHOW_TOAST_MESSAGE:
      draft.isShowToast = true;
      draft.toastMessage = action.message;
      draft.isToastFailure = action.isFailure;
      break;

    case HIDE_TOAST_MESSAGE:
      draft.isShowToast = false;
      break;

    case SET_TOAST_POSITION:
      draft.toastPosition = action.position;
      break;

    case TOGGLE_DRAWER_MENU:
      draft.isDrawerMenuOpen = !draft.isDrawerMenuOpen;
      break;

    case SET_DIRTY_FORM:
      draft.hasDirtyForm = action.payload.flag;
      break;

    case GET_PROVIDER_GROUPS_REQUEST:
      draft.isProviderGroupsLoading = true;
      break;

    case GET_PROVIDER_GROUPS_SUCCESS:
      draft.isProviderGroupsLoading = false;
      draft.providerGroups = action.response.data;
      break;

    case GET_PROVIDER_GROUPS_FAILURE:
      draft.isProviderGroupsLoading = false;
      break;

    case GET_ENTRANCES_REQUEST:
      draft.isEntrancesLoading = true;
      break;

    case GET_ENTRANCES_SUCCESS:
      draft.isEntrancesLoading = false;
      draft.entrances = action.response.data;
      // draft.entrances[0].marketingContent = [
      //   {
      //     imageUrl: 'http://placehold.it/400x190?text=Google',
      //     url: 'https://www.google.com',
      //     altText: 'Testing image',
      //     name: 'Google Image'
      //   },
      //   {
      //     imageUrl: 'http://placehold.it/400x190?text=Yahoo',
      //     url: null,
      //     altText: 'Testing image',
      //     name: 'Yahoo Image'
      //   },
      //   {
      //     imageUrl: 'http://placehold.it/400x190?text=Apple',
      //     url: 'https://www.apple.com',
      //     altText: 'Testing image',
      //     name: 'Apple Image'
      //   }
      // ];
      break;

    case GET_ENTRANCES_FAILURE:
      draft.isEntrancesLoading = false;
      break;

    case GET_ACCOUNTS_REQUEST:
      draft.isAccountsLoading = true;
      break;

    case GET_ACCOUNTS_SUCCESS:
      draft.isAccountsLoading = false;
      draft.accounts = action.response.data;
      break;

    case GET_ACCOUNTS_FAILURE:
      draft.isAccountsLoading = false;
      break;

    default:
      break;
  }
}, initialState);
