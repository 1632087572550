import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { lessThan12YearsAgo } from '../../utilities/dataUtils';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';
import { genderOptions } from '../../utilities/genderUtils';
import PhoneNumberMask from '../../common/inputs/phoneNumberMask.component';

const PatientPersonalInfoForm = ({ entranceId, isLoading, patient, onChange }) => {
  const genderLabelRef = useRef();
  const [genderLabelWidth, setGenderLabelWidth] = useState(0);
  const hasDob = patient.dob;
  let showWeightField = false;

  if (hasDob) {
    const lessThan12YearsOld =
      lessThan12YearsAgo('MM/DD/YYYY')(moment(patient.dob).format('MM/DD/YYYY')) === true;

    if (lessThan12YearsOld) {
      showWeightField = true;
    }
  }

  const hasReadPermission = can(entrancePermissions.readPatientDemographics, entranceId);
  const hasEditPermission = can(entrancePermissions.updatePatientDemographics, entranceId);

  useEffect(() => {
    if (genderLabelRef.current && genderLabelWidth === 0) {
      setGenderLabelWidth(ReactDOM.findDOMNode(genderLabelRef.current).offsetWidth)
    }
  }, [genderLabelRef.current, genderLabelWidth]);


  return hasReadPermission ? (
    <>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={isLoading || !hasEditPermission}
            fullWidth
            label="First Name"
            margin="normal"
            name="firstName"
            value={patient.firstName || ''}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={isLoading || !hasEditPermission}
            fullWidth
            label="Last Name"
            margin="normal"
            name="lastName"
            value={patient.lastName || ''}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={showWeightField ? 4 : 6}>
          <TextField
            disabled={isLoading || !hasEditPermission}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label="Date of Birth"
            margin="normal"
            name="dob"
            style={showWeightField ? { marginTop: 0 } : {}}
            type="date"
            value={patient.dob || ''}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={showWeightField ? 4 : 6}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel
              ref={ref => {
                genderLabelRef.current = ref;
              }}
              htmlFor="gender-placeholder"
            >
              Gender
            </InputLabel>
            <Select
              input={
                <OutlinedInput
                  disabled={isLoading || !hasEditPermission}
                  labelWidth={genderLabelWidth}
                  name="patientGender"
                  id="gender-placeholder"
                />
              }
              value={patient.gender || ''}
              onChange={onChange}
            >
              {genderOptions.map(gender => {
                return (
                  <MenuItem value={gender.value} key={gender.value}>
                    {gender.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {showWeightField && (
          <Grid item xs={12} sm={4}>
            <TextField
              disabled={isLoading || !hasEditPermission}
              fullWidth
              label="Weight (lbs.)"
              margin="normal"
              name="weight"
              style={showWeightField ? { marginTop: 0 } : {}}
              type="number"
              value={patient.weight || ''}
              variant="outlined"
              onChange={onChange}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={isLoading || !hasEditPermission}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label="Employer Code"
            margin="normal"
            name="groupCode"
            value={patient.groupCode}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={isLoading || !hasEditPermission}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label="Group Identifier"
            margin="normal"
            name="groupIdentifier"
            readOnly
            value={patient.groupIdentifier}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={isLoading || !hasEditPermission}
            fullWidth
            label="Address Line 1"
            margin="normal"
            name="addressLine1"
            value={patient.addressLine1 || ''}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={isLoading || !hasEditPermission}
            fullWidth
            label="Address Line 2"
            margin="normal"
            name="addressLine2"
            value={patient.addressLine2 || ''}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={4}>
          <TextField
            disabled={isLoading || !hasEditPermission}
            fullWidth
            label="City"
            margin="normal"
            name="addressCity"
            value={patient.addressCity || ''}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            disabled={isLoading || !hasEditPermission}
            fullWidth
            label="State"
            margin="normal"
            name="addressState"
            value={patient.addressState || ''}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            disabled={isLoading || !hasEditPermission}
            fullWidth
            label="Zip Code"
            margin="normal"
            name="addressZip"
            value={patient.addressZip || ''}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={16}>
        <Grid item xs={12} sm={3}>
          <TextField
            disabled={isLoading || !hasEditPermission}
            fullWidth
            label="Email Address"
            margin="normal"
            name="emailAddress"
            value={patient.emailAddress || ''}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            InputProps={{
              inputComponent: PhoneNumberMask,
              name: 'mobilePhone',
              onChange,
            }}
            name="mobilePhone"
            label="Mobile Phone"
            margin="normal"
            value={patient.mobilePhone || ''}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            InputProps={{
              inputComponent: PhoneNumberMask,
              onChange,
            }}
            name="homePhone"
            label="Home Phone"
            margin="normal"
            value={patient.homePhone || ''}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            InputProps={{
              inputComponent: PhoneNumberMask,
              onChange,
            }}
            name="workPhone"
            label="Work Phone"
            margin="normal"
            value={patient.workPhone || ''}
            variant="outlined"
            onChange={onChange}
          />
        </Grid>

      </Grid>
    </>
  ) : (
    <Typography style={{ marginBottom: '1rem', marginTop: '1rem' }} variant="body1">
      You do not have permission to view a patient&apos;s demographics.
    </Typography>
  );
};

PatientPersonalInfoForm.propTypes = {
  entranceId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  patient: PropTypes.object,

  onChange: PropTypes.func.isRequired,
};

PatientPersonalInfoForm.defaultProps = {
  patient: null,
};

export default PatientPersonalInfoForm;
