import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CouponsDataTable from './couponsDataTable.component';
import classnames from 'classnames';
import { getCoupons } from './coupons.actions';
import { getPricingGroups } from '../pricing/pricingGroups.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { FormControlLabel, Switch } from '@material-ui/core';

const styles = theme => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 5rem)'
  }
});

class CouponsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      currentRow: null,
      toggleAll: false
    };

    this.handleToggleAll = this.handleToggleAll.bind(this);
  }

  componentWillMount() {
    this.props.setPageTitle('Coupons');
    this.props.getCoupons();
    this.props.getPricingGroups();
  }

  handleToggleAll = () => {
    this.setState({ toggleAll: !this.state.toggleAll });
  };

  render() {
    const { classes, isLoading, coupons, entrances, accounts, pricingGroups } = this.props;

    if (isLoading === true) {
      return <LoadingOverlay />;
    }

    let filteredCoupons = this.state.toggleAll ? coupons : coupons.filter(c => c.status !== 'deleted');

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormControlLabel control={<Switch checked={this.state.toggleAll} onChange={this.handleToggleAll} />} label="Show Deleted" />
        </div>
        <CouponsDataTable
          data={filteredCoupons}
          entrances={entrances}
          accounts={accounts}
          pricingGroups={pricingGroups}
          history={this.props.history}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    coupons: state.coupons.coupons,
    pricingGroups: state.pricingGroups.pricingGroups,
    isLoading: state.coupons.isLoading || state.pricingGroups.isLoading,
    entrances: state.layout.entrances,
    accounts: state.layout.accounts
  };
};

CouponsContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        getCoupons,
        getPricingGroups
      }
    )(CouponsContainer)
  )
);
