import { BASE_URL } from '../../middleware/api';
import { Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@material-ui/core';
import { can } from '../login/can';
import { connect } from 'react-redux';
import { entrancePermissions } from '../../types/permissionTypes';
import { isEmpty, isNil } from 'lodash';
import { showToast } from '../layout/layout.actions';
import { uploadFiles } from '../../utilities/upload';
import { withStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import NoteAdd from '@material-ui/icons/NoteAdd';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import alertTypes from '../../types/alertTypes';
import { MARKETING_CONTENT_CATEGORIES } from '../../types/marketingContent';
import { getMarketingContentOptions, MARKETING_CONTENT_OPTIONS, getContentCategory } from '../../utilities/marketingContentUtils';

const MAX_FILE_UPLOAD_SIZE = 10000000;
const IMAGE_UPLOAD_URL = `${BASE_URL}v1/admin/entrances/ent_1/marketingUpload`;

class MarketingContentForm extends Component {
  constructor(props) {
    super(props);

    const content = props.content;

    this.state = {
      altText: content ? content.altText : '',
      brandColor: content ? content.color : '',
      bannerColor: content ? content.bannerColor : '',
      bannerText: content ? content.bannerText : '',
      contentType: content ? content.contentType : '',
      id: content ? content.id : '',
      imageUrl: content ? content.imageUrl : '',
      isUploading: false,
      labelWidthEntrance: 0,
      name: content ? content.name : '',
      url: content ? content.url : '',
    };
  }

  componentDidMount() {
    this.setState({
      labelWidthEntrance: ReactDOM.findDOMNode(this.inputLabelRefEntrance).offsetWidth,
    });
  }

  renderSelectOptions = () => {
    if (!isEmpty(this.props.entrance)) {
      return getMarketingContentOptions(this.props.entrance.marketingContent, this.state.contentType).map(option => (
        <MenuItem value={option.value} key={option.value}>
          {option.label}
        </MenuItem>
      ));
    }

    return [];
  };

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  handleContentTypeChange = event => {
    const { name, value } = event.target;

    const contentType = MARKETING_CONTENT_OPTIONS.find(option => option.value === value);

    this.setState({ [name]: contentType.value });
  };

  formHasErrors = () => {
    const { bannerText, bannerColor, brandColor, contentType, name, imageUrl, altText } = this.state;

    const isBannerContentCategory = getContentCategory(contentType) === MARKETING_CONTENT_CATEGORIES.TEXT;
    const isColorContentCategory = getContentCategory(contentType) === MARKETING_CONTENT_CATEGORIES.COLORS;

    if (isBannerContentCategory) {
      return bannerText === '' || bannerColor === '';
    } else if (isColorContentCategory) {
      return brandColor === '';
    }

    return contentType === '' || name === '' || imageUrl === '' || altText === '';
  };

  handleSaveContent = () => {
    const { id, brandColor, bannerText, bannerColor, contentType, name, imageUrl, altText, url } = this.state;
    let content = {};

    const isBannerContentCategory = getContentCategory(contentType) === MARKETING_CONTENT_CATEGORIES.TEXT;
    const isColorContentCategory = getContentCategory(contentType) === MARKETING_CONTENT_CATEGORIES.COLORS;

    if (isBannerContentCategory) {
      content = {
        id: !isNil(this.props.content) ? id : Date.now(),
        bannerColor,
        bannerText,
        contentType,
      };
    } else if (isColorContentCategory) {
      content = {
        id: !isNil(this.props.content) ? id : Date.now(),
        color: brandColor,
        contentType,
      };
    } else {
      content = {
        id: !isNil(this.props.content) ? id : Date.now(),
        altText,
        contentType,
        imageUrl,
        name,
        url,
      };
    }

    this.props.handleSaveContent(content, this.props.content);
  };

  handleDeleteContent = () => {
    if (window.confirm('Are you sure you want to delete this content')) {
      this.props.handleDeleteContent(this.props.content);
    }
  };

  handleFileDrop = (acceptedFiles, rejectedFiles) => {
    if (!isEmpty(rejectedFiles)) {
      this.props.showToast('Please select an image file');
    } else if (!isEmpty(acceptedFiles)) {
      this.handleFileUpload(IMAGE_UPLOAD_URL, acceptedFiles);
    }
  };

  handleFileUpload = (url, acceptedFiles) => {
    this.setState({
      isUploading: true,
    });
    uploadFiles(
      url,
      acceptedFiles,
      response => {
        const { url } = response.data[0];
        this.setState({
          isUploading: false,
          imageUrl: url,
        });
      },
      err => {
        this.props.showToast('There was a problem uploading the file. Please try again.');
        this.setState({
          isUploading: false,
        });
      }
    );
  };

  render() {
    const { classes, disabled, entrance } = this.props;
    const { contentType, name, altText, url, labelWidthEntrance, isUploading, imageUrl, bannerText, bannerColor, brandColor } = this.state;

    const isBannerContentCategory = getContentCategory(contentType) === MARKETING_CONTENT_CATEGORIES.TEXT;
    const isColorContentCategory = getContentCategory(contentType) === MARKETING_CONTENT_CATEGORIES.COLORS;

    if (isUploading) return <LoadingOverlay />;

    return (
      <Fragment>
        <Typography variant="h5">Edit Marketing Content</Typography>
        <Grid container spacing={16}>
          <Grid container item xs={12} spacing={16} className={classes.formContainer}>
            <Grid item xs={12}>
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.inputLabelRefEntrance = ref;
                  }}
                  htmlFor="contentType-placeholder"
                >
                  Content Type
                </InputLabel>
                <Select
                  input={<OutlinedInput labelWidth={labelWidthEntrance} name="contentType" id="contentType-placeholder" />}
                  name="contentType"
                  value={contentType}
                  onChange={this.handleContentTypeChange}
                >
                  {this.renderSelectOptions()}
                </Select>
              </FormControl>
            </Grid>
            {isBannerContentCategory && (
              <>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    disabled={disabled}
                    label="Banner Text"
                    margin="normal"
                    name="bannerText"
                    required
                    value={bannerText}
                    variant="outlined"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl required variant="outlined" className={classes.formControl} style={{ marginTop: '1rem' }}>
                    <InputLabel
                      ref={ref => {
                        this.inputLabelRefEntrance = ref;
                      }}
                      htmlFor="bannerColor-placeholder"
                    >
                      Banner Color
                    </InputLabel>
                    <Select
                      disabled={this.props.coupon !== undefined || disabled}
                      input={<OutlinedInput labelWidth={labelWidthEntrance} name="bannerColor" id="bannerColor-placeholder" />}
                      name="bannerColor"
                      value={bannerColor}
                      onChange={this.handleChange}
                    >
                      {this.renderSelectOptions(alertTypes)}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            {isColorContentCategory && (
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  disabled={disabled}
                  label="Hex Color"
                  margin="normal"
                  name="brandColor"
                  required
                  value={brandColor}
                  variant="outlined"
                  onChange={this.handleChange}
                />
              </Grid>
            )}
            {!isBannerContentCategory && !isColorContentCategory && (
              <>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    disabled={disabled}
                    label="Content Name"
                    margin="normal"
                    name="name"
                    required
                    value={name}
                    variant="outlined"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    disabled={disabled}
                    label="Alt Text"
                    margin="normal"
                    name="altText"
                    required
                    value={altText}
                    variant="outlined"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    disabled={disabled}
                    label="Link URL"
                    margin="normal"
                    name="url"
                    value={url}
                    variant="outlined"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} className={classes.dropzoneContainer}>
                  <NoteAdd className={classes.dropzoneIcon} />
                  <Dropzone
                    accept="image/jpeg,image/png"
                    className="no-select"
                    maxSize={MAX_FILE_UPLOAD_SIZE}
                    multiple={false}
                    onDrop={this.handleFileDrop}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} style={{ outline: 'none', width: '100%' }}>
                        <input {...getInputProps()} />
                        {isEmpty(imageUrl) && (
                          <Typography className={classes.dropzone}>
                            Drop image file here, or click to select image file to upload.
                          </Typography>
                        )}
                        {!isEmpty(imageUrl) && (
                          <Fragment>
                            <Typography className={classes.dropzone}>{imageUrl}</Typography>
                          </Fragment>
                        )}
                      </div>
                    )}
                  </Dropzone>
                </Grid>
                {!isEmpty(imageUrl) && (
                  <Grid item xs={6}>
                    <img src={imageUrl} alt="" style={{ width: '100%', objectFit: 'contain' }} />
                  </Grid>
                )}
              </>
            )}
            {/* FORM ACTIONS */}
            <Grid container item xs={12} justify="flex-end">
              {this.props.content && (
                <Button
                  disabled={!can(entrancePermissions.deleteMarketingContent, entrance.id)}
                  variant="outlined"
                  onClick={this.handleDeleteContent}
                >
                  Delete
                </Button>
              )}
              {!disabled && (
                <Fragment>
                  <Button
                    disabled={this.formHasErrors()}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
                    onClick={this.handleSaveContent}
                  >
                    Save
                  </Button>
                  <Button variant="outlined" onClick={this.props.handleCancel}>
                    Cancel
                  </Button>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const styles = theme => ({
  formContainer: {
    margin: '1rem auto',
    maxWidth: '800px',
  },
  formControl: {
    width: '100%',
  },
  dropzoneContainer: {
    display: 'flex',
    margin: 'auto',
    marginTop: '1rem',
  },
  dropzone: {
    borderColor: theme.palette.primary.darkgray,
    borderStyle: 'dashed',
    borderRadius: '0.5rem',
    borderWidth: 2,
    cursor: 'pointer',
    padding: '1rem',
    color: theme.palette.primary.darkgray,
    fontSize: '1.2rem',
  },
  dropzoneIcon: {
    fontSize: 42,
    color: theme.palette.primary.main,
    alignSelf: 'center',
  },
  textField: {
    marginBottom: 0,
    width: '100%',
  },
});

MarketingContentForm.propTypes = {
  classes: PropTypes.object.isRequired,

  content: PropTypes.object,
  disabled: PropTypes.bool,
  entrance: PropTypes.object,

  handleCancel: PropTypes.func,
  handleDeleteContent: PropTypes.func,
  handleSaveContent: PropTypes.func.isRequired,
};

MarketingContentForm.defaultProps = {
  content: null,
  entrance: {},
};

const mapStateToProps = (/* state */) => {
  return {};
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, {
    showToast,
  })(MarketingContentForm)
);
