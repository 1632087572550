import { MARKETING_CONTENT_TYPES, MARKETING_CONTENT_CATEGORIES } from '../types/marketingContent';

const {
  BRAND_ERROR_COLOR,
  BRAND_PRIMARY_COLOR,
  BRAND_PRIMARY_CONTRAST_COLOR,
  BRAND_PRIMARY_DARK_COLOR,
  BRAND_SECONDARY_COLOR,
  BRAND_SUCCESS_COLOR,
  BRAND_WARNING_COLOR,
  DESKTOP_GREY_LOGO,
  DESKTOP_HOME,
  DESKTOP_LOGIN,
  DESKTOP_VISIT_LEGAL,
  DESKTOP_WAITING_ROOM,
  DESKTOP_WHITE_LOGO,
  HOMEPAGE_BANNER,
  LOGIN_BANNER,
  MOBILE_HOME,
  MOBILE_LOGIN,
  MOBILE_TOPBAR_LOGO,
  MOBILE_VISIT_LEGAL,
  MOBILE_WAITING_ROOM,
  MOBILE_WELCOME,
} = MARKETING_CONTENT_TYPES;

export const MARKETING_CONTENT_OPTIONS = [
  { value: BRAND_ERROR_COLOR, label: 'Brand Error Color' },
  { value: BRAND_PRIMARY_COLOR, label: 'Brand Primary Color' },
  { value: BRAND_PRIMARY_CONTRAST_COLOR, label: 'Brand Primary Contrast Color' },
  { value: BRAND_PRIMARY_DARK_COLOR, label: 'Brand Primary Dark Color' },
  { value: BRAND_SECONDARY_COLOR, label: 'Brand Secondary Color' },
  { value: BRAND_SUCCESS_COLOR, label: 'Brand Success Color' },
  { value: BRAND_WARNING_COLOR, label: 'Brand Warning Color' },
  { value: DESKTOP_GREY_LOGO, label: 'Desktop Grey Entrance Logo' },
  { value: DESKTOP_HOME, label: 'Desktop Carousel' },
  { value: DESKTOP_LOGIN, label: 'Desktop Login Banner' },
  { value: DESKTOP_VISIT_LEGAL, label: 'Desktop Legal Banner' },
  { value: DESKTOP_WAITING_ROOM, label: 'Desktop Waiting Room Carousel' },
  { value: DESKTOP_WHITE_LOGO, label: 'Desktop White Entrance Logo' },
  { value: HOMEPAGE_BANNER, label: 'App Banner Text' },
  { value: LOGIN_BANNER, label: 'Welcome Banner Text' },
  { value: MOBILE_HOME, label: 'Mobile Patient List Banner' },
  { value: MOBILE_LOGIN, label: 'Mobile Login Banner' },
  { value: MOBILE_TOPBAR_LOGO, label: 'Mobile Topbar Logo' },
  { value: MOBILE_VISIT_LEGAL, label: 'Mobile Legal Banner' },
  { value: MOBILE_WAITING_ROOM, label: 'Mobile Waiting Room Carousel' },
  { value: MOBILE_WELCOME, label: 'Mobile Welcome Banner' },
];

export const MARKETING_CONTENT_MULTI_USE = [
  DESKTOP_HOME,
  DESKTOP_WAITING_ROOM,
  MOBILE_WAITING_ROOM,
];

/*
  If marketing option is able to be used more than once or is the currently selected item, leave it as an option.
  If the option already exists with a value, remove it as an option
*/
export const getMarketingContentOptions = (marketingContent, selectedType) => {
  return MARKETING_CONTENT_OPTIONS.filter(option => {
    const isMultiUse =
      MARKETING_CONTENT_MULTI_USE.findIndex(multiOption => option.value === multiOption) !== -1;

    if (isMultiUse || selectedType === option.value) return true;

    return (
      !marketingContent ||
      !marketingContent[option.value] ||
      (marketingContent[option.value] && marketingContent[option.value].length === 0)
    );
  });
};

export const getContentCategory = contentType => {
  switch (contentType) {
    case HOMEPAGE_BANNER:
    case LOGIN_BANNER:
      return MARKETING_CONTENT_CATEGORIES.TEXT;

    case BRAND_ERROR_COLOR:
    case BRAND_PRIMARY_COLOR:
    case BRAND_PRIMARY_CONTRAST_COLOR:
    case BRAND_PRIMARY_DARK_COLOR:
    case BRAND_SECONDARY_COLOR:
    case BRAND_SUCCESS_COLOR:
    case BRAND_WARNING_COLOR:
      return MARKETING_CONTENT_CATEGORIES.COLORS;

    default:
      return MARKETING_CONTENT_CATEGORIES.IMAGES;
  }
};
