import { RELATIONSHIP_TYPES } from '../types/relationshipTypes';

export const getRelationshipOptions = () => {
  return Object.keys(RELATIONSHIP_TYPES).map(key => {
    const { label, value } = RELATIONSHIP_TYPES[key];
    return {
      label,
      value,
    };
  });
};

export const formatInsuranceAttachments = patient => {
  const insuranceIdKeys = [
    'insuranceCardImageUploadId',
    'insuranceCardImageUpload2Id',
    'insuranceCardImageUpload3Id',
    'insuranceCardImageUpload4Id',
  ];
  const toReturn = [];

  insuranceIdKeys.forEach((key, i) => {
    if (i === 0 && patient[key]) {
      toReturn.push({
        thumbnailUrl: patient.insurance.cardImageThumbnailUrl,
        id: patient.insurance.cardImageUploadId,
        url: patient.insurance.cardImageUrl,
      });
    } else if (patient[key]) {
      toReturn.push({
        thumbnailUrl: patient.insurance[`cardImage${i + 1}ThumbnailUrl`],
        id: patient.insurance[`cardImageUpload${i + 1}Id`],
        url: patient.insurance[`cardImage${i + 1}Url`],
      });
    }
  });

  return toReturn;
};
