export const entrancePermissions = {
  readBoard: 'read:board', // View an entrance board ( Admin App )
  readBasePrice: 'read:base_price', // View an entrance base price ( Admin App )
  updateBasePrice: 'update:base_price', // Edit an entrance base price ( Admin App )
  createPricingGroup: 'create:pricing_group', // Create an entrance pricing group ( Admin App )
  readPricingGroup: 'read:pricing_group', // View an entrance pricing group ( Admin App )
  updatePricingGroup: 'update:pricing_group', // Edit an entrance pricing group ( Admin App )
  deletePricingGroup: 'delete:pricing_group', // Delete an entrance pricing group ( Admin App )
  createCoupon: 'create:coupon', // Add an entrance coupon ( Admin App )
  readCoupon: 'read:coupon', // View an entrance coupon ( Admin App )
  updateCoupon: 'update:coupon', // Edit an entrance coupon ( Admin App )
  deleteCoupon: 'delete:coupon', // Edit an entrance coupon ( Admin App )
  createLegalDocument: 'create:legal_document', // Add an entrance legal document ( Admin App )
  readLegalDocument: 'read:legal_document', // View an entrance legal document ( Admin App )
  updateLegalDocument: 'update:legal_document', // Edit an entrance legal document ( Admin App )
  deleteLegalDocument: 'delete:legal_document', // Delete an entrance legal document ( Admin App )
  readPatientDemographics: 'read:patient_demographics', // View an entrance patient demographics ( Admin App )
  updatePatientDemographics: 'update:patient_demographics', // Edit an entrance patient demographics ( Admin App )
  readPatientInsurance: 'read:insurance', // View an entrance patient insurance ( Admin App )
  updatePatientInsurance: 'update:insurance', // Edit an entrance patient insurance ( Admin App )
  readPatientPharmacy: 'read:pharmacy', // View an entrance patient pharmacy ( Admin App )
  updatePatientPharmacy: 'update:pharmacy', // Edit an entrance patient pharmacy ( Admin App )
  readSuggestedPaymentChange: 'read:suggested_payment_change', // View an entrance suggested payment change ( Admin App )
  updateSuggestedPaymentChange: 'update:suggested_payment_change', // Edit an entrance suggested payment change ( Admin App )
  createMarketingContent: 'create:marketing_content', // Create entrance marketing content ( Admin App )
  readMarketingContent: 'read:marketing_content', // Read entrance marketing content ( Admin App )
  updateMarketingContent: 'update:marketing_content', // Update entrance marketing content ( Admin App )
  deleteMarketingContent: 'delete:marketing_content', // Delete entrance marketing content ( Admin App )
  createOutageBanner: 'create:outage_banner', // Create entrance outage banner ( Admin App )
  readOutageBanner: 'read:outage_banner', // Read entrance outage banner ( Admin App )
  updateOutageBanner: 'update:outage_banner', // Update entrance outage banner ( Admin App )
  deleteOutageBanner: 'delete:outage_banner', // Delete entrance outage banner ( Admin App )
  readEntranceSettings: 'read:entrance_settings', // Read entrance settings ( Admin App )
  updateEntranceSettings: 'update:entrance_settings', // Update entrance settings ( Admin App )
  createFacility: 'create:facility', // Create entrance facility ( Admin App )
  readFacility: 'read:facility', // Read entrance facility ( Admin App )
  updateFacility: 'update:facility', // Update entrance facility ( Admin App )
  deleteFacility: 'delete:facility', // Delete entrance facility ( Admin App )
};

export const providerGroupPermissions = {
  createNoteType: 'create:note_type', // Add a provider group note type ( Admin App )
  readNoteType: 'read:note_type', // View a provider group note type ( Admin App )
  updateNoteType: 'update:note_type', // Edit a provider group note type ( Admin App )
  deleteNoteType: 'delete:note_type', // Delete a provider group note type ( Admin App )
  createNoteTemplate: 'create:note_template', // Add a provider group note template ( Admin App )
  readNoteTemplate: 'read:note_template', // View a provider group note template ( Admin App )
  updateNoteTemplate: 'update:note_template', // Edit a provider group note template ( Admin App )
  deleteNoteTemplate: 'delete:note_template', // Delete a provider group note template ( Admin App )
};

export default { entrancePermissions, providerGroupPermissions };
