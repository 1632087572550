import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { uploadFiles } from '../../utilities/upload';
import { showToast } from '../layout/layout.actions';
import {
  processPricingGroupMemberUpload,
  PROCESS_PRICING_GROUP_MEMBER_SUCCESS,
  getPricingGroupMembers
} from './pricingGroupMembers.actions';
import { isEmpty, isNil } from 'lodash';
import NoteAdd from '@material-ui/icons/NoteAdd';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { logEvent } from '../../utilities/googleAnalytics';

import { BASE_URL } from '../../middleware/api';
import { fileUploadTypes } from '../../types/fileUploadTypes';

const MAX_FILE_UPLOAD_SIZE = 10000000;

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  gridItem: {
    padding: '1rem'
  },
  text: {
    color: theme.palette.primary.darkgray,
    fontSize: '1rem',
    textAlign: 'center',
    marginBottom: '0.5rem'
  },
  dropzone: {
    margin: '0.5rem',
    borderColor: theme.palette.primary.darkgray,
    borderStyle: 'dashed',
    borderRadius: '0.5rem',
    borderWidth: 2,
    cursor: 'pointer',
    padding: '1rem',
    color: theme.palette.primary.darkgray,
    fontSize: '1.2rem'
  },
  dropzoneIcon: {
    fontSize: 42,
    color: theme.palette.primary.main,
    alignSelf: 'center'
  },
  countGridItem: {
    padding: '0.25rem',
    margin: 'auto'
  },
  countBox: {
    borderWidth: 1,
    borderColor: theme.palette.primary.darkgray,
    borderStyle: 'solid',
    padding: '1rem'
  },
  countBoxText: {
    color: theme.palette.primary.darkgray
  }
});

class PricingGroupMemberUploadForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptedFiles: [],
      isUploading: false,
      uploadedFile: null,
      uploadMode: null
    };
  }

  handleFileDrop = (acceptedFiles, rejectedFiles) => {
    if (!isEmpty(rejectedFiles)) {
      this.props.showToast('Please select a CSV file');
    } else if (!isEmpty(acceptedFiles)) {
      this.setState({
        acceptedFiles
      });
    }
  };

  handleFileUpload = (url, mode) => {
    const { pricingGroupId } = this.props.match.params;

    logEvent(mode === fileUploadTypes.APPEND ? 'append_members' : 'replace_members', {
      adminId: this.props.user.email,
      pricingGroupId
    });

    this.setState({
      isUploading: true,
      uploadMode: mode
    });
    uploadFiles(
      url,
      this.state.acceptedFiles,
      response => {
        this.setState({
          isUploading: false,
          uploadedFile: response
        });
      },
      err => {
        this.props.showToast('There was a problem uploading the file. Please try again.');
        this.setState({
          isUploading: false,
          uploadedFile: null,
          uploadMode: null
        });
      }
    );
  };

  handleApproveUpload = async () => {
    const { pricingGroupId } = this.props.match.params;
    const { uploadMode, uploadedFile } = this.state;

    logEvent('approve_upload', {
      adminId: this.props.user.email,
      pricingGroupId
    });

    const result = await this.props.processPricingGroupMemberUpload(pricingGroupId, uploadedFile.id, uploadMode);
    if (result.type === PROCESS_PRICING_GROUP_MEMBER_SUCCESS) {
      this.props.handleClose();
      this.props.getPricingGroupMembers();
    } else {
      this.props.showToast('There was a problem approving the file upload. Please try again.');
    }
  };

  render() {
    let { classes, theme } = this.props;
    const { acceptedFiles, isUploading, uploadedFile, uploadMode } = this.state;

    const { pricingGroupId } = this.props.match.params;
    const UPLOAD_APPEND_FILE_URL = `${BASE_URL}v1/admin/pricinggroups/${pricingGroupId}/upload?mode=${fileUploadTypes.APPEND}`;
    const UPLOAD_REPLACE_FILE_URL = `${BASE_URL}v1/admin/pricinggroups/${pricingGroupId}/upload?mode=${fileUploadTypes.REPLACE}`;

    return (
      <Grid container spacing={16} className={classes.container}>
        {isUploading && <LoadingOverlay />}

        <Grid item xs={12}>
          <Typography style={{ color: theme.palette.primary.darkgray, margin: '0 auto 0.5rem', textAlign: 'center' }} variant={'h6'}>
            {`${
              isNil(uploadedFile)
                ? 'UPLOAD PRICING GROUP MEMBERS'
                : uploadMode === fileUploadTypes.APPEND
                ? 'APPEND LIST: UPLOAD'
                : 'REPLACE LIST: UPLOAD'
            }`}
          </Typography>
        </Grid>

        {/* FILE NOT YET UPLOADED */}
        {isNil(uploadedFile) && (
          <Fragment>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography className={classes.text} style={{ marginBottom: 0 }}>
                Upload a CSV file of the pricing group members.
              </Typography>
              <Typography className={classes.text}>
                The file should have the following columns in this order: firstName, lastName, identifier, and dob.
              </Typography>
              <Typography className={classes.text}>
                Select Append New to add the current list OR select Replace List to remove and add new list.
              </Typography>
            </Grid>

            <Grid item xs={8} style={{ display: 'flex', margin: 'auto' }}>
              <NoteAdd className={classes.dropzoneIcon} />
              <Dropzone accept=".csv" maxSize={MAX_FILE_UPLOAD_SIZE} onDrop={this.handleFileDrop} className={'no-select'} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={{ outline: 'none', width: '100%' }}>
                    <input {...getInputProps()} />
                    {isEmpty(acceptedFiles) && (
                      <Typography className={classes.dropzone}>Drop CSV file here, or click to select CSV file to upload.</Typography>
                    )}
                    {!isEmpty(acceptedFiles) && <Typography className={classes.dropzone}>{acceptedFiles[0].name}</Typography>}
                  </div>
                )}
              </Dropzone>
            </Grid>

            <Grid container item xs={12} justify={'flex-end'}>
              <Button
                disabled={isEmpty(acceptedFiles)}
                variant={'outlined'}
                color={'primary'}
                style={{ marginLeft: 'auto', marginRight: '0.5rem' }}
                onClick={() => this.handleFileUpload(UPLOAD_APPEND_FILE_URL, fileUploadTypes.APPEND)}
              >
                {'APPEND NEW'}
              </Button>
              <Button
                disabled={isEmpty(acceptedFiles)}
                variant={'outlined'}
                color={'primary'}
                onClick={() => this.handleFileUpload(UPLOAD_REPLACE_FILE_URL, fileUploadTypes.REPLACE)}
              >
                {'REPLACE LIST'}
              </Button>
            </Grid>
          </Fragment>
        )}

        {/* FILE UPLOADED BUT NOT YET APPROVED */}
        {!isNil(uploadedFile) && (
          <Fragment>
            <Grid container spacing={16} style={{ marginBottom: '1rem' }}>
              <Grid item xs={3} className={classes.countGridItem}>
                <div className={classes.countBox}>
                  <Typography className={classes.countBoxText}>BEFORE</Typography>
                  <Typography className={classes.countBoxText} style={{ fontSize: '1.6rem' }}>
                    {uploadedFile.before}
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={3} className={classes.countGridItem}>
                <div className={classes.countBox}>
                  <Typography className={classes.countBoxText}>AFTER</Typography>
                  <Typography className={classes.countBoxText} style={{ fontSize: '1.6rem' }}>
                    {uploadedFile.after}
                  </Typography>
                </div>
              </Grid>

              {uploadMode === fileUploadTypes.APPEND && (
                <Grid item xs={3} className={classes.countGridItem}>
                  <div className={classes.countBox}>
                    <Typography className={classes.countBoxText}>DUPLICATES</Typography>
                    <Typography className={classes.countBoxText} style={{ fontSize: '1.6rem' }}>
                      {uploadedFile.duplicates}
                    </Typography>
                  </div>
                </Grid>
              )}
            </Grid>

            <Grid container item xs={12} justify={'flex-end'}>
              <Button
                variant={'contained'}
                color={'primary'}
                style={{ marginLeft: 'auto', marginRight: '0.5rem' }}
                onClick={this.handleApproveUpload}
              >
                {'Approve'}
              </Button>
              <Button variant={'outlined'} onClick={() => this.props.handleClose(true)}>
                {'Cancel'}
              </Button>
            </Grid>
          </Fragment>
        )}
      </Grid>
    );
  }
}

PricingGroupMemberUploadForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func
};

const mapStateToProps = state => {
  return {
    user: state.login.user
  };
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        showToast,
        processPricingGroupMemberUpload,
        getPricingGroupMembers
      }
    )(PricingGroupMemberUploadForm)
  )
);
