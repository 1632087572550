import Symbol from 'es6-symbol';
import auth from '../auth/auth';

let SERVER_URL = process.env.REACT_APP_LOCAL_API ? 'http://localhost:3001/' : process.env.REACT_APP_SERVER_URL;

export const BASE_URL = `${SERVER_URL}`;

function callApi(token, endpoint, authenticated, method = 'GET', payload) {
  console.log('Requesting', endpoint);
  let config = { headers: {} };

  if (authenticated) {
    console.log('Auth required');
    if (token) {
      config = {
        headers: { Authorization: `Bearer ${token}` }
      };
    } else {
      console.log('No token available.');
      // call auth0 logout
      auth.logout();
    }
  }
  config.method = method;
  if (typeof payload !== 'undefined') {
    //Is this form data?
    if (Object.prototype.toString.call(payload) === '[object FormData]') {
      config.body = payload;
    } else {
      config.body = JSON.stringify(payload);
      config.headers['Content-Type'] = 'application/json';
    }
  }

  console.log('Fetching', BASE_URL + endpoint);
  return fetch(BASE_URL + endpoint, config)
    .then(response => {
      return response.json().then(json => {
        return { json, response };
      });
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        console.log('Bad Response', response);
        return Promise.reject(json);
      }
      let metadata = {};
      if (response.headers.get('X-Total-Count')) {
        metadata.totalCount = Number(response.headers.get('X-Total-Count'));
      }
      return { json, metadata };
    });
}

export const CALL_API = Symbol('Call API');

export default ({ dispatch, getState }) => next => async action => {
  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint, types, authenticated, method, payload, options, actionMetadata } = callAPI;

  const [requestType, successType, errorType] = types;

  next({
    type: requestType,
    payload,
    actionMetadata
  });

  let jwt;
  if (authenticated) {
    try {
      jwt = localStorage.getItem('access_token');
    } catch (e) {
      console.log('ERROR GETTING TOKEN', e);
    }
  }

  // Passing the authenticated boolean back in our data will let us distinguish
  // between normal and secret quotes
  return callApi(jwt, endpoint, authenticated, method, payload).then(
    response => {
      return next({
        response: response.json,
        metadata: response.metadata,
        authenticated,
        payload,
        type: successType,
        options,
        actionMetadata
      });
    },
    error => {
      let resp = {
        type: errorType,
        actionMetadata
      };
      if (error.message === 'Cannot validate JWT') {
        //Call Auth0 logout
        auth.logout();
      }
      if (error.messages) {
        resp.messages = error.messages;
      } else if (error.message) {
        resp.messages = [error.message];
      } else {
        resp.messages = ['There was an error.'];
      }
      return next(resp);
    }
  );
};
