import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

const styles = theme => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 5rem)'
  }
});

class DashboardsContainer extends Component {
  componentWillMount() {
    this.props.setPageTitle('Dashboards');
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        <iframe
          title="dashboard"
          src="https://datastudio.google.com/embed/reporting/1-55zu-va7YaIpIo4M8MEVA_YpnB8pTxe/page/A9Tx"
          style={{ border: 0, width: '100%', height: '100%' }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

DashboardsContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setPageTitle: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      setPageTitle
    })(DashboardsContainer)
  )
);
