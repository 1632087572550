import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import {
  getNoteTemplates,
  createNoteTemplate,
  saveNoteTemplate,
  deleteNoteTemplate,
  CREATE_NOTE_TEMPLATE_SUCCESS,
  SAVE_NOTE_TEMPLATE_SUCCESS,
  DELETE_NOTE_TEMPLATE_SUCCESS
} from './noteTemplates.actions';
import { getNoteTypes } from './noteTypes.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import NoteTemplateFormComponent from './noteTemplateForm.component';
import { find } from 'lodash';
import { showToast } from '../layout/layout.actions';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = theme => ({
  container: {
    padding: '3rem',
    height: 'calc(100% - 2rem)'
  }
});

class NoteTemplateContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleSaveNoteTemplate = this.handleSaveNoteTemplate.bind(this);
    this.handleToggleNoteTemplateEnable = this.handleToggleNoteTemplateEnable.bind(this);
    this.handleDeleteNoteTemplate = this.handleDeleteNoteTemplate.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async handleSaveNoteTemplate(id, noteTemplate) {
    if (id) {
      let response = await this.props.saveNoteTemplate(id, noteTemplate);
      if (response.type === SAVE_NOTE_TEMPLATE_SUCCESS) {
        this.props.history.goBack();
      } else {
        this.props.showToast('Could not save the Note Template. Please check your connection and try again.');
      }
    } else {
      logEvent('add_notetemplate', {
        adminId: this.props.user.email
      });
      let response = await this.props.createNoteTemplate(noteTemplate);
      if (response.type === CREATE_NOTE_TEMPLATE_SUCCESS) {
        this.props.history.goBack();
      } else {
        this.props.showToast('Could not create the Note Template. Please check your connection and try again.');
      }
    }
  }

  async handleToggleNoteTemplateEnable(id, noteTemplate) {
    let response = await this.props.saveNoteTemplate(id, noteTemplate);
    if (response.type !== SAVE_NOTE_TEMPLATE_SUCCESS) {
      this.props.showToast('Could not save the Note Template. Please check your connection and try again.');
    }
    return response;
  }

  async handleDeleteNoteTemplate(id) {
    let response = await this.props.deleteNoteTemplate(id);
    if (response.type === DELETE_NOTE_TEMPLATE_SUCCESS) {
      this.props.history.goBack();
    } else {
      this.props.showToast('Could not delete the Note Template. Please check your connection and try again.');
    }
    return response;
  }

  handleCancel() {
    this.props.history.goBack();
  }

  componentWillMount() {
    this.props.setPageTitle('Note Template');
    this.props.getNoteTemplates();
    this.props.getNoteTypes();
  }

  render() {
    const { classes, isLoading, isSaving, noteTemplates, providerGroups, noteTypes } = this.props;
    const { noteTemplateId } = this.props.match.params;

    if (isLoading === true) {
      return <LoadingOverlay />;
    }

    const noteTemplate = find(noteTemplates, { id: noteTemplateId });
    const disabled = noteTemplate
      ? !can(providerGroupPermissions.updateNoteTemplate, noteTemplate.providerGroupId)
      : !can(providerGroupPermissions.createNoteTemplate, null);

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        {isSaving && <LoadingOverlay />}
        <NoteTemplateFormComponent
          noteTemplate={noteTemplate}
          providerGroups={providerGroups}
          noteTypes={noteTypes}
          handleSaveNoteTemplate={this.handleSaveNoteTemplate}
          handleToggleNoteTemplateEnable={this.handleToggleNoteTemplateEnable}
          handleDeleteNoteTemplate={this.handleDeleteNoteTemplate}
          handleCancel={this.handleCancel}
          disabled={disabled}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    noteTemplates: state.noteTemplates.noteTemplates,
    noteTypes: state.noteTypes.noteTypes,
    isLoading: state.noteTemplates.isLoading || state.noteTypes.isLoading,
    isSaving: state.noteTemplates.isSaving,
    providerGroups: state.layout.providerGroups,
    user: state.login.user
  };
};

NoteTemplateContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        getNoteTemplates,
        createNoteTemplate,
        saveNoteTemplate,
        deleteNoteTemplate,
        showToast,
        getNoteTypes
      }
    )(NoteTemplateContainer)
  )
);
