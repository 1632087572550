import { CALL_API } from '../../middleware/api';

export const SAVE_MARKETING_CONTENT_REQUEST = 'SAVE_MARKETING_CONTENT_REQUEST';
export const SAVE_MARKETING_CONTENT_SUCCESS = 'SAVE_MARKETING_CONTENT_SUCCESS';
export const SAVE_MARKETING_CONTENT_FAILURE = 'SAVE_MARKETING_CONTENT_FAILURE';

export const saveMarketingContent = (entranceId, data) => {
  // TODO: figure out flow of particular content array
  // entrance.marketingContent =
  return {
    [CALL_API]: {
      types: [SAVE_MARKETING_CONTENT_REQUEST, SAVE_MARKETING_CONTENT_SUCCESS, SAVE_MARKETING_CONTENT_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/entrances/${entranceId}`,
      method: 'PUT',
      payload: data
    }
  };
};
