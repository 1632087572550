import { Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { setPageTitle } from './layout.actions';

class NoWebAccessContainer extends Component {
  componentWillMount() {
    this.props.setPageTitle('User Not Authorized');
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h5" style={{ margin: '2rem 0' }}>
          You do not have access to this application.
        </Typography>
        <Button variant="contained" color="primary" onClick={() => this.props.history.replace('/logout')}>
          Logout
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

NoWebAccessContainer.propTypes = {
  setPageTitle: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(
  connect(
    mapStateToProps,
    { setPageTitle }
  )(NoWebAccessContainer)
);
