import { Button, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Typography } from '@material-ui/core';
import ReactRouterPropTypes from 'react-router-prop-types';
import { SAVE_MARKETING_CONTENT_SUCCESS, saveMarketingContent } from './marketing.actions';
import { can } from '../login/can';
import { connect } from 'react-redux';
import { entrancePermissions } from '../../types/permissionTypes';
import { findIndex, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import CustomModal from '../../common/modal/customModal.component';
import DataTable from '../../common/dataTable/dataTable.component';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import MarketingContentForm from './marketingContentForm.component';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'index', numeric: false, disablePadding: false, label: 'Order', sortable: false, filtering: false },
  { id: 'contentType', numeric: false, disablePadding: false, label: 'Content Type', sortable: true, filtering: true },
  { id: 'imageUrl', numeric: false, disablePadding: false, label: 'Image', sortable: false, filtering: false },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true, filtering: true },
  { id: 'url', numeric: false, disablePadding: false, label: 'URL', sortable: false, filtering: true },
  { id: 'bannerText', numeric: false, disablePadding: false, label: 'Banner Text', sortable: true, filtering: true },
  { id: 'bannerColor', numeric: false, disablePadding: false, label: 'Banner Color', sortable: true, filtering: true },
  { id: 'color', numeric: false, disablePadding: false, label: 'Brand Color', sortable: false, filtering: false },
];

class MarketingContentDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: {},
      isShowModal: false,
      selectedContent: null,
    };

    this.menuAnchorEl = {};
  }

  handleToggleMenu = (event, i) => {
    let isMenuOpen = { ...this.state.isMenuOpen };
    isMenuOpen[i] = !isMenuOpen[i];

    this.setState({ isMenuOpen });
  };

  handleCloseMenu = (event, i) => {
    if (this.menuAnchorEl[i].contains(event.target)) {
      return;
    }

    let isMenuOpen = { ...this.state.isMenuOpen };
    isMenuOpen[i] = false;

    this.setState({ isMenuOpen });
  };

  handleAddClick = () => {
    this.props.history.push(`/marketingContent/${this.props.entrance.id}/create`);
  };

  handleEditClick = async (row, i) => {
    let isMenuOpen = { ...this.state.isMenuOpen };
    isMenuOpen[i] = !isMenuOpen[i];

    this.setState({
      selectedContent: row,
      isShowModal: true,
      isMenuOpen,
    });
  };

  handleDeleteClick = async (row, i) => {
    let isMenuOpen = { ...this.state.isMenuOpen };
    isMenuOpen[i] = !isMenuOpen[i];

    this.setState(
      {
        // selectedContent: row,
        isMenuOpen,
      },
      () => {
        if (window.confirm('Are you sure you want to delete this content?')) {
          this.handleDeleteContent(row);
        } else {
          console.log('CANCELED DELETE');
        }
      }
    );
  };

  handleCloseModal = () => {
    this.setState({
      selectedContent: null,
      isShowModal: false,
    });
  };

  handleMoveUp = item => {
    const updateData = this.props.data.filter(d => d.contentType === item.contentType);
    const index = updateData.findIndex(d => d.id === item.id);

    if (index > -1) {
      const itemToMove = updateData[index];
      updateData[index] = updateData[index - 1];
      updateData[index - 1] = itemToMove;
      this.props.handleRowReorder(item.contentType, updateData);
    }
  };

  handleMoveDown = item => {
    const updateData = this.props.data.filter(d => d.contentType === item.contentType);
    const index = updateData.findIndex(d => d.id === item.id);

    if (index > -1) {
      const itemToMove = updateData[index];
      updateData[index] = updateData[index + 1];
      updateData[index + 1] = itemToMove;
      this.props.handleRowReorder(item.contentType, updateData);
    }
  };

  handleSaveContent = async (newContent, prevContent) => {
    const updateEntrance = { ...this.props.entrance };

    // if new and old content are different contentTypes we need to remove from the old and add to the new
    if (newContent.contentType !== prevContent.contentType) {
      const contentIndex = findIndex(updateEntrance.marketingContent[prevContent.contentType], { id: prevContent.id });

      if (contentIndex !== -1) {
        updateEntrance.marketingContent[prevContent.contentType].splice(contentIndex, 1);

        const contentType = newContent.contentType;
        delete newContent.contentType;
        delete newContent.isFirstOfType;
        delete newContent.isLastOfType;

        if (isEmpty(updateEntrance.marketingContent[contentType])) {
          updateEntrance.marketingContent[contentType] = [];
        }

        updateEntrance.marketingContent[contentType].push(newContent);
      }
    } else {
      const contentIndex = findIndex(updateEntrance.marketingContent[newContent.contentType], { id: newContent.id });

      if (contentIndex !== -1) {
        const contentType = newContent.contentType;
        delete newContent.contentType;
        delete newContent.isFirstOfType;
        delete newContent.isLastOfType;

        updateEntrance.marketingContent[contentType][contentIndex] = newContent;
      }
    }

    delete updateEntrance.created;
    delete updateEntrance.updated;
    delete updateEntrance.doseSpot;
    delete updateEntrance.stripe;
    delete updateEntrance.id;
    delete updateEntrance.tenantId;

    const result = await this.props.saveMarketingContent(this.props.entrance.id, updateEntrance);

    if (result.type === SAVE_MARKETING_CONTENT_SUCCESS) {
      this.setState({ isShowModal: false });
      this.props.history.replace(`/marketingContent/${this.props.entrance.id}`);
    }
  };

  handleDeleteContent = async content => {
    const updateEntrance = { ...this.props.entrance };
    const contentIndex = findIndex(updateEntrance.marketingContent[content.contentType], { id: content.id });

    if (contentIndex !== -1) {
      updateEntrance.marketingContent[content.contentType].splice(contentIndex, 1);

      delete updateEntrance.created;
      delete updateEntrance.updated;
      delete updateEntrance.doseSpot;
      delete updateEntrance.stripe;
      delete updateEntrance.id;

      const result = await this.props.saveMarketingContent(this.props.entrance.id, updateEntrance);

      if (result.type === SAVE_MARKETING_CONTENT_SUCCESS) {
        if (this.state.isShowModal) {
          this.setState({ isShowModal: false });
        }

        this.props.history.replace(`/marketingContent/${this.props.entrance.id}`);
      }
    }
  };

  // get custom row render component
  renderRow = (row, i) => {
    const { isFirstOfType, isLastOfType } = row;
    const isOnlyOfType = isFirstOfType && isLastOfType;

    return (
      <TableRow
        key={i}
        tabIndex={-1}
        classes={{
          root:
            i % 2 === 0 ? classNames([this.props.classes.tableRowRoot, this.props.classes.rowHighlight]) : this.props.classes.tableRowRoot,
        }}
      >
        <TableCell align="left">
          <Typography style={{ display: 'inline', marginRight: '0.25rem' }}>{row.index + 1}</Typography>

          {!isFirstOfType && !isOnlyOfType && (
            <IconButton size="small" className={this.props.classes.orderButton} onClick={() => this.handleMoveUp(row)}>
              <ArrowUp fontSize="small" />
            </IconButton>
          )}

          {!isLastOfType && !isOnlyOfType && (
            <IconButton size="small" className={this.props.classes.orderButton} onClick={() => this.handleMoveDown(row)}>
              <ArrowDown fontSize="small" />
            </IconButton>
          )}
        </TableCell>
        <TableCell align="left">
          <Typography>{row.contentType}</Typography>
        </TableCell>
        <TableCell align="left">
          {row.imageUrl ? (
            <img src={row.imageUrl} style={{ width: '200px', height: '95px' }} alt={row.altText} />
          ) : (
            <Typography>N/A</Typography>
          )}
        </TableCell>
        <TableCell align="left">
          <Typography>{row.name || 'N/A'}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography>{row.url || 'N/A'}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography>{row.bannerText || 'N/A'}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography>{row.bannerColor || 'N/A'}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography>{row.color || 'N/A'}</Typography>
        </TableCell>
        <TableCell align="right">
          <Fragment>
            <Button
              buttonRef={node => {
                this.menuAnchorEl[i] = node;
              }}
              aria-owns={this.state.isMenuOpen[i] ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={e => this.handleToggleMenu(e, i)}
              variant="contained"
              color="primary"
              disabled={!can(entrancePermissions.updateSuggestedPaymentChange, row.entranceId)}
            >
              Actions
              <ArrowDropDown style={{ marginTop: -2 }} />
            </Button>
            <Popper
              open={this.state.isMenuOpen[i] ? this.state.isMenuOpen[i] : false}
              anchorEl={this.menuAnchorEl[i]}
              transition
              disablePortal
              style={{ zIndex: 1000 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper style={{ minWidth: 200 }}>
                    <ClickAwayListener
                      onClickAway={e => {
                        this.handleCloseMenu(e, i);
                      }}
                    >
                      <MenuList>
                        <MenuItem
                          disabled={!can(entrancePermissions.updateMarketingContent, row.entranceId)}
                          onClick={() => this.handleEditClick(row, i)}
                        >
                          EDIT
                        </MenuItem>
                        <MenuItem
                          disabled={!can(entrancePermissions.deleteMarketingContent, row.entranceId)}
                          onClick={() => this.handleDeleteClick(row, i)}
                        >
                          DELETE
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Fragment>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { data, isSaving, entrance } = this.props;

    return (
      <Fragment>
        <DataTable
          data={can(entrancePermissions.readMarketingContent, entrance ? entrance.id : null) ? data : []}
          title=""
          columns={columns}
          renderRow={this.renderRow}
          disableSearch
          defaultSortOrder="asc"
          onAddClick={can(entrancePermissions.createMarketingContent, entrance ? entrance.id : null) ? this.handleAddClick : null}
        />
        <CustomModal open={this.state.isShowModal} escapeKeyDown backdropClick handleClose={this.handleCloseModal}>
          {isSaving && <LoadingOverlay />}
          <MarketingContentForm
            content={this.state.selectedContent}
            entrance={entrance}
            handleSaveContent={this.handleSaveContent}
            handleDeleteContent={this.handleDeleteContent}
            handleCancel={this.handleCloseModal}
          />
        </CustomModal>
      </Fragment>
    );
  }
}

const styles = theme => ({
  wrapper: {
    position: 'relative',
  },
  tableRowRoot: {
    cursor: 'pointer !important',
  },
  rowHighlight: {
    backgroundColor: theme.palette.primary.lightgray,
  },
  fab: {
    margin: theme.spacing.unit,
    position: 'absolute',
    right: '2rem',
    top: '1rem',
  },
  orderButton: {
    padding: '0.25rem',
  },
});

MarketingContentDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  data: PropTypes.array.isRequired,
  entrance: PropTypes.object,

  saveMarketingContent: PropTypes.func.isRequired,
  handleRowReorder: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    entrances: state.layout.entrances,
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, {
    saveMarketingContent,
  })(MarketingContentDataTable)
);
