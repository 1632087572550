import { CALL_API } from '../../middleware/api';

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_RESPONSIVE_BREAKPOINT = 'SET_RESPONSIVE_BREAKPOINT';

export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE';
export const SET_TOAST_POSITION = 'SET_TOAST_POSITION';

export const TOGGLE_DRAWER_MENU = 'TOGGLE_DRAWER_MENU';

export const SET_DIRTY_FORM = 'SET_DIRTY_FORM';

export const GET_PROVIDER_GROUPS_REQUEST = 'GET_PROVIDER_GROUPS_REQUEST';
export const GET_PROVIDER_GROUPS_SUCCESS = 'GET_PROVIDER_GROUPS_SUCCESS';
export const GET_PROVIDER_GROUPS_FAILURE = 'GET_PROVIDER_GROUPS_FAILURE';

export const GET_ENTRANCES_REQUEST = 'GET_ENTRANCES_REQUEST';
export const GET_ENTRANCES_SUCCESS = 'GET_ENTRANCES_SUCCESS';
export const GET_ENTRANCES_FAILURE = 'GET_ENTRANCES_FAILURE';

export const GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';

export const setPageTitle = (title, breadcrumbLink, breadcrumbTitle) => {
  return {
    type: SET_PAGE_TITLE,
    payload: title,
    breadcrumb: breadcrumbLink,
    breadcrumbTitle,
  };
};

export const setResponsiveBreakpoint = (breakpoint) => {
  return {
    type: SET_RESPONSIVE_BREAKPOINT,
    payload: breakpoint,
  };
};

export const showToast = (message, isFailure) => {
  return {
    type: SHOW_TOAST_MESSAGE,
    message,
    isFailure,
  };
};

export const hideToast = () => {
  return {
    type: HIDE_TOAST_MESSAGE,
  };
};

let toastTimer;
export const handleToastMessage = (message, isFailure) => {
  return (dispatch, getState) => {
    clearTimeout(toastTimer);
    dispatch(showToast(message, isFailure));
    toastTimer = setTimeout(() => {
      dispatch(hideToast());
    }, 3000);
  };
};

export const setToastPosition = (position) => {
  return {
    type: SET_TOAST_POSITION,
    position,
  };
};

export const toggleDrawerMenu = () => {
  return {
    type: TOGGLE_DRAWER_MENU,
  };
};

export const setDirtyForm = (flag) => {
  return {
    type: SET_DIRTY_FORM,
    payload: { flag },
  };
};

export const getProviderGroups = () => {
  return {
    [CALL_API]: {
      types: [GET_PROVIDER_GROUPS_REQUEST, GET_PROVIDER_GROUPS_SUCCESS, GET_PROVIDER_GROUPS_FAILURE],
      authenticated: true,
      endpoint: 'v1/admin/providergroups',
      method: 'GET',
    },
  };
};

export const getEntrances = () => {
  return {
    [CALL_API]: {
      types: [GET_ENTRANCES_REQUEST, GET_ENTRANCES_SUCCESS, GET_ENTRANCES_FAILURE],
      authenticated: true,
      endpoint: 'v1/admin/entrances',
      method: 'GET',
    },
  };
};

export const getAccounts = () => {
  return {
    [CALL_API]: {
      types: [GET_ACCOUNTS_REQUEST, GET_ACCOUNTS_SUCCESS, GET_ACCOUNTS_FAILURE],
      authenticated: true,
      endpoint: 'v1/admin/accounts',
      method: 'GET',
    },
  };
};
