export const types = {
  PROVIDER: 'provider',
  PATIENT: 'patient'
};

export const getFriendlyName = type => {
  switch (type) {
    case types.PATIENT:
      return 'Patient';

    case types.PROVIDER:
      return 'Provider';

    default:
      return '';
  }
};

export default types;
