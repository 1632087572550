import {
  CREATE_FACILITY_SUCCESS,
  GET_FACILITY_MEMBERSHIPS_SUCCESS,
  GET_FACILITY_SUCCESS,
  UPDATE_FACILITY_SUCCESS,
  clearFacilityDetail,
  createFacility,
  getFacility,
  getFacilityMemberships,
  updateFacility,
  disableFacility,
  DISABLE_FACILITY_SUCCESS,
} from '../facilities.actions';
import { connect } from 'react-redux';
import { has } from 'lodash';
import { setPageTitle } from '../../layout/layout.actions';
import { showToast } from '../../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import FacilityForm from './facilityForm.component';
import LoadingOverlay from '../../../common/loadingOverlay/loadingOverlay.component';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

const styles = theme => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 5rem)',
  },
});

class FacilityDetail extends Component {
  state = {
    facilityMemberships: [],
  };

  async componentWillMount() {
    this.props.setPageTitle('Facility Details');
    const { facilityId } = this.props.match.params;
    if (facilityId !== 'create') {
      const facilityResult = await this.props.getFacility(facilityId);
      const membershipResult = await this.props.getFacilityMemberships(facilityId);

      if (facilityResult.type !== GET_FACILITY_SUCCESS || membershipResult.type !== GET_FACILITY_MEMBERSHIPS_SUCCESS) {
        this.props.history.push('/facilities');
      }
    }
  }

  // clear detail when unmounting
  componentWillUnmount() {
    this.props.clearFacilityDetail();
  }

  handleSaveFacility = async data => {
    if (!has(data, 'id')) {
      // create new
      const result = await this.props.createFacility(data);
      if (result.type === CREATE_FACILITY_SUCCESS) {
        this.props.history.push('/facilities');
        this.props.clearFacilityDetail();
      } else {
        this.props.showToast('Error creating facility. Please check your connection and try again.');
      }
    } else {
      // edit existing
      const result = await this.props.updateFacility(data.id, data);
      if (result.type === UPDATE_FACILITY_SUCCESS) {
        this.props.history.push('/facilities');
        this.props.clearFacilityDetail();
      } else {
        this.props.showToast('Error updating facility. Please check your connection and try again.');
      }
    }
  };

  handleDisableFacility = async () => {
    const { facilityDetail } = this.props;

    if (window.confirm(`Are you sure you want to disable ${facilityDetail.name}?`)) {
      const result = await this.props.disableFacility(facilityDetail.id);
      if (result.type === DISABLE_FACILITY_SUCCESS) {
        this.props.showToast(`${facilityDetail.name} has been disabled.`);
        this.props.history.push('/facilities');
        this.props.clearFacilityDetail();
      } else {
        this.props.showToast(`Error disabling facility. ${result.messages[0]}`);
      }
    }
  };

  handleCancel = () => {
    this.props.history.push('/facilities');
    this.props.clearFacilityDetail();
  };

  render() {
    const { classes, entrances, facilityDetail, isLoading } = this.props;

    if (isLoading) return <LoadingOverlay />;

    return (
      <div className={classnames([classes.container])}>
        <FacilityForm
          facility={facilityDetail}
          entrances={entrances}
          handleSave={this.handleSaveFacility}
          handleDisable={this.handleDisableFacility}
          handleCancel={this.handleCancel}
        />
      </div>
    );
  }
}

FacilityDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  entrances: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  createFacility: PropTypes.func.isRequired,
  updateFacility: PropTypes.func.isRequired,
  facilityDetail: PropTypes.object,
  getFacility: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  clearFacilityDetail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getFacilityMemberships: PropTypes.func.isRequired,
  disableFacility: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    entrances: state.layout.entrances,
    facilityDetail: state.facilities.facilityDetail,
    isLoading: state.facilities.isLoading,
  };
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      setPageTitle,
      getFacility,
      showToast,
      createFacility,
      updateFacility,
      clearFacilityDetail,
      getFacilityMemberships,
      disableFacility,
    })(FacilityDetail)
  )
);
