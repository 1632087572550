import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { getOutageBanner } from './outageBanner.actions';
import OutageBannerTable from './outageBannerTable.container';
import outageBannerTypes from '../../types/outageBannerTypes';
import { Paper } from '@material-ui/core';

const styles = theme => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 5rem)'
  }
});

class OutageBannerContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    this.props.setPageTitle('Outage Banner');
    this.props.getOutageBanner();
  }

  render() {
    const { classes, isLoading } = this.props;

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        {isLoading ? (
          <LoadingOverlay />
        ) : (
          <>
            <Paper style={{ marginBottom: '2rem' }}>
              <OutageBannerTable type={outageBannerTypes.PATIENT} />
            </Paper>
            <Paper>
              <OutageBannerTable type={outageBannerTypes.PROVIDER} />
            </Paper>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.outageBanner.isLoading
  };
};

OutageBannerContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getOutageBanner: PropTypes.func.isRequired,

  isLoading: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        getOutageBanner
      }
    )(OutageBannerContainer)
  )
);
