import {
  GET_PRICING_BASES_REQUEST,
  GET_PRICING_BASES_SUCCESS,
  GET_PRICING_BASES_FAILURE,
  //CREATE_PRICING_BASE_REQUEST,
  //CREATE_PRICING_BASE_SUCCESS,
  //CREATE_PRICING_BASE_FAILURE,
  SAVE_PRICING_BASE_REQUEST,
  SAVE_PRICING_BASE_SUCCESS,
  SAVE_PRICING_BASE_FAILURE
  //DELETE_PRICING_BASE_REQUEST,
  //DELETE_PRICING_BASE_SUCCESS,
  //DELETE_PRICING_BASE_FAILURE
} from './pricingBases.actions';
import produce from 'immer';

const initialState = {
  pricingBases: [],
  isLoading: false,
  isSaving: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_PRICING_BASES_REQUEST:
      draft.isLoading = true;
      break;

    case GET_PRICING_BASES_SUCCESS:
      draft.isLoading = false;
      draft.pricingBases = action.response.data;
      break;

    case GET_PRICING_BASES_FAILURE:
      draft.isLoading = false;
      break;

    /*
    case CREATE_PRICING_BASE_REQUEST:
      draft.isSaving = true;
      break;

    case CREATE_PRICING_BASE_SUCCESS: {
      draft.isSaving = false;
      break;
    }

    case CREATE_PRICING_BASE_FAILURE:
      draft.isSaving = false;
      break;
    */

    case SAVE_PRICING_BASE_REQUEST:
      draft.isSaving = true;
      break;

    case SAVE_PRICING_BASE_SUCCESS: {
      draft.isSaving = false;
      break;
    }

    case SAVE_PRICING_BASE_FAILURE:
      draft.isSaving = false;
      break;

    /*
    case DELETE_PRICING_BASE_REQUEST:
      draft.isSaving = true;
      break;

    case DELETE_PRICING_BASE_SUCCESS:
      draft.isSaving = false;
      break;

    case DELETE_PRICING_BASE_FAILURE:
      draft.isSaving = false;
      break;
      */

    default:
      break;
  }
}, initialState);
