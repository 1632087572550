import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { showToast } from '../layout/layout.actions';
import { getEntrances } from '../layout/layout.actions';
import { saveMarketingContent } from './marketing.actions';
import MarketingContentDataTable from './marketingContentDataTable.component';
import { isEmpty, find } from 'lodash';
import { MARKETING_CONTENT_TYPES } from '../../types/marketingContent';

const styles = theme => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 5rem)',
  },
});

class MarketingContentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entrance: null,
    };

    this._debounceTimer = null;
  }

  componentWillMount() {
    const entrance = find(this.props.entrances, { id: this.props.match.params.entranceId });

    if (!isEmpty(entrance)) {
      this.props.setPageTitle(`Marketing Content - ${entrance.name}`);
    } else {
      this.props.setPageTitle('Marketing Content');
    }
  }

  componentDidMount() {
    const entrance = find(this.props.entrances, { id: this.props.match.params.entranceId });

    this.setState({
      entrance,
    });
  }

  // replace a given contentTypes key with new data
  handleRowReorder = (contentType, data) => {
    const updateEntrance = { ...this.state.entrance };

    // clean up ui properties
    delete data.isFirstOfType;
    delete data.isLastOfType;

    updateEntrance.marketingContent[contentType] = data;
    this.setState({
      entrance: updateEntrance,
    });

    // update entrance data w/ debounce
    const { entranceId } = this.props.match.params;
    clearTimeout(this._debounceTimer);
    this._debounceTimer = setTimeout(() => {
      this.saveMarketingContent(entranceId, updateEntrance);
    }, 250);
  };

  // wipe unneeded fields from update and call redux action
  saveMarketingContent = (entranceId, updateEntrance) => {
    delete updateEntrance.id;
    delete updateEntrance.doseSpot;
    delete updateEntrance.stripe;
    delete updateEntrance.created;
    delete updateEntrance.updated;

    this.props.saveMarketingContent(entranceId, updateEntrance);
  };

  getMarketingData() {
    const { entrance } = this.state;
    let toReturn = [];

    if (!isEmpty(entrance)) {
      for (let key in entrance.marketingContent) {
        const contents = entrance.marketingContent[key];
        contents.forEach((item, i) => {
          const isFirstOfType = i === 0;
          const isLastOfType = i === contents.length - 1;
          toReturn.push({
            contentType: MARKETING_CONTENT_TYPES[key],
            ...item,
            index: i,
            isFirstOfType,
            isLastOfType,
          });
        });
      }
    }

    return toReturn;
  }

  render() {
    const { classes, isLoading, history } = this.props;

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        {isLoading ? (
          <LoadingOverlay />
        ) : (
          <MarketingContentDataTable
            entrance={this.state.entrance}
            data={this.getMarketingData()}
            history={history}
            handleRowReorder={this.handleRowReorder}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.layout.isEntrancesLoading,
    entrances: state.layout.entrances,
  };
};

MarketingContentContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  isLoading: PropTypes.bool,
  entrances: PropTypes.array.isRequired,

  getEntrances: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, {
      setPageTitle,
      showToast,
      getEntrances,
      saveMarketingContent,
    })(MarketingContentContainer)
  )
);
