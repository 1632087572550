import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router-dom';
import { setDirtyForm, toggleDrawerMenu } from './layout.actions';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import BackIcon from '@material-ui/icons/ChevronLeft';
import DrawerMenu, { DRAWER_WIDTH } from './drawerMenu.component';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
// import colors from '../../styles/colors';

const styles = (theme) => ({
  // logo: {
  //   maxHeight: '2.5rem',
  //   marginRight: '1rem'
  menuButtonWrapper: {
    width: '3rem',
  },
  toolbarContents: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  pageTitle: {
    textAlign: 'center',
    flex: 1,
  },
  root: {
    flexGrow: 1,
    height: 440,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hamburgerButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  hide: {
    display: 'none',
  },
});

class Header extends Component {
  constructor(props) {
    super(props);

    this.viewPage = this.viewPage.bind(this);
    this.isActiveRoute = this.isActiveRoute.bind(this);
  }

  isActiveRoute(route) {
    const { pathname } = this.props.location;

    //explicitly return true/false because matchpath doesn't return a boolean
    if (
      matchPath(pathname, {
        path: route,
        exact: true,
        strict: false,
      })
    ) {
      return true;
    }

    return false;
  }

  viewPage(route) {
    if (!this.isActiveRoute(route)) {
      if (route.indexOf('http') > -1) {
        window.open(route, '_blank');
      } else {
        this.props.history.push(route);
      }
    }
  }

  render() {
    let { classes, shouldShowBackButton, isDrawerMenuOpen, user, pageTitle } = this.props;

    return (
      <Fragment>
        <AppBar position="absolute" className={classNames(classes.appBar, isDrawerMenuOpen && classes.appBarShift)}>
          <Toolbar disableGutters={!isDrawerMenuOpen}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.props.toggleDrawerMenu}
              className={classNames(classes.hamburgerButton, isDrawerMenuOpen && classes.hide)}
            >
              <MenuIcon />
            </IconButton>

            <div className={classes.toolbarContents}>
              {shouldShowBackButton ? (
                <IconButton
                  className={classes.menuButtonWrapper}
                  color="inherit"
                  aria-label="Back"
                  onClick={() => {
                    if (this.props.hasDirtyForm) {
                      console.log('Had dirty form... manually assessing prompt');
                      if (window.confirm('You have unsaved changes. Clicking OK will not save these changes.')) {
                        this.props.setDirtyForm(false);
                        this.props.history.goBack();
                      }
                    } else {
                      this.props.history.goBack();
                    }
                  }}
                >
                  <BackIcon />
                </IconButton>
              ) : (
                <div className={classes.menuButtonWrapper} />
              )}

              <div className={classes.pageTitle}>
                <Typography variant="h6" color="inherit" noWrap
                  className={classes.flex}
                >
                  {pageTitle}
                </Typography>
              </div>

              <div className={classes.menuButtonWrapper} />
            </div>
          </Toolbar>
        </AppBar>

        <DrawerMenu
          isDrawerMenuOpen={isDrawerMenuOpen}
          toggleDrawerMenu={this.props.toggleDrawerMenu}
          user={user}
          viewPage={this.viewPage}
        />
      </Fragment>
    );
  }
}

Header.propTypes = {
  responsiveBreakpoint: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,

  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  shouldShowBackButton: PropTypes.bool,
  isDrawerMenuOpen: PropTypes.bool,
  toggleDrawerMenu: PropTypes.func.isRequired,
  setDirtyForm: PropTypes.func.isRequired,
  hasDirtyForm: PropTypes.bool,
  location: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    responsiveBreakpoint: state.layout.responsiveBreakpoint,
    pageTitle: state.layout.pageTitle,
    isDrawerMenuOpen: state.layout.isDrawerMenuOpen,
    user: state.login.user,
    hasDirtyForm: state.layout.hasDirtyForm,
  };
};

const withRouterHeader = withRouter(
  connect(
    mapStateToProps,
    {
      toggleDrawerMenu,
      setDirtyForm,
    }
  )(Header)
);

export default withStyles(styles, { withTheme: true })(withRouterHeader);
