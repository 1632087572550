import produce from 'immer';
import {
  PATIENT_SEARCH_FAILURE,
  PATIENT_SEARCH_SUCCESS,
  PATIENT_SEARCH_REQUEST,
  SAVE_PATIENT_FAILURE,
  SAVE_PATIENT_SUCCESS,
  SAVE_PATIENT_REQUEST,
  PHARMACY_SEARCH_SUCCESS,
  PHARMACY_SEARCH_FAILURE,
  PHARMACY_SEARCH_REQUEST,
  CLEAR_PHARMACIES,
} from './patients.actions';

const initialState = {
  isLoading: false,
  isLoadingPharmacies: false,
  pharmacies: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case PATIENT_SEARCH_SUCCESS:
    case SAVE_PATIENT_SUCCESS:
      draft.isLoading = false;
      break;

    case PATIENT_SEARCH_FAILURE:
    case SAVE_PATIENT_FAILURE:
      draft.isLoading = false;
      break;

    case PATIENT_SEARCH_REQUEST:
    case SAVE_PATIENT_REQUEST:
      draft.isLoading = true;
      break;

    case PHARMACY_SEARCH_FAILURE:
      draft.isLoadingPharmacies = false;
      break;

    case PHARMACY_SEARCH_REQUEST:
      draft.isLoadingPharmacies = true;
      break;

    case PHARMACY_SEARCH_SUCCESS:
      draft.pharmacies = action.response;
      draft.isLoadingPharmacies = false;
      break;

    case CLEAR_PHARMACIES:
      draft.pharmacies = null;
      break;

    default:
      break;
  }
}, initialState);
