import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { compose } from 'recompose';
import { getEntrances, setPageTitle } from '../layout/layout.actions';

import EntrancesDataTable from './entrancesDataTable.component';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { can } from '../login/can';
import { entrancePermissions } from '../../types/permissionTypes';

const EntrancesContainer = ({
  classes,
  entrances,
  history,
  isLoading,
  location: { pathname },
  setPageTitle: changePageTitle,
}) => {
  useEffect(() => {
    if (pathname.indexOf('marketingContent') > -1) {
      changePageTitle('Marketing Content - Select An Entrance');
    } else if (pathname.indexOf('patients') > -1) {
      changePageTitle('Patients - Select An Entrance');
    } else {
      changePageTitle('Entrances');
    }
  }, [pathname, changePageTitle]);

  let data = entrances;

  if (pathname.indexOf('marketingContent') > -1) {
    data = data.filter(entrance => can(entrancePermissions.readMarketingContent, entrance.id));
  } else if (pathname.indexOf('patients') > -1) {
    data = data.filter(entrance => can(entrancePermissions.readPatientDemographics, entrance.id));
  } else {
    data = data.filter(entrance => can(entrancePermissions.readEntranceSettings, entrance.id));
  }

  return (
    <div className={classNames([classes.container, 'page-padding-bottom'])}>
      {isLoading ? <LoadingOverlay /> : <EntrancesDataTable data={data} history={history} />}
    </div>
  );
};

const styles = theme => ({
  container: {
    height: 'calc(100% - 5rem)',
    padding: theme.spacing.unit * 2,
  },
});

EntrancesContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,

  isLoading: PropTypes.bool.isRequired,
  entrances: PropTypes.array.isRequired,

  setPageTitle: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    entrances: state.layout.entrances,
    isLoading: state.layout.isEntrancesLoading,
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    getEntrances,
    setPageTitle,
  })
)(EntrancesContainer);
