import {
  GET_PRICING_GROUP_MEMBERS_REQUEST,
  GET_PRICING_GROUP_MEMBERS_SUCCESS,
  GET_PRICING_GROUP_MEMBERS_FAILURE,
  CREATE_PRICING_GROUP_MEMBER_REQUEST,
  CREATE_PRICING_GROUP_MEMBER_SUCCESS,
  CREATE_PRICING_GROUP_MEMBER_FAILURE,
  SAVE_PRICING_GROUP_MEMBER_REQUEST,
  SAVE_PRICING_GROUP_MEMBER_SUCCESS,
  SAVE_PRICING_GROUP_MEMBER_FAILURE,
  DELETE_PRICING_GROUP_MEMBER_REQUEST,
  DELETE_PRICING_GROUP_MEMBER_SUCCESS,
  DELETE_PRICING_GROUP_MEMBER_FAILURE,
  PROCESS_PRICING_GROUP_MEMBER_REQUEST,
  PROCESS_PRICING_GROUP_MEMBER_SUCCESS,
  PROCESS_PRICING_GROUP_MEMBER_FAILURE
} from './pricingGroupMembers.actions';
import produce from 'immer';

const initialState = {
  pricingGroupMembers: [],
  isLoading: false,
  isSaving: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_PRICING_GROUP_MEMBERS_REQUEST:
      draft.isLoading = true;
      break;
    case GET_PRICING_GROUP_MEMBERS_SUCCESS:
      draft.isLoading = false;
      draft.pricingGroupMembers = action.response.data;
      break;

    case GET_PRICING_GROUP_MEMBERS_FAILURE:
      draft.isLoading = false;
      break;

    case CREATE_PRICING_GROUP_MEMBER_REQUEST:
      draft.isSaving = true;
      break;

    case CREATE_PRICING_GROUP_MEMBER_SUCCESS: {
      let pricingGroupMember = action.payload;
      pricingGroupMember.id = action.response.id;

      draft.pricingGroupMembers.push(pricingGroupMember);
      draft.isSaving = false;
      break;
    }

    case CREATE_PRICING_GROUP_MEMBER_FAILURE:
      draft.isSaving = false;
      break;

    case SAVE_PRICING_GROUP_MEMBER_REQUEST:
      draft.isSaving = true;
      break;

    case SAVE_PRICING_GROUP_MEMBER_SUCCESS: {
      action.payload.id = action.actionMetadata;
      let index = draft.pricingGroupMembers.findIndex(member => member.id === action.actionMetadata);
      draft.pricingGroupMembers[index] = action.payload;
      draft.isSaving = false;
      break;
    }

    case SAVE_PRICING_GROUP_MEMBER_FAILURE:
      draft.isSaving = false;
      break;

    case DELETE_PRICING_GROUP_MEMBER_REQUEST:
      draft.isSaving = true;
      break;

    case DELETE_PRICING_GROUP_MEMBER_SUCCESS: {
      let index = draft.pricingGroupMembers.findIndex(member => member.id === action.actionMetadata);
      draft.pricingGroupMembers.splice(index, 1);
      draft.isSaving = false;
      break;
    }

    case DELETE_PRICING_GROUP_MEMBER_FAILURE:
      draft.isSaving = false;
      break;

    case PROCESS_PRICING_GROUP_MEMBER_REQUEST:
      draft.isSaving = true;
      break;

    case PROCESS_PRICING_GROUP_MEMBER_SUCCESS: {
      draft.isSaving = false;
      break;
    }

    case PROCESS_PRICING_GROUP_MEMBER_FAILURE:
      draft.isSaving = false;
      break;

    default:
      break;
  }
}, initialState);
