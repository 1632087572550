import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import DataTable from '../../common/dataTable/dataTable.component';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classnames from 'classnames';
import moment from 'moment';

const styles = (theme) => ({
  tableRowRoot: {
    cursor: 'pointer !important',
  },
  rowHighlight: {
    backgroundColor: theme.palette.primary.lightgray,
  },
});

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'firstName', numeric: false, disablePadding: false, label: 'First Name', sortable: true, filtering: true },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name', sortable: true, filtering: true },
  { id: 'dob', numeric: false, disablePadding: false, label: 'DoB', sortable: true, filtering: true },
  { id: 'identifier', numeric: false, disablePadding: false, label: 'ID', sortable: true, filtering: true },
];

class PricingGroupMembersDataTable extends Component {
  // get custom row render component
  renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow
          tabIndex={-1}
          classes={{
            root:
              i % 2 === 0 ? classnames([this.props.classes.tableRowRoot, this.props.classes.rowHighlight]) : this.props.classes.tableRowRoot,
          }}
        >
          <TableCell align="left">{row.firstName}</TableCell>
          <TableCell align="left">{row.lastName}</TableCell>
          <TableCell align="left">{row.dob}</TableCell>
          <TableCell align="left">{row.identifier}</TableCell>
          <TableCell align="right">
            {!this.props.disabled && (
              <Fragment>
                <Button
                  onClick={(event) => this.props.handleDeletePricingGroupMember(row.id)}
                  color="primary"
                  variant="outlined"
                  style={{ marginRight: '0.5rem' }}
                >
                  DELETE
                </Button>
                <Button onClick={(event) => this.props.handleEditPricingGroupMember(row.id)} color="primary" variant="contained">
                  VIEW
                </Button>
              </Fragment>
            )}
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  render() {
    const { data } = this.props;

    let formattedData = data.map((row) => {
      row = { ...row };

      row.dob = `${moment(row.dob).format('L')}`;
      row.identifier = row.identifier ? row.identifier : '';

      return row;
    });

    return (
      <DataTable
        data={formattedData}
        title={''}
        columns={columns}
        renderRow={this.renderRow}
        disableSearch
        defaultSortOrder={'asc'}
      //onAddClick={this.handleAddClick}
      />
    );
  }
}

PricingGroupMembersDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  handleEditPricingGroupMember: PropTypes.func.isRequired,
  handleDeletePricingGroupMember: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    {}
  )(PricingGroupMembersDataTable)
);
