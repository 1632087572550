import { connect } from 'react-redux';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import PricingBasesDataTable from './pricingBasesDataTable.component';
import PricingGroupsDataTable from './pricingGroupsDataTable.component';
import classnames from 'classnames';
import { getPricingBases } from './pricingBases.actions';
import { getPricingGroups } from './pricingGroups.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Switch, Route, Link } from 'react-router-dom';
import { FormControlLabel, Switch as MUISwitch } from '@material-ui/core';

const styles = theme => ({
  container: {
    padding: '1rem',
    height: 'calc(100% - 5rem)'
  }
});

class PricingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      currentRow: null,
      toggleAll: false
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleToggleAll = this.handleToggleAll.bind(this);
  }

  componentWillMount() {
    this.props.setPageTitle('Pricing');
    this.props.getPricingBases();
    this.props.getPricingGroups();
  }

  handleTabChange(event, newValue) {
    this.setState({ currentTab: newValue });
  }

  handleToggleAll = () => {
    this.setState({ toggleAll: !this.state.toggleAll });
  };

  render() {
    const { classes, isLoading, pricingBases, pricingGroups, entrances, location } = this.props;

    if (isLoading === true) {
      return <LoadingOverlay />;
    }

    let filteredPricingGroups = this.state.toggleAll ? pricingGroups : pricingGroups.filter(pg => pg.status !== 'deleted');

    return (
      <div className={classnames([classes.container, 'page-padding-bottom'])}>
        <AppBar position="static" color="default">
          <Tabs value={location.pathname}>
            <Tab label="Bases" value="/pricing/bases" component={Link} to="/pricing/bases" />
            <Tab label="Groups" value="/pricing/groups" component={Link} to="/pricing/groups" />
          </Tabs>
        </AppBar>
        <Switch>
          <Route path="/pricing/bases" render={() => <PricingBasesDataTable data={pricingBases} history={this.props.history} />} />
          <Route
            path="/pricing/groups"
            render={() => (
              <Fragment>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <FormControlLabel
                    control={<MUISwitch checked={this.state.toggleAll} onChange={this.handleToggleAll} />}
                    label="Show Deleted"
                  />
                </div>
                <PricingGroupsDataTable data={filteredPricingGroups} entrances={entrances} history={this.props.history} />
              </Fragment>
            )}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pricingBases: state.pricingBases.pricingBases,
    pricingGroups: state.pricingGroups.pricingGroups,
    isLoading: state.pricingBases.isLoading || state.pricingGroups.isLoading,
    entrances: state.layout.entrances
  };
};

PricingContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        setPageTitle,
        getPricingBases,
        getPricingGroups
      }
    )(PricingContainer)
  )
);
