export const noteExportTypes = [
  'Not Exported',
  'Assessment',
  'HistoryOfPresentIllness',
  'Instructions',
  'Interventions',
  'Objective',
  'PhysicalExam',
  'PlanOfCare',
  'ReviewOfSystems',
  'SocialHistory',
  'Subjective',
];