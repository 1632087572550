import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { PriceTextField } from '../../common/priceTextField/priceTextField.component';
import { Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { find } from 'lodash';

const styles = theme => ({
  container: {
    padding: theme.spacing.unit
  },
  formControl: {
    width: '100%'
  },
  gridItem: {
    display: 'flex'
  },
  label: {
    marginRight: '1rem'
  }
});

class AltChargeForm extends Component {
  constructor(props) {
    super(props);

    const transaction = props.transaction;

    this.state = {
      finalPrice: transaction ? transaction.finalPrice : ''
    };
  }

  handleChange = name => event => {
    let updateObj = { [name]: event.target.value };
    this.setState(updateObj);
  };

  formHasErrors = () => {
    return this.state.finalPrice === '';
  };

  handleSaveTransaction = () => {
    let transaction = {
      finalPrice: Math.round(this.state.finalPrice * 100, 2)
    };

    this.props.handleSaveTransaction(this.props.transaction.id, transaction);
  };

  render() {
    let { classes, transaction } = this.props;
    const { finalPrice } = this.state;

    if (!transaction) {
      return null;
    }

    let entrance = find(this.props.entrances, { id: transaction.entranceId });
    let pricingGroup = find(this.props.pricingGroups, { id: transaction.pricingGroupId });

    return (
      <Grid container spacing={16} className={classes.container}>
        <Grid item xs={12} className={classes.gridItem}>
          <Typography className={classes.label}>Name:</Typography>
          <Typography>{`${transaction.patientFirstName} ${transaction.patientLastName}`}</Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <Typography className={classes.label}>Reason:</Typography>
          <Typography>{transaction.reason}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <Typography className={classes.label}>Entrance:</Typography>
          <Typography>{entrance ? entrance.name : ''}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <Typography className={classes.label}>Pricing Group:</Typography>
          <Typography>{pricingGroup ? pricingGroup.name : ''}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <Typography className={classes.label}>Provider Name:</Typography>
          <Typography>{transaction.providerName}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <Typography className={classes.label}>Provider Mobile:</Typography>
          <NumberFormat
            format="(###) ###-####"
            value={transaction.providerMobileNumber}
            displayType={'text'}
            renderText={value => <Typography>{value}</Typography>}
          />
        </Grid>

        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <Typography className={classes.label}>Amount Authorized:</Typography>
          <Typography>
            <NumberFormat
              value={transaction.authorizedPrice}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale
            />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <Typography className={classes.label}>Suggested Price:</Typography>
          <Typography>
            <NumberFormat
              value={transaction.suggestedPrice / 10}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale
            />
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <FormControl className={classes.formControl} style={{ marginTop: '-1.1rem' }}>
            <TextField
              id="finalPrice"
              className={classes.textField}
              value={finalPrice}
              onChange={this.handleChange('finalPrice')}
              label={'Final Price'}
              margin="normal"
              variant="outlined"
              required
              InputProps={{
                inputComponent: PriceTextField
              }}
            />
          </FormControl>
        </Grid>

        <Grid container item xs={12} justify={'flex-end'}>
          <Button
            disabled={this.formHasErrors()}
            variant={'contained'}
            color={'primary'}
            style={{ marginRight: '0.5rem' }}
            onClick={this.handleSaveTransaction}
          >
            {'Save'}
          </Button>
          <Button variant={'outlined'} onClick={this.props.handleCancel}>
            {'Cancel'}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

AltChargeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  transaction: PropTypes.object,
  entrances: PropTypes.array.isRequired,
  pricingGroups: PropTypes.array.isRequired,
  handleSaveTransaction: PropTypes.func.isRequired,
  handleCancel: PropTypes.func
};

export default withStyles(styles)(AltChargeForm);
