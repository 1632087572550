import { CALL_API } from '../../middleware/api';

export const GET_PRICING_GROUP_MEMBERS_REQUEST = 'GET_PRICING_GROUP_MEMBERS_REQUEST';
export const GET_PRICING_GROUP_MEMBERS_SUCCESS = 'GET_PRICING_GROUP_MEMBERS_SUCCESS';
export const GET_PRICING_GROUP_MEMBERS_FAILURE = 'GET_PRICING_GROUP_MEMBERS_FAILURE';

export const CREATE_PRICING_GROUP_MEMBER_REQUEST = 'CREATE_PRICING_GROUP_MEMBER_REQUEST';
export const CREATE_PRICING_GROUP_MEMBER_SUCCESS = 'CREATE_PRICING_GROUP_MEMBER_SUCCESS';
export const CREATE_PRICING_GROUP_MEMBER_FAILURE = 'CREATE_PRICING_GROUP_MEMBER_FAILURE';

export const SAVE_PRICING_GROUP_MEMBER_REQUEST = 'SAVE_PRICING_GROUP_MEMBER_REQUEST';
export const SAVE_PRICING_GROUP_MEMBER_SUCCESS = 'SAVE_PRICING_GROUP_MEMBER_SUCCESS';
export const SAVE_PRICING_GROUP_MEMBER_FAILURE = 'SAVE_PRICING_GROUP_MEMBER_FAILURE';

export const DELETE_PRICING_GROUP_MEMBER_REQUEST = 'DELETE_PRICING_GROUP_MEMBER_REQUEST';
export const DELETE_PRICING_GROUP_MEMBER_SUCCESS = 'DELETE_PRICING_GROUP_MEMBER_SUCCESS';
export const DELETE_PRICING_GROUP_MEMBER_FAILURE = 'DELETE_PRICING_GROUP_MEMBER_FAILURE';

export const PROCESS_PRICING_GROUP_MEMBER_REQUEST = 'PROCESS_PRICING_GROUP_MEMBER_REQUEST';
export const PROCESS_PRICING_GROUP_MEMBER_SUCCESS = 'PROCESS_PRICING_GROUP_MEMBER_SUCCESS';
export const PROCESS_PRICING_GROUP_MEMBER_FAILURE = 'PROCESS_PRICING_GROUP_MEMBER_FAILURE';

export const getPricingGroupMembers = () => {
  return {
    [CALL_API]: {
      types: [GET_PRICING_GROUP_MEMBERS_REQUEST, GET_PRICING_GROUP_MEMBERS_SUCCESS, GET_PRICING_GROUP_MEMBERS_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/pricinggroupmembers`,
      method: 'GET'
    }
  };
};

export const createPricingGroupMember = member => {
  return {
    [CALL_API]: {
      types: [CREATE_PRICING_GROUP_MEMBER_REQUEST, CREATE_PRICING_GROUP_MEMBER_SUCCESS, CREATE_PRICING_GROUP_MEMBER_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/pricinggroupmembers`,
      method: 'POST',
      payload: member
    }
  };
};

export const savePricingGroupMember = (memberId, member) => {
  return {
    [CALL_API]: {
      types: [SAVE_PRICING_GROUP_MEMBER_REQUEST, SAVE_PRICING_GROUP_MEMBER_SUCCESS, SAVE_PRICING_GROUP_MEMBER_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/pricinggroupmembers/${memberId}`,
      method: 'PUT',
      payload: member,
      actionMetadata: memberId
    }
  };
};

export const deletePricingGroupMember = memberId => {
  return {
    [CALL_API]: {
      types: [DELETE_PRICING_GROUP_MEMBER_REQUEST, DELETE_PRICING_GROUP_MEMBER_SUCCESS, DELETE_PRICING_GROUP_MEMBER_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/pricinggroupmembers/${memberId}`,
      method: 'DELETE',
      actionMetadata: memberId
    }
  };
};

export const processPricingGroupMemberUpload = (pricingGroupId, uploadId, mode) => {
  return {
    [CALL_API]: {
      types: [PROCESS_PRICING_GROUP_MEMBER_REQUEST, PROCESS_PRICING_GROUP_MEMBER_SUCCESS, PROCESS_PRICING_GROUP_MEMBER_FAILURE],
      authenticated: true,
      endpoint: `v1/admin/pricinggroups/${pricingGroupId}/upload/${uploadId}/process?mode=${mode}`,
      method: 'POST'
    }
  };
};
